import React from 'react';
import SupportBarStyles from '~/styles/support_bar_styles';

interface Props {}

const SupportBar: React.SFC<Props> = () => (
	<SupportBarStyles>
		<div className="contact-info">
			Questions? <br />
			Call support at: <br />
			<a href="tel:888-240-0314">888-240-0314</a>
		</div>
	</SupportBarStyles>
);

export default SupportBar;
