import styled from 'styled-components';
import boxShadowMixin from '~/styles/mixins/box_shadow';

const AppContentStyles = styled.div`
	height: 100vh;
	overflow-y: auto;

	.title-bar {
		margin-bottom: 16px;
		margin-top: 32px;
	}

	.loader {
		img {
			width: 166px;
			height: 34px;
			margin-bottom: 18px;
		}
		border-radius: 4px;
		font-size: 18px;
		text-align: center;
		background-color: white;
		max-height: 256px;
		max-width: 80%;
		margin-top: 32px;
		margin-left: auto;
		margin-right: auto;
		padding: 18px;
		p {
			margin-bottom: 0;
		}
	}

	.status-card {
		font-family: 'Proxima Nova', sans-serif;
		border-radius: 4px;
		font-size: 18px;
		text-align: center;
		background-color: white;
		max-height: 512px;
		max-width: 80%;
		margin: 32px auto;
		padding: 18px;
		${boxShadowMixin(1)};
		img {
			width: 166px;
			height: 34px;
			margin-bottom: 18px;
		}
		p {
			margin-bottom: 0;
		}
	}

	.app-column {
		max-width: 900px;
	}
`;

export default AppContentStyles;
