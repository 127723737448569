import React from 'react';

import PathStepStyles from '~/styles/path_step_styles';

const SubscriptionErrorStep: React.FC = () => (
	<PathStepStyles>
		<h1>Something went wrong</h1>
		<div className="page-content">
			<p>In order to continue, you’ll need to close this window and start again.</p>
		</div>
	</PathStepStyles>
);

export default SubscriptionErrorStep;
