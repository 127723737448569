/**
 * Determines the next step based on current state data
 */

import { logError } from '~/rollbar';
import { isCoursePresentInConfig } from '~/types/signup_config';
import { selectPasskeyOnly } from '~/utils/helpers';
import { RootState } from '..';
import {
	selectAmountOwed,
	selectCourseIsFree,
	selectCurrentStep,
	selectHasInstitutionalPay,
	selectRetakingCourse,
	selectShippingRequired,
	selectStepByName,
	selectUserSubscriptionMethod
} from '../selectors';

export function goToNextStep(state: RootState, history) {
	let currentStep = selectCurrentStep(state);
	const config = state.app.config;
	const course_id = isCoursePresentInConfig(config) ? config.course.id : null;

	if (
		state.app.config.user_agent.unsupported &&
		(currentStep?.techCheck ||
			(state.app.config.path_config.abbreviated_path && currentStep?.name === 'welcome'))
	) {
		history.push(`/tech_check`);
		return;
	}

	if (config.path_config.abbreviated_path) {
		if (currentStep?.name === 'welcome') {
			if (state.app.finalized) {
				window.location.href = course_id ? `/courses/${course_id}` : '/courses';
				return;
			} else if (config.user_agent.unsupported) {
				history.push(`/tech_check`);
			}
		}
	}

	if (currentStep?.name === 'full_address') {
		if (state.user.isBillingAddressSameAsPhysical) {
			history.push('/review_addresses');
			return;
		}
	}

	if (currentStep?.name === 'print_shipping_address') {
		if (
			state.user.isBillingAddressSameAsShipping ||
			state.user.subscriptionPayload.payment_details?.payment_method === 'passkey'
		) {
			history.push('/print_review_addresses');
			return;
		}
	}

	const { course_search_info } = state.user;
	if (
		state.app.config.path_type === 'front_door' ||
		state.app.config.path_type === 'front_door_returning'
	) {
		if (
			state.app.currentStep === 'select_school' &&
			(course_search_info.school?.lms_school || course_search_info.school?.never_show_course_list)
		) {
			history.push(`/lms_school`);
			return;
		}

		if (
			state.app.currentStep === 'select_instructor' &&
			course_search_info.instructor?.value === -1
		) {
			history.push(`/instructor_not_found`);
			return;
		}

		if (state.app.currentStep === 'select_course' && course_search_info.course?.value === -1) {
			history.push(`/course_not_found`);
			return;
		}

		if (state.app.currentStep === 'select_course' && course_search_info.course?.lms_course) {
			history.push(`/lms_school`);
			return;
		}
	}

	// Branch on name of next step from the path config
	let nextStepName: any = currentStep?.next;
	if (state.app.config.path_config.abbreviated_path) {
		if (nextStepName === 'webtext_info') {
			nextStepName = 'webtext_subscribe';
		}

		if (nextStepName === 'success') {
			nextStepName = 'terms_of_service';
			window.location.href = course_id ? `/courses/${course_id}` : '/courses';
		}
	}

	if (nextStepName === 'webtext_subscribe') {
		if (isCoursePresentInConfig(state.app.config)) {
			if (selectHasInstitutionalPay(state) || selectCourseIsFree(state)) {
				nextStepName = 'terms_of_service';
			} else if (selectPasskeyOnly(state.app.config)) {
				nextStepName = 'passkey_input';
			}
		}
	} else {
		// Branch on name of current step
		switch (currentStep?.name) {
			case 'welcome':
				if (selectRetakingCourse(state)) {
					if (selectAmountOwed(state) > 0) {
						nextStepName = 'webtext_subscribe';
					} else {
						nextStepName = 'terms_of_service';
					}
				}
				break;
			case 'webtext_subscribe':
			case 'print_payment_type':
			case 'print_review_addresses':
				const subscriptionMethod = selectUserSubscriptionMethod(state)!;
				nextStepName = currentStep.next[subscriptionMethod];
				break;
			case 'passkey_input':
				if (selectShippingRequired(state)) {
					nextStepName = 'print_info';
				}
				break;
		}
	}

	let next = selectStepByName(state, nextStepName);
	if (next) {
		history.push(`/${next.name}`);
	} else {
		const message = `Invalid next step. No next path step exists in path config matching name ${nextStepName}. If you’re seeing this message, it's likely due to a missing path step inside of a config in src/paths`;
		logError(message);
	}
}
