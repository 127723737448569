import React from 'react';

import AppButton from '~/connected/app_button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepPropsWithCoursePresentInAppConfig } from '~/types';

export interface Props extends StepPropsWithCoursePresentInAppConfig {}

export default class extends React.Component<Props> {
	public render() {
		const { course, school } = this.props.appConfig;

		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>
						Welcome!
						<br />
						Nice to meet you!
					</h1>
					<hr />
					<p>We’re Soomo Learning and we make webtexts.</p>
					<p>
						It looks like you’re signing up for <strong>{course.number}</strong> at{' '}
						<strong>{school.name}</strong>.
					</p>
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}
