import classNames from 'classnames';
import { darken } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';

import { RootState } from '~/store';
import { appContinue, appDisabledContinueClick } from '~/store/app/actions';

interface Props {
	forwardDisabled?: boolean;
	forwardHidden?: boolean;
	onBack: () => any;
	onForward?: () => any;
	onForwardDisabled?: () => any;
}

const iconSize = 22;
const NavButtons: React.FC<Props> = (props) => (
	<NavButtonStyles className={'nav-buttons'}>
		<>
			<button className="nav-button" onClick={props.onBack}>
				<KeyboardArrowLeft size={iconSize} />
			</button>
			{!props.forwardHidden && (
				<button
					className={classNames('nav-button', {
						disabled: props.forwardDisabled
					})}
					onClick={!props.forwardDisabled ? props.onForward : props.onForwardDisabled}>
					<KeyboardArrowRight size={iconSize} />
				</button>
			)}
		</>
	</NavButtonStyles>
);

const NavButtonStyles = styled.div`
	display: inline-flex;
	.nav-button {
		user-select: none;
		margin: 2px;
		display: flex;
		padding: 0px;
		border-radius: 2px;
		background-color: #8f8f8f;
		width: 29px;
		height: 29px;
		color: white;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		:not(.disabled) {
			:hover {
				background-color: #a3a3a3;
				background-color: ${darken(0.25, '#a3a3a3')};
			}
		}

		&.disabled {
			opacity: 0.35;
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		padding: 0;
		margin: 0;
		.nav-button {
			width: 22px;
			height: 22px;
			border-radius: 2px;
			border: none;
			:first-of-type {
				margin-right: 0px;
			}

			svg {
				flex-shrink: 0;
				position: relative;
				top: 0;
				bottom: 0;
			}
		}
	}
`;

const NavButtonContainer: React.FC<RouteComponentProps | any> = (props) => {
	return (
		<NavButtons
			forwardDisabled={props.forwardDisabled}
			forwardHidden={props.forwardHidden}
			onBack={props.history.goBack}
			onForward={props.appContinue}
			onForwardDisabled={props.appDisabledContinueClick}
		/>
	);
};

export default withRouter(
	connect(
		(state: RootState) => ({
			forwardDisabled:
				!state.app.canContinue ||
				['card_confirm', 'terms_of_service_cc', 'terms_of_service'].indexOf(
					state.app.currentStep
				) != -1,
			forwardHidden: ['not_found'].indexOf(state.app.currentStep) != -1
		}),
		{ appContinue, appDisabledContinueClick }
	)(NavButtonContainer)
);
