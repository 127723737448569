import { ActionType, getType } from 'typesafe-actions';

import { appActions } from '~/store/app';
import { AppConfig, AppLoadingState, StepConfig } from '~/types';
import { isCoursePresentInConfig } from '~/types/signup_config';

export type AppAction = ActionType<typeof appActions>;

export interface AppState {
	loadingState: AppLoadingState;
	appError: string;
	currentStep: string;
	config: AppConfig;
	canContinue: boolean;
	path: Array<StepConfig>;
	submittingSubscription: boolean;
	erroredStep: string;
	validationErrors: string[];
	tokenizationErrors: string[];
	finalizationErrors: string[];
	finalized: boolean; // Track that the LMS path has been finalized
	purchaseCompleted: boolean; // Track that the "complete_purchase" (from trial) path has been finalized
	printPurchaseCompleted: boolean; // Track that the "print_purchase" path has been finalized
	fetchingConfig: boolean;
	submitCount: number;
	continueClickedWhileDisabled?: boolean;
}

export const initialState = {
	loadingState: 'unloaded',
	appError: null,
	currentStep: null,
	config: null,
	canContinue: false,
	path: null,
	erroredStep: null,
	validationErrors: null,
	submittingSubscription: false,
	tokenizationErrors: null,
	finalizationErrors: null,
	finalized: false,
	purchaseCompleted: false,
	printPurchaseCompleted: false,
	fetchingConfig: false,
	submitCount: 0
} as unknown as AppState;

export default function app(state: AppState = initialState, action: AppAction): AppState {
	switch (action.type) {
		case getType(appActions.appStart):
			const { config, path } = action.payload;

			let purchaseCompleted = false;
			let printPurchaseCompleted = false;
			if (isCoursePresentInConfig(config)) {
				purchaseCompleted =
					config.path_type === 'complete_purchase' &&
					config.course.subscription.status === 'active';
				printPurchaseCompleted =
					config.path_type === 'print_purchase' && config.course.print.ordered;
			}

			return {
				...state,
				loadingState: 'loading',
				finalized: Boolean(config.finalized),
				purchaseCompleted,
				printPurchaseCompleted,
				config,
				path
			};
		case getType(appActions.appFetchConfigBoot.request):
			return {
				...state,
				fetchingConfig: true
			};
		case getType(appActions.appFetchConfigBoot.failure):
			return {
				...state,
				fetchingConfig: false
			};
		case getType(appActions.appFetchConfigBoot.success):
			return {
				...state,
				config: action.payload,
				fetchingConfig: false
			};
		case getType(appActions.appFetchConfig.request):
			return {
				...state,
				fetchingConfig: true
			};
		case getType(appActions.appFetchConfig.failure):
			return {
				...state,
				fetchingConfig: false
			};
		case getType(appActions.appFetchConfig.success):
			return {
				...state,
				config: action.payload,
				fetchingConfig: false
			};
		case getType(appActions.appSetLoadingState):
			return {
				...state,
				loadingState: action.payload
			};
		case getType(appActions.appDisabledContinueClick):
			return {
				...state,
				continueClickedWhileDisabled: true
			};
		case getType(appActions.appContinue):
			return {
				...state,
				submitCount: state.submitCount + 1,
				validationErrors: [],
				continueClickedWhileDisabled: false
			};
		case getType(appActions.appStepMounted):
			const currentStep = action.payload;
			let step = state.path.find((p) => p.name === currentStep);

			state.submitCount = 0;
			state.continueClickedWhileDisabled = false;
			if (currentStep === 'subscription_error') {
				return {
					...state,
					canContinue: false,
					currentStep: currentStep
				};
			} else if (step) {
				return {
					...state,
					canContinue: step.canContinue || false,
					currentStep: action.payload
				};
			} else {
				return {
					...state,
					canContinue: false,
					currentStep: action.payload
				};
			}
		case getType(appActions.appSetCanContinue):
			return {
				...state,
				canContinue: action.payload
			};
		case getType(appActions.appSetValidationErrors):
			return {
				...state,
				validationErrors: action.payload
			};
		case getType(appActions.appSetFinalizationErrors):
			return {
				...state,
				finalizationErrors: action.payload,
				submittingSubscription: false
			};

		case getType(appActions.appSetFinalized):
			return {
				...state,
				submittingSubscription: false,
				finalized: action.payload // When any finalization flow has completed
			};
		case getType(appActions.appSetPurchaseComplete):
			return {
				...state,
				purchaseCompleted: true // When the "complete_purchase" flow has completed
			};
		case getType(appActions.appSetPrintPurchaseComplete):
			return {
				...state,
				printPurchaseCompleted: true // When the "print_purchase" flow has completed
			};
		case getType(appActions.appSetTokenizationErrors):
			return {
				...state,
				tokenizationErrors: action.payload
			};
		case getType(appActions.appSetSubmittingSubscription):
			return {
				...state,
				submittingSubscription: action.payload
			};
		case getType(appActions.appError):
			return {
				...state,
				loadingState: 'errored',
				appError: action.payload
			};
		default:
			return state;
	}
}
