import styled from 'styled-components';

import { connect } from 'react-redux';
import { RootState } from '~/store';
import Preview from './Preview';

export interface Props {
	desktopImageURL?: string;
	printImageURL?: string;
	showPrint?: boolean;
	showLMSWarning?: boolean;
}

const Container = (props: Props) => {
	let { desktopImageURL, printImageURL, showPrint, showLMSWarning } = props;

	return (
		<WebtextPreviewStyles className="webtext-preview">
			{props.showLMSWarning ? (
				<LMSWarning>
					<img id="lms-warning" src="/images/u_turn.png" />
				</LMSWarning>
			) : (
				<Preview
					desktopImageURL={desktopImageURL}
					printImageURL={printImageURL}
					showDefault={!desktopImageURL}
					showPrint={showPrint}
				/>
			)}
		</WebtextPreviewStyles>
	);
};

export default connect((state: RootState) => ({
	desktopImageURL: state.app.config.textbook.desktop_img_url,
	printImageURL: state.app.config.textbook.print_img_url,
	showLMSWarning: state.app.currentStep === 'lms_school',
	showPrint:
		state.app.currentStep?.indexOf('print') !== -1 ||
		state.app.currentStep?.indexOf('shipping') !== -1 ||
		state.app.config.path_type === 'print_purchase'
}))(Container);

const WebtextPreviewStyles = styled.div`
	height: 100%;
	.webtext-preview {
		max-height: 100%;
	}
`;

const LMSWarning = styled.div`
	background: linear-gradient(to right, #b7cbce 0%, #e2cab9 100%);
	border-radius: 4px;
	border: 4px white;
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: relative;

	img {
		opacity: 1;
		width: 256px;
		height: 256px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
`;
