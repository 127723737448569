import { SelectInput } from '~/components/input';
import SignupStep from './SignupStep';

const round2 = (v) => Math.round(v * 100) / 100;

const updatePaymentMethod = (paymentMethod) => ({
	subscriptionPayload: { payment_details: { payment_method: paymentMethod } }
});
const getPaymentMethod = (formState) =>
	formState.subscriptionPayload?.payment_details.payment_method;

const PrintPaymentTypeStep = () => (
	<SignupStep validation={(formState) => !!getPaymentMethod(formState)}>
		{({ setFormValue, signupConfig, ...formState }) => {
			const printCost = round2(signupConfig.course.subscription.print_cost);
			const paymentOptions = [
				{
					label: 'PASSKEY - From your bookstore',
					value: 'passkey',
					description: 'Redeem a passkey purchased from your school bookstore.'
				},
				{
					label: `CREDIT CARD - $${printCost} plus applicable tax`,
					value: 'creditcard',
					description: `Use a credit card for $${printCost} plus tax.`
				}
			];
			return (
				<>
					<h1>Choose your payment</h1>
					<hr />
					<p>There are two ways to pay for your print book:</p>
					<ol>
						{paymentOptions.map((option, i) => (
							<li key={i}>{option.description}</li>
						))}
					</ol>
					<br />
					<SelectInput
						blurInputOnSelect={true}
						className="subscribe-select"
						placeholder="Select a payment type"
						options={paymentOptions}
						value={getPaymentMethod(formState)}
						onChange={(paymentMethod) => setFormValue(updatePaymentMethod(paymentMethod))}
					/>
				</>
			);
		}}
	</SignupStep>
);

export default PrintPaymentTypeStep;
