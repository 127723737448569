import React from 'react';
import styled from 'styled-components';

interface Props {}

const Loader: React.SFC<Props> = () => (
	<LoaderStyles>
		<img alt="Soomo Learning Logo" src="/images/soomo_splash_logo.png" />
	</LoaderStyles>
);

const LoaderStyles = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	img {
		width: 195px;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		display: none;
	}
`;

export default Loader;
