import React from 'react';

import Button from '~/components/input/Button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

class PrintInfoStep extends React.Component<Props, {}> {
	componentDidMount() {
		this.props.setCanContinue(true);
	}

	public render() {
		const { stepConfig, submitPage } = this.props;
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>{stepConfig.title}</h1>
					<p style={{ marginBottom: '1.5em' }}>
						This passkey includes a print version of your webtext. <br />
						 Tell us where to send it!
					</p>
					<p className="italics">
						This print-on-demand copy will take 6–10 business days to print and ship. (Which is
						amazingly fast for a custom book!)
					</p>
				</div>
				<Button onClick={submitPage}>Ok</Button>
			</PathStepStyles>
		);
	}
}

export default PrintInfoStep;
