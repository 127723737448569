import { Component } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { Store } from 'redux';
import { ThemeProvider } from 'styled-components';

import '~/styles/scss/index.scss';
import theme from '~/styles/theme';

import AppContent from '~/components/AppContent';
import { AppConfig } from '~/types';

import complete_purchase_path from '~/paths/complete_purchase_path';
import front_door_path from '~/paths/front_door_path';
import front_door_returning_path from '~/paths/front_door_returning_path';
import lms_path from '~/paths/lms_path';
import prefilled_path from '~/paths/prefilled_path';
import print_purchase_path from '~/paths/print_purchase_path';

import { appContinue, appStart } from '~/store/app/actions';

interface Props {
	store: Store;
	config: AppConfig;
}

let keyDown = false;

class App extends Component<Props> {
	componentWillMount() {
		const { config } = this.props;
		const { store } = this.props;
		let path;
		switch (config.path_type) {
			case 'LMS':
				path = lms_path;
				break;
			case 'complete_purchase':
				path = complete_purchase_path;
				break;
			case 'print_purchase':
				path = print_purchase_path;
				break;
			case 'front_door':
				path = front_door_path;
				break;
			case 'front_door_returning':
				path = front_door_returning_path;
				break;
			case 'prefilled':
				path = prefilled_path;
				break;
			default:
				path = lms_path;
		}
		store.dispatch(appStart({ config, path }));
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('keyup', this.handleKeyUp);
		window.addEventListener('touchstart', this.handleTouchDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown);
		document.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener('touchstart', this.handleTouchDown, false);
	}

	handleTouchDown = (e) => {
		this.setState({
			isTouchScreen: true
		});
	};

	handleKeyUp = (e) => {
		keyDown = false;
	};

	// Key handling for global enter key hits
	handleKeyDown = (e) => {
		if (keyDown) return;
		keyDown = true;
		const { store } = this.props;
		const state = store.getState();
		const { currentStep, canContinue } = state.app;
		let shouldContinue; // Make sure the event wasn't fired on an invalid target
		if (e.keyCode === 13 && currentStep !== 'complete_purchase') {
			shouldContinue = !e.target.parentNode.classList.contains('soomo-select__value-container');

			if (shouldContinue) {
				shouldContinue = !e.target.parentNode.classList.contains('soomo-select__input');
			}

			if (shouldContinue) {
				shouldContinue = !e.target.classList.contains('button');
			}

			if (shouldContinue) {
				shouldContinue = !(e.target.tagName === 'A');
			}

			if (
				shouldContinue &&
				state.app.canContinue &&
				currentStep.indexOf('terms_of_service') === -1
			) {
				store.dispatch(appContinue());
			}
		}
	};

	render() {
		return (
			<Provider store={this.props.store}>
				<HashRouter hashType="noslash">
					<ThemeProvider theme={theme}>
						<AppContent />
					</ThemeProvider>
				</HashRouter>
			</Provider>
		);
	}
}

export default App;
