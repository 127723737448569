import React from 'react';

import AppButton from '~/connected/app_button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

class WelcomeFrontDoorStep extends React.Component<Props> {
	public render() {
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>
						Welcome!
						<br />
						Nice to meet you!
					</h1>
					<hr />
					<p>We’re Soomo Learning and we make webtexts. Let’s find yours.</p>
				</div>
				<AppButton>Start</AppButton>
			</PathStepStyles>
		);
	}
}

export default WelcomeFrontDoorStep;
