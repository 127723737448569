import React from 'react';

import AppButton from '~/connected/app_button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';
import { isSchoolPresentInConfig } from '~/types/signup_config';

export interface Props extends StepProps {}

export default class extends React.Component<Props> {
	public render() {
		const { user, finalized } = this.props.appConfig;

		const school = isSchoolPresentInConfig(this.props.appConfig)
			? this.props.appConfig.school
			: null;

		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>
						Welcome back, {user.first_name} {user.last_name}!
					</h1>
					<hr />
					{school && (
						<>
							<p>It looks like you’ve previously taken a course with us at {school.name}.</p>
							{!finalized && <p>Let’s get you signed up for your next course.</p>}
						</>
					)}
					{!school && (
						<>
							<p>It looks like you’ve already got an account with us.</p>
							{!finalized && <p>Let’s get you signed up for your course.</p>}
						</>
					)}
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}
