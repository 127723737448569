import { SignupStep } from '~/components';

export default () => (
	<SignupStep hideButton>
		{() => (
			<>
				<h1>Success!</h1>
				<p>Expect your print book to arrive in 6–10 business days.</p>
			</>
		)}
	</SignupStep>
);
