import validate from 'validate.js';

import { addressDetailsValidation } from '~/utils/validations';

import { SignupStep } from '~/components';
import { AddressInput } from '~/components/input';

const validation = (userState) => !validate(userState.physicalAddress, addressDetailsValidation);

export default () => (
	<SignupStep validation={validation}>
		{({ setFormValue, subscriptionPayload, shippingAddress, isBillingAddressSameAsShipping }) => {
			const { payment_method } = subscriptionPayload.payment_details;

			return (
				<>
					<h1>Enter shipping address</h1>

					<p className="italics">
						{payment_method === 'creditcard'
							? 'We’ll use this address to calculate sales tax, too.'
							: 'Where do you want us to send your book?'}
					</p>

					<AddressInput
						value={shippingAddress}
						onChange={(shippingAddress) => {
							return setFormValue({
								shippingAddress,
								physicalAddress: shippingAddress
							});
						}}
						isShipping
					/>
					{payment_method === 'creditcard' && (
						<>
							<input
								style={{ marginRight: '.5rem' }}
								type="checkbox"
								id="billing-address-same-as-current-address"
								checked={isBillingAddressSameAsShipping}
								onChange={({ target }) =>
									setFormValue({
										isBillingAddressSameAsShipping: target.checked
									})
								}
							/>

							<span>This is also my billing address</span>
						</>
					)}
				</>
			);
		}}
	</SignupStep>
);
