import React from 'react';

import NativeSelect from '~/components/input/SelectInput/NativeSelect';
import SelectInput from '~/components/input/SelectInput/SelectInput';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

export interface State {}

export interface ErrorStepConfigParams {
	error_tips: [string];
}

const options = [
	{
		label: 'Value 1',
		value: '1'
	},
	{
		label: 'Value 2',
		value: '2'
	},
	{
		label: 'Value 3',
		value: '3'
	}
];

class DebugSelect extends React.Component<Props, { value: any }> {
	constructor(props) {
		super(props);
		this.state = {
			value: ''
		};
	}

	handleSelect = (e) =>
		this.setState({
			value: e.target.value
		});

	public render() {
		const { value } = this.state;
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>Select Input Debug</h1>
					<p>React-select 2</p>
					<SelectInput
						tabIndex={1}
						className="subscribe-select"
						placeholder={'Select a subscription type'}
						options={options}
						value={this.state.value}
						onChange={({ value }) => this.setState({ value })}
					/>
					<p>Styled native input</p>
					<NativeSelect
						options={options}
						placeholder={'Select a subscription type'}
						value={this.state.value}
						onChange={this.handleSelect}
					/>
				</div>
			</PathStepStyles>
		);
	}
}

export default DebugSelect;
