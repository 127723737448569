import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { appFetchConfig, appFetchConfigBoot } from '~/store/app/actions';

import { fetchSignupConfig } from '~/api';

export default function* rootSaga() {
	yield all([watchFetchConfig(), watchFetchConfigBoot()]);
}

function* watchFetchConfig() {
	yield takeLatest(getType(appFetchConfig.request), fetchConfig);
}

function* fetchConfig(action) {
	try {
		const { data } = yield call(fetchSignupConfig, action.payload);
		yield put(appFetchConfig.success(data));
	} catch (e) {
		yield put(appFetchConfig.failure(e));
	}
}

function* watchFetchConfigBoot() {
	yield takeLatest(getType(appFetchConfigBoot.request), fetchConfigBoot);
}

function* fetchConfigBoot(action) {
	try {
		const { data } = yield call(fetchSignupConfig, action.payload);
		yield put(appFetchConfigBoot.success(data));
	} catch (e) {
		yield put(appFetchConfigBoot.failure(e));
	}
}
