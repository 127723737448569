import SignupStep from './SignupStep';

const default_points = [
	'You’ll find content that’s been customized to meet the needs of your course.',
	'You’ll encounter questions on almost every page. (Check your syllabus to see how many points they’re worth.)',
	'You can highlight, take notes, or download the webtext to your mobile device!'
];

const WebtextInfoStep = () => (
	<SignupStep>
		{({ signupConfig }) => (
			<>
				<h1>A webtext is different from a traditional textbook.</h1>
				<hr />
				<ul>
					{(signupConfig.path_config.webtext_info_points || default_points).map((p, i) => (
						<li key={i}>{p}</li>
					))}
				</ul>
			</>
		)}
	</SignupStep>
);

export default WebtextInfoStep;
