import React from 'react';
import { Link } from 'react-router-dom';

import Button from '~/components/input/Button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

import PasskeyInput from '~/components/input/PasskeyInput';
import { toggleContinue } from '~/utils/helpers';

export interface Props extends StepProps {}

export interface State {
	submitted: boolean;
}

export interface PasskeyStepConfigParams {}

class PrintPasskeyInputStep extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			submitted: false
		};
		this.validate();
	}

	componentDidMount() {
		this.props.userUpdatePaymentDetails({
			payment_method: 'passkey'
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.validate();
			if (!prevProps.validatingData && this.props.validatingData) {
				this.setState({
					submitted: true
				});
			}
		}
	}

	validate() {
		let { passkey, canContinue, setCanContinue } = this.props;
		let valid = passkey.length === 13;
		toggleContinue(canContinue, valid, setCanContinue);
	}

	handleChange = (e) => {
		const passkey = e.target.value;
		this.props.userUpdatePaymentDetails({
			passkey_plaintext: passkey
		});
		if (this.state.submitted && passkey.length < 13) {
			this.setState({ submitted: false });
		}
	};

	public render() {
		const { stepConfig, canContinue, submitPage, validatingData, passkey } = this.props;
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>{stepConfig.title}</h1>
					<hr />
					<p className="italics">
						Once redeemed, passkeys cannot be returned to the school bookstore.
					</p>
					<PasskeyInput value={passkey} onChange={this.handleChange} disabled={validatingData} />
					{this.renderHelpText()}
					{this.renderErrors()}
				</div>
				<>
					<br />
					<Button onClick={() => submitPage()} disabled={!canContinue} loading={validatingData}>
						Redeem
					</Button>
				</>
			</PathStepStyles>
		);
	}

	private renderHelpText() {
		const { passkey } = this.props;

		if (passkey.length < 13) {
			return (
				<>
					<p>
						This passkey should start with "WPOD" and have a total of 13 letters and numbers. If you
						have questions about your passkey order, get in touch with your school bookstore.
					</p>
					<p>
						Or, you can go back and <Link to="/print_payment_type">pay by credit card</Link>{' '}
						instead.
					</p>
				</>
			);
		}
	}

	private renderErrors() {
		const { passkey, validatingData, validationErrors } = this.props;
		if (this.state.submitted && !validatingData && validationErrors.length) {
			let errors = validationErrors.map((err, i) => {
				return (
					<div key={i} className="error-message">
						{err}
					</div>
				);
			});

			if (errors) {
				return (
					<>
						{errors}
						<div className="error-message">
							If you need help, click the messenger button in the lower right. Or you can go back
							and <Link to="/print_payment_type">pay by credit card</Link> instead.
						</div>
					</>
				);
			}
		}
	}
}

export default PrintPasskeyInputStep;
