import { useEffect } from 'react';

import { StepProps } from '~/types';
import SignupStep from './SignupStep';

const TrialInfoStep = (props: StepProps) => {
	useEffect(() => {
		props.userUpdatePaymentDetails({
			payment_method: 'trial'
		});
	}, []);

	return (
		<SignupStep>
			{({ signupConfig }) => (
				<>
					<h1>About the trial</h1>
					<hr />
					<p>
						You will have access to the {signupConfig.course.subscription.trial.content_description}
						.
					</p>
					<p>
						When you’re ready to pay, click the link in the green banner running across the top of
						the page.
					</p>
					<p>Don’t worry! Your work will still be saved if your trial runs out.</p>
				</>
			)}
		</SignupStep>
	);
};

export default TrialInfoStep;
