import styled from 'styled-components';

const TOSViewerStyles = styled.div`
	max-height: 80%;
	max-width: 500px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding: 8px;
	margin-top: 8px;
	margin-bottom: 16px;
	border-top: thin solid ${({ theme }) => theme.color.brand};
	border-bottom: thin solid ${({ theme }) => theme.color.brand};
	a {
		font-weight: bold;
		text-decoration: underline;
		color: #0068d3;
	}

	h1 {
		font-size: 14px !important;
		text-transform: uppercase;
		color: black;
	}

	h2 {
		font-size: 14px;
		color: black;
		margin-top: 8px;
		margin-bottom: 8px;
	}

	h3 {
		font-size: 12px;
		color: black;
	}

	.rollover-defn {
		display: none;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		max-height: 256px;
	}
`;

export default TOSViewerStyles;
