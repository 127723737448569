import React from 'react';

import { TextInput } from '~/components/input';
import AppButton from '~/connected/app_button';
import FrontDoorInfoHeader from '~/connected/front_door_info_formatter';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

interface Props extends StepProps {}

class RegisterEmailStep extends React.Component<Props> {
	private handleEmailChange = (e) => {
		this.props.userUpdate({ email: e.target.value.replace(/ /g, '') });
	};

	private handleEmailConfirmChange = (e) => {
		this.props.userUpdate({ email_confirm: e.target.value.replace(/ /g, '') });
	};

	public render() {
		const { user_state } = this.props;
		return (
			<PathStepStyles>
				<div className="page-content">
					<FrontDoorInfoHeader />
					<h1>Enter a valid email. You will use this to log in.</h1>
					<hr />
					<TextInput
						autoFocus
						label="Email Login"
						placeholder="jane@gmail.com"
						name="email"
						value={user_state.email}
						onChange={this.handleEmailChange}
					/>
					<TextInput
						label="Re-enter email"
						placeholder="jane@gmail.com"
						name="email_confirm"
						value={user_state.email_confirm}
						onChange={this.handleEmailConfirmChange}
					/>
					{this.renderErrors()}
					{!this.props.canContinue && 'Emails must match.'}
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}

	private renderErrors() {
		const { validatingData, validationErrors } = this.props;
		if (!validatingData && validationErrors.length) {
			let errors = validationErrors.map((err, i) => {
				return (
					<div key={i} className="error-message">
						{err}
					</div>
				);
			});

			if (errors) {
				return <>{errors}</>;
			}
		}
	}
}

export default RegisterEmailStep;
