import { RootState } from '~/store';
import { CourseSubscriptionInfo, FullShippingInfo, StepConfig, SubscriptionPayload } from '~/types';
import { isCourseBeingRetaken, isCoursePresentInConfig } from '~/types/signup_config';
import { buildSubLengthDescription } from '~/utils/helpers';

export function selectCurrentStep(state: RootState): StepConfig | null {
	if (state.app.path) {
		return state.app.path.find((s) => s.name === state.app.currentStep)!;
	} else {
		return null;
	}
}

export function selectStepByName(state: RootState, name: string): StepConfig | null {
	if (state.app.config) {
		return state.app.path.find((s) => s.name === name)!;
	} else {
		return null;
	}
}

export function selectCreditCardSubscriptionInfo(state: RootState): CourseSubscriptionInfo | null {
	const { config } = state.app;

	return isCoursePresentInConfig(config)
		? {
				length: buildSubLengthDescription(config.course.subscription.duration),
				courseNumber: config.institutional_course.number,
				courseName: config.institutional_course.name,
				cost: config.course.subscription.amount_owed || config.course.subscription.cost
		  }
		: null;
}

export function selectUserSubscriptionInfo(state: RootState) {
	const { user } = state;
	return {
		...user.subscriptionPayload
	};
}

export function selectUserSubscriptionMethod(state: RootState) {
	const { user } = state;
	return user.subscriptionPayload?.payment_details?.payment_method;
}

export function selectShippingRequired(state: RootState) {
	let required = false;
	if (state.user.subscriptionValidation) {
		return state.user.subscriptionValidation.missing.shipping_details;
	}
	return required;
}

export function selectShippingDetails(state) {
	return state.user.subscriptionPayload.shipping_details;
}

export function selectPassKey(state: RootState) {
	return state.user.subscriptionPayload.payment_details?.passkey_plaintext || '';
}

export function selectFullShippingInfo(state: RootState): FullShippingInfo {
	let name = '';
	if (state.app.config.user.first_name) {
		name = `${state.app.config.user.first_name} ${state.app.config.user.last_name}`;
	} else {
		name = `${state.user.first_name} ${state.user.last_name}`;
	}

	return {
		...state.user.subscriptionPayload.shipping_details,
		name
	};
}

export function selectPaymentMethod(state: RootState) {
	return state.user.subscriptionPayload.payment_details?.payment_method;
}

export function selectCardInfo(state: RootState) {
	return {
		cardHolder: state.user.cardHolder,
		cardNumber: state.user.cardNumber,
		CVV: state.user.CVV,
		expMonth: state.user.cardExpMonth,
		expYear: state.user.cardExpYear
	};
}

export function selectRetakingCourse(state: RootState) {
	if (isCoursePresentInConfig(state.app.config)) {
		return state.app.config.course.retaking;
	} else {
		throw new Error('Course is not present in signup configuration. (selectRetakingCourse)');
	}
}

export function selectHasInstitutionalPay(state: RootState) {
	if (isCoursePresentInConfig(state.app.config)) {
		return state.app.config.course.subscription.has_institutional_pay;
	} else {
		throw new Error('Course is not present in signup configuration. (selectHasInstitutionalPay)');
	}
}

export function selectCourseIsFree(state: RootState) {
	if (isCoursePresentInConfig(state.app.config)) {
		return state.app.config.course.subscription.cost === 0;
	} else {
		throw new Error('Course is not present in signup configuration. (selectCourseIsFree)');
	}
}

export function selectAmountOwed(state: RootState) {
	if (isCoursePresentInConfig(state.app.config)) {
		if (isCourseBeingRetaken(state.app.config.course)) {
			return state.app.config.course.subscription.amount_owed;
		} else {
			throw new Error('Course is not being retaken. (selectAmountOwed)');
		}
	} else {
		throw new Error('Course is not present in signup configuration. (selectAmountOwed)');
	}
}

export function selectShowMobilePreview(state: RootState) {
	const step = selectCurrentStep(state);
	return step ? step.showcaseMobilePreview : false;
}

export function selectShowNav(state: RootState) {
	const step = selectCurrentStep(state);
	return (
		state.app.currentStep !== state.app.path[0].name &&
		step &&
		!step.hideNav &&
		!state.app.fetchingConfig &&
		state.app.currentStep !== 'lms_school' &&
		state.app.currentStep?.indexOf('not_found') == -1
	);
}

export function selectIsLoadingAsync(state: RootState) {
	return (
		state.user.asyncState.validatingData ||
		state.user.asyncState.tokenizingCard ||
		state.user.asyncState.finalizingSubscription
	);
}

export function selectSubscriptionPayload(state: RootState): SubscriptionPayload {
	const course_search = state.user.course_search_info.course;
	const course_id = course_search
		? course_search.value
		: isCoursePresentInConfig(state.app.config)
		? state.app.config.course.id
		: undefined;
	const billingAddress = state.user.isBillingAddressSameAsPhysical
		? state.user.physicalAddress
		: state.user.isBillingAddressSameAsShipping
		? state.user.shippingAddress
		: state.user.billingAddress;
	return {
		...state.user.subscriptionPayload,
		course_id,
		path_type: state.app.config.path_type,
		// todo: standardize on street1 everywhere
		shipping_details: {
			address1: state.user.shippingAddress?.street1,
			address2: state.user.shippingAddress?.street2,
			city: state.user.shippingAddress?.city,
			state: state.user.shippingAddress?.state,
			zip: state.user.shippingAddress?.zip
		},
		user: {
			email_address: state.user.email,
			password: state.user.password,
			first_name: state.user.first_name,
			last_name: state.user.last_name
		},
		tax_address: {
			street1: state.user.physicalAddress?.street1,
			street2: state.user.physicalAddress?.street2,
			city: state.user.physicalAddress?.city,
			state: state.user.physicalAddress?.state,
			zip: state.user.physicalAddress?.zip
		},
		billing_address: {
			street1: billingAddress?.street1,
			street2: billingAddress?.street2,
			city: billingAddress?.city,
			state: billingAddress?.state,
			zip: billingAddress?.zip
		},
		tax_summary: state.user.estimatedTax?.taxSummary
	};
}
