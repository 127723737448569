import cx from 'classnames';
import { Component, ReactNode } from 'react';
import MaskedInput from 'react-text-mask';
import TextInputStyles from './styles';

interface Props {
	onChange?: (value) => void;
	name?: string;
	value?: string;
	type?: string;
	label?: ReactNode;
	error?: string;
	pipe?: (a: any, b: any) => string;
	maxLength?: number;
	mask?: (string | RegExp)[];
	placeholder?: string;
	placeholderChar?: string;
	guide?: boolean;
	disabled?: boolean;
	tabIndex?: number;
	autoFocus?: boolean;
	pattern?: string;
	hidden?: boolean;
	className?: string;
}

class TextInput extends Component<Props> {
	static defaultProps = {
		mask: false,
		type: 'text',
		tabIndex: 0,
		autoFocus: false,
		hidden: false,
		placeholder: '',
		pattern: ''
	};
	public render() {
		const value = this.props.value || '';
		return !this.props.hidden ? (
			<TextInputStyles className={cx('text-input', this.props.className)}>
				<label htmlFor={this.props.name}>{this.props.label}</label>
				{this.props.mask ? (
					<MaskedInput {...this.props} type="text" value={value} id={this.props.name} />
				) : (
					<input
						type={this.props.type}
						autoFocus={this.props.autoFocus}
						id={this.props.name}
						name={this.props.name}
						onChange={this.props.onChange}
						placeholder={this.props.placeholder}
						disabled={this.props.disabled}
						tabIndex={this.props.tabIndex}
						maxLength={this.props.maxLength}
						value={value}
					/>
				)}
				{this.props.error && <span className="error">{this.props.error}</span>}
			</TextInputStyles>
		) : null;
	}
}

export default TextInput;
