import { Component } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import styled from 'styled-components';

import SelectInput, { SelectInputProps } from '~/components/input/SelectInput/SelectInput';

interface Props {
	label?: string;
	loading?: boolean;
	noOptionsMessage?: () => any;
}

interface State {
	loadingDelayComplete?: boolean;
}

const styles = {
	valueContainer: (base) => ({
		...base,
		flex: '1 0 0%',
		flexDirection: 'column'
	}),
	control: (base) => ({
		...base,
		flex: '1 0 auto'
	}),
	singleValue: (base) => ({
		...base,
		display: 'block'
	})
};

class TypeaheadSelectInput extends Component<SelectInputProps & Props, State> {
	static defaultProps = {
		label: 'Enter text below'
	};

	timeout: number | null = null;
	state = {
		loadingDelayComplete: false
	};
	componentDidUpdate(prevProps) {
		if (!prevProps.loading && this.props.loading) {
			this.timeout = setTimeout(() => {
				this.setState({
					loadingDelayComplete: true
				});
			}, 1000);
		}

		if (prevProps.loading && !this.props.loading) {
			this.setState({ loadingDelayComplete: false });
			this.timeout != null && clearTimeout(this.timeout);
		}
	}

	public render() {
		return (
			<div style={{ marginBottom: 16 }}>
				<SelectInput
					{...this.props}
					isSearchable
					blurInputOnSelect
					autoFocus
					showNativeFallback
					reactSelectStyles={styles}
					wrappingElement={TypeaheadSelectInputStyles}
				/>
				{this.props.loading && this.state.loadingDelayComplete && (
					<BarLoader width={320} color="#BDD63A" />
				)}
			</div>
		);
	}
}

const TypeaheadSelectInputStyles = styled.div`
	&.show-native-fallback {
		.soomo-select__control {
			display: none;
		}

		.native-select {
			display: block;
		}
	}

	&.force-native-feedback {
		.soomo-select__control {
			display: none;
		}

		.native-select {
			display: block;
		}
	}

	.soomo-select__dropdown-indicator {
		svg {
			color: #757575;
		}
	}

	.soomo-select__control {
		max-width: 320px;
		height: 38px;
		font-size: ${({ theme }) => theme.textInput.fontSize};
		border: none;
		caret-color: ${({ theme }) => theme.color.brand};
		border-bottom: 1px solid ${({ theme }) => theme.color.brand};
		line-height: 32px;
		background-color: transparent;
		border-radius: 0;

		align-items: flex-start;
		margin: 0;
		padding: 0;
		::placeholder {
			letter-spacing: 2px;

			font-weight: lighter;
		}

		:focus {
			outline: none;
		}

		&:hover {
			border-bottom: 1px solid ${({ theme }) => theme.color.brand};
			box-shadow: none;
		}

		&:focus {
		}
	}

	.soomo-select__value-container {
		font-size: 26px;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0;
		padding: 0;
		padding-left: 5px;
	}

	.soomo-select__control--is-focused {
		border: none;
		border-bottom: 1px solid ${({ theme }) => theme.color.brand};
		box-shadow: none;
	}

	.soomo-select__control {
		&:hover {
			border: none;
			border-bottom: 1px solid ${({ theme }) => theme.color.brand};
			box-shadow: none;
		}
	}

	.soomo-select__indicator-separator {
		display: none;
	}

	.soomo-select__placeholder {
		margin: 0;
		max-width: 320px;
		height: 38px;
		padding: 0 0 0 5px;
		font-size: 28px;
		border: none;
		padding-top: 4px;
		line-height: 28px;
		font-weight: lighter;
		color: #717171;
	}

	.soomo-select__indicator-separator {
	}

	label {
		font-weight: bold;
		color: #757575;
		text-transform: uppercase;
		letter-spacing: 1.9px;
		font-size: ${({ theme }) => theme.textInput.labelSize};
		margin-bottom: 2px;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		max-width: 320px;
		&.show-native-fallback {
			.soomo-select__control {
				display: flex;
			}
			.native-select {
				display: none;
			}
		}

		&.force-native-fallback {
			.soomo-select__control {
				display: none;
			}
			.native-select {
				display: block;
			}
		}
	}
`;

export default TypeaheadSelectInput;
