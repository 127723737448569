import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import validate from 'validate.js';

import { addressDetailsValidation } from '~/utils/validations';

import { Link } from 'react-router-dom';
import Address from '~/components/Address';
import SignupStep from '~/components/path_steps/SignupStep';
import { sm } from '~/styles/mixins/breakpoints';

const validation = (userState) => !validate(userState.shippingAddress, addressDetailsValidation);

const PrintReviewAddressesStep = () => (
	<SignupStep validation={validation} buttonText={() => <>Confirm</>}>
		{({ subscriptionPayload, shippingAddress, billingAddress, isBillingAddressSameAsShipping }) => {
			const { payment_method } = subscriptionPayload.payment_details;

			return (
				<div style={{ marginBottom: '2em' }}>
					<h1>{payment_method === 'creditcard' ? 'Review addresses' : 'Review address'}</h1>
					<hr />
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<span style={{ fontWeight: 'bold' }}>Shipping Address</span>
						<Link to="/print_shipping_address">
							<MdEdit />
							Edit
						</Link>
					</div>
					<Address address={shippingAddress} />

					{payment_method === 'creditcard' && (
						<>
							<Ruling />

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<span style={{ fontWeight: 'bold' }}>Billing Address</span>
								<Link
									to={
										isBillingAddressSameAsShipping ? '/print_shipping_address' : '/billing_address'
									}>
									<MdEdit />
									Edit
								</Link>
							</div>
							<Address
								address={isBillingAddressSameAsShipping ? shippingAddress : billingAddress}
							/>
						</>
					)}
				</div>
			);
		}}
	</SignupStep>
);

const Ruling = styled.div`
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 335px;
	height: 1px;
	background-color: #bdd63a;
	@media (max-width: ${sm}px) {
		width: 100%;
	}
`;

export default PrintReviewAddressesStep;
