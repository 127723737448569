import styled from 'styled-components';

const PathStepStyles = styled.div`
	padding-top: 14px;
	font-size: 1.5rem;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
	height: 100%;
	width: 100vw;
	h1 {
		line-height: 1.05;
		margin-bottom: 16px;
		margin-top: 16px;
	}

	p.italics {
		font-size: 14px;
		font-style: italic;
		margin-bottom: 21px;
		color: #757575;
		/* Hack */
		margin-top: -12px;
	}

	a.no-link-styles {
		text-decoration: none;
	}

	a {
		color: #0068d3;
		text-decoration: underline;
	}

	.page-content {
		line-height: 1.5em;
		font-family: 'Proxima Nova', sans-serif;
		margin-left: ${({ theme }) => theme.pathStep.mobilePadding};
		margin-right: ${({ theme }) => theme.pathStep.mobilePadding};
		border-top: none;
		padding-bottom: 18px;
		margin-bottom: 18px;
		input:disabled {
			opacity: 0.65;
		}

		input {
			&:-moz-ui-invalid:not(output) {
				box-shadow: none;
			}
		}

		hr {
			border: none;
			background-color: ${({ theme }) => theme.color.brand};
			height: 2px;
			width: 54px;
			margin-left: 0;
			@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
				display: none;
			}
		}

		ul,
		ol {
			padding-left: 18px;
			line-height: 1.75rem;
		}

		li {
			margin-bottom: 1rem;
		}

		@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.errors {
		margin-top: 16px;
	}

	.error-message {
		color: ${(props) => props.theme.color.error};
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 4px;
		}
	}

	.link {
		color: #007bff;
		text-decoration: none;
		text-decoration-line: none;
		text-decoration-style: initial;
		text-decoration-color: initial;
		background-color: transparent;
	}

	.transaction-error {
		margin-bottom: 12px;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		padding-top: 0;
		font-size: 1.2rem;
		display: block;
		width: auto;
		margin-left: ${({ theme }) => theme.pathStep.mobilePadding};

		/* Getting rid of the right bleed in service of UX requests for text to expand farther right on the signup steps */
		/* margin-right: ${({ theme }) => theme.pathStep.mobilePadding}; */
		margin-right: 0;

		height: 90%;
		.page-content {
			line-height: 1.45em;
			padding-bottom: 0;
			margin-bottom: initial;
		}

		.errors {
			margin-top: 16px;
			.error-message {
				width: initial;
			}
		}
	}

	.cvv-tooltip {
		margin-left: 4px;
		margin-bottom: 2px;
	}

	&#page-terms_of_service {
		@media (max-width: ${({ theme }) => theme.breakpoints.small}) {
			.page-content {
				max-height: 65vh;
				overflow-y: scroll;
			}
			.continue-button {
				position: absolute;
				right: 0;
				left: 0;
				bottom: 0;
			}
		}

		@media (max-width: ${({ theme }) => theme.breakpoints.large}) {
			#tos-link {
				margin-bottom: 2rem;
			}
		}

		#tos-link {
			color: #0068d3;
			text-decoration: underline !important;
			display: flex;
			justify-content: flex-end;
			svg {
				margin-left: 0.5rem;
			}
		}
	}
`;

export default PathStepStyles;
