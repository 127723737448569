const path = [
	{
		name: 'welcome_back',
		next: 'select_school',
		progress: 15,
		canContinue: true,
		showcaseMobilePreview: true,
		techCheck: true,
		hideNav: true
	},
	{
		name: 'tech_check',
		next: 'select_school',
		progress: 15
	},
	{
		name: 'select_school',
		next: 'select_instructor',
		progress: 20
	},
	{
		name: 'select_instructor',
		next: 'select_course',
		progress: 25
	},
	{
		name: 'select_course',
		next: 'webtext_subscribe',
		rehydrate_config: true,
		progress: 30
	},
	{
		name: 'webtext_subscribe',
		title: 'Subscribe to your webtext',
		next: {
			creditcard: 'full_address',
			trial: 'trial_info',
			passkey: 'passkey_input'
		},
		progress: 50
	},

	{
		name: 'full_address',
		next: 'billing_address',
		progress: 55
	},

	{
		name: 'billing_address',
		next: 'review_addresses',
		progress: 60
	},

	{
		name: 'review_addresses',
		next: 'credit_card_input',
		progress: 63
	},
	{
		name: 'credit_card_input',
		title: 'Enter your card number and CVV',
		next: 'cardholder_input',
		progress: 65
	},
	{
		name: 'cardholder_input',
		title: 'The name on the card',
		next: 'card_expiration_input',
		progress: 70
	},
	{
		name: 'card_expiration_input',
		title: 'Your card’s expiration date',
		next: 'terms_of_service_cc',
		progress: 75
	},
	{
		name: 'terms_of_service_cc',
		title: 'Terms of Service',
		next: 'card_confirm',
		progress: 80,
		canContinue: true
	},
	{
		name: 'card_confirm',
		title: 'Please double-check your card information before confirming your purchase.',
		next: 'success',
		finalize: true, // Finalize the user's subscription when leaving this step (store/app/effects)
		progress: 90
	},
	{
		name: 'passkey_input',
		title: 'Enter your passkey',
		next: 'terms_of_service',
		progress: 65,
		validate: true // Validate the user's subscription when leaving this step (store/app/effects)
	},
	{
		name: 'print_info',
		title: 'The print version (included)',
		next: 'shipping_address',
		canContinue: true,
		progress: 67
	},
	{
		name: 'shipping_address',
		title: 'Shipping address',
		next: 'terms_of_service',
		progress: 69
	},
	{
		name: 'trial_info',
		title: 'About the trial',
		next: 'terms_of_service',
		canContinue: true,
		progress: 65
	},
	{
		name: 'terms_of_service',
		title: 'Terms of Service',
		next: 'success',
		canContinue: true,
		finalize: true,
		progress: 80
	},
	{
		name: 'success',
		title: 'You’re signed up!',
		next: 'success',
		hideNav: true,
		progress: 100
	}
];

export default path;
