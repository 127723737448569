import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '~/store';
import { appActions } from '~/store/app';
import { userActions } from '~/store/user';
import { StepPropActions, StepProps, StepPropState } from '~/types';

import pathSteps from '~/components/path_steps';

import {
	selectCreditCardSubscriptionInfo,
	selectFullShippingInfo,
	selectIsLoadingAsync,
	selectPassKey,
	selectPaymentMethod,
	selectShippingDetails,
	selectUserSubscriptionMethod
} from '~/store/selectors';

/**
 * Returns component mapped to name prop on config, wrapped with the props below
 * triggers APP_STEP_MOUNTED to clue the store into which page is active.
 */
function withPathStepProps(config) {
	const Step = pathSteps[config.name];
	class PathStepContainer extends Component<StepProps> {
		componentWillMount() {
			this.props.onMount(config.name);
		}

		public render() {
			return <Step {...this.props} />;
		}
	}

	// Data from the store the path step will
	const mapStateToProps = (state: RootState): StepPropState => ({
		stepConfig: config,
		canContinue: state.app.canContinue,
		finalizing: state.user.asyncState.finalizingSubscription,
		submittingSubscription: state.app.submittingSubscription,
		validatingData: state.user.asyncState.validatingData,
		tokenizingCard: state.user.asyncState.tokenizingCard,
		tokenizationErrors: state.app.tokenizationErrors || [],
		finalizationErrors: state.app.finalizationErrors || [],
		validationErrors: state.app.validationErrors || [],
		subscriptionValidation: state.user.subscriptionValidation,
		user: state.user.subscriptionPayload.user!,
		appConfig: state.app.config,
		termsAccepted: state.user.subscriptionPayload.terms_of_service_accepted!,
		courseSearchInfo: state.user.course_search_info,
		user_state: state.user,
		shippingDetails: selectShippingDetails(state) || {},
		fullShippingInfo: selectFullShippingInfo(state),
		paymentMethod: selectPaymentMethod(state)!,
		passkey: selectPassKey(state),
		subscriptionMethod: selectUserSubscriptionMethod(state)!,
		courseSubscriptionInfo: selectCreditCardSubscriptionInfo(state)!,
		isLoadingAsync: selectIsLoadingAsync(state),
		fetchingConfig: state.app.fetchingConfig
	});

	const mapDispatchToProps = (dispatch): StepPropActions =>
		bindActionCreators(
			{
				onMount: appActions.appStepMounted,
				submitPage: appActions.appContinue,
				userUpdate: userActions.userUpdate,
				setFormValue: userActions.setFormValue,
				userUpdateAddress: userActions.userUpdateAddress,
				userUpdatePaymentDetails: userActions.userUpdatePaymentDetails,
				userSetTermsAgreed: userActions.userSetTermsAgreed,
				userUpdateCourseSearchInfo: userActions.userUpdateCourseSearchInfo,
				setCanContinue: appActions.appSetCanContinue
			},
			dispatch
		);

	return connect(mapStateToProps, mapDispatchToProps)(PathStepContainer);
}

export default withPathStepProps;
