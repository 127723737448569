import { SignupStep } from '~/components';
import { TextInput } from '~/components/input';

export default () => (
	<SignupStep validation={({ cardHolder }) => !!cardHolder}>
		{({ cardHolder, setFormValue }) => (
			<>
				<h1>Enter cardholder name</h1>
				<TextInput
					value={cardHolder}
					name="cardholder_name"
					label="Full Name (As it appears on the card)"
					className="fs-exclude"
					placeholder="John Doe"
					autoFocus
					onChange={(e) => setFormValue({ cardHolder: e.target.value })}
				/>
			</>
		)}
	</SignupStep>
);
