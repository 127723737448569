import axios from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import qs from 'qs';

import {
	AuthenticationResponseSuccess,
	EstimatedSalesTaxResponse,
	FinalizationResponse,
	SubscriptionPayload,
	TokenizationResult,
	ValidationResponse
} from '~/types';

const endpoints = {
	signup: {
		config: '/signup/student',
		authenticate: '/signup/student/authenticate',
		validate: '/signup/student/validate',
		finalize: '/signup/student/finalize',
		search_schools: '/signup/student/schools',
		search_instructors: '/signup/student/instructors',
		search_courses: '/signup/student/courses',
		estimated_sales_tax: '/signup/student/estimated_sales_tax'
	},
	printPurchase: {
		config: '/store',
		validate: '/store/validate',
		finalize: '/store/finalize',
		estimated_sales_tax: '/store/estimated_sales_tax'
	}
};

function endpoint(path_type) {
	return path_type === 'print_purchase' ? endpoints.printPurchase : endpoints.signup;
}

axios.interceptors.response.use(undefined, (error) => {
	// note that error.response can be null or undefined,
	// e.g. when a request fails with status code 0 (network error)
	const errorCode = error.response?.status;
	if (errorCode === 403) {
		window.location.hash = 'session_error';
	} else if (
		errorCode === 400 &&
		error.response.data &&
		error.response.data.error === 'invalid authenticity token'
	) {
		window.location.hash = 'session_error';
	}
	return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
	config.url = 'https://' + import.meta.env.VITE_SOOMO_CORE_API_HOST + config.url;
	// Serialize nested params in GET requests
	// https://github.com/axios/axios/issues/738#issuecomment-412905574
	config.paramsSerializer = (params) => {
		return qs.stringify(params, {
			arrayFormat: 'brackets',
			encode: true
		});
	};
	return config;
});

axios.defaults.headers.common.Accept = 'application/json';

export type SignupJwtPayload =
	| (JwtPayload & { cid: number; extra: { path_type: 'print_purchase' } })
	| (JwtPayload & { cid?: number; extra: { path_type: string } });

export const authenticate = (jwt: string) => {
	return axios
		.post<AuthenticationResponseSuccess>(endpoints.signup.authenticate, { jwt })
		.then(({ data: { token, intercom, fullstory } }) => {
			const {
				cid: courseId,
				extra: { path_type: pathType }
			} = jwtDecode<SignupJwtPayload>(jwt);

			window.sessionStorage.setItem('jwt', token);
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			return { pathType, courseId, intercom, fullstory };
		});
};

/**
 * Tokenize card info using accept.js
 * @link https://developer.authorize.net/api/reference/features/acceptjs.html#Building_your_Payment_Form
 * @param {CardInfo} params
 * @returns {Promise<any>}
 */
export const tokenizeCardInfo = (params) => {
	return new Promise<TokenizationResult>((resolve, reject) => {
		let stringMonth = params.expMonth.toString();
		const cardData = {
			cardNumber: params.cardNumber,
			month: stringMonth.length === 2 ? stringMonth : `0${stringMonth}`,
			year: params.expYear.toString().substring(2, 4),
			cardCode: params.CVV,
			fullName: params.cardHolder
		};

		const payload = {
			authData: {
				clientKey: import.meta.env.VITE_ACCEPTJS_CLIENT_KEY,
				apiLoginID: import.meta.env.VITE_ACCEPTJS_API_LOGIN_ID
			},
			cardData
		};
		// @ts-ignore
		Accept.dispatchData(payload, (result) => {
			resolve(result);
		});
	});
};

export const validateSubscription = (params: SubscriptionPayload, path_type: string) => {
	let payload: any = params;
	let modifiedPayload = {
		...payload,
		path_type
	};
	modifiedPayload.terms_of_service_accepted = payload.terms_of_service_accepted ? '1' : '0';
	return axios.post<ValidationResponse>(endpoint(path_type).validate, modifiedPayload);
};

export const finalizeSubscription = (params: SubscriptionPayload, path_type: string) => {
	let payload: any = params;
	let modifiedPayload = {
		...payload,
		path_type
	};
	modifiedPayload.terms_of_service_accepted = payload.terms_of_service_accepted ? '1' : '0';
	return axios.post<FinalizationResponse>(endpoint(path_type).finalize, modifiedPayload);
};

export const lookupSchools = () => {
	return axios.get(endpoints.signup.search_schools);
};

export const lookupInstructors = (school_id: string) => {
	return axios.get(endpoints.signup.search_instructors, {
		params: {
			school_id
		}
	});
};

export const lookupCourses = (instructor_id: string, school_id: string) => {
	return axios.get(endpoints.signup.search_courses, {
		params: {
			instructor_id,
			school_id
		}
	});
};

export const fetchSignupConfig = (course_id?: string) => {
	return axios.get(endpoints.signup.config, {
		params: {
			course_id
		}
	});
};

export const fetchPrintPurchaseConfig = (course_id: string) => {
	return axios.get(endpoints.printPurchase.config, {
		params: {
			course_id
		}
	});
};

export const fetchEstimatedTax = (params, path_type) => {
	return axios.post<EstimatedSalesTaxResponse>(endpoint(path_type).estimated_sales_tax, params);
};
