import React from 'react';

import { lookupInstructors } from '~/api';
import TypeaheadSelectInput from '~/components/input/TypeAheadSelectInput';
import AppButton from '~/connected/app_button';
import FrontDoorInfoHeader from '~/connected/front_door_info_formatter';
import PathStepStyles from '~/styles/path_step_styles';
import { SelectOption, StepProps } from '~/types';
import { useBasicInputs } from '~/utils/helpers';

interface Props extends StepProps {}

type State = {
	instructor_options: Array<SelectOption>;
	fetch_error: boolean;
	fetching: boolean;
};

class SelectInstructorStep extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			fetch_error: false,
			fetching: false,
			instructor_options: this.props.courseSearchInfo.instructor
				? [this.props.courseSearchInfo.instructor]
				: []
		};
	}

	componentDidMount() {
		const { school } = this.props.courseSearchInfo;
		if (school) {
			this.fetchInstructors();
		}
	}

	fetchInstructors = () =>
		this.setState({ fetching: true }, () =>
			lookupInstructors(this.props.courseSearchInfo.school!.value)
				.then((resp) => {
					this.setState({
						instructor_options: resp.data,
						fetch_error: false,
						fetching: false
					});
				})
				.catch((err) => {
					this.setState({
						fetch_error: true,
						fetching: false
					});
				})
		);

	private handleChange = (value) => {
		this.props.userUpdateCourseSearchInfo({
			instructor: this.state.instructor_options.find((i) => i.value == value),
			course: undefined
		});
	};

	public render() {
		const { stepConfig, appConfig, courseSearchInfo } = this.props;
		const { instructor } = courseSearchInfo;
		const { instructor_options, fetch_error, fetching } = this.state;
		const forceNativeSelect = useBasicInputs(appConfig);
		return (
			<PathStepStyles>
				<div className="page-content">
					<FrontDoorInfoHeader />
					<h1>Great! Now let’s find your instructor.</h1>
					<hr />
					<TypeaheadSelectInput
						value={instructor && instructor.value}
						onChange={this.handleChange}
						className="instructor-select"
						forceNativeFallback={forceNativeSelect}
						placeholder="Select Your Instructor"
						label="Instructor Name"
						noOptionsMessage={() => (fetching ? 'Loading' : 'No Options')}
						loading={fetching}
						options={instructor_options}
					/>
					{!fetching && fetch_error && (
						<p className="error-message" style={{ marginTop: 16 }}>
							Error loading instructors. Click{' '}
							<span className="link" onClick={this.fetchInstructors}>
								here
							</span>{' '}
							to try again.
						</p>
					)}
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}

export default SelectInstructorStep;
