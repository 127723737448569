import React from 'react';
import states from '~/fixtures/states';
import { AddressDetails } from '~/types';

interface Props {
	address: AddressDetails;
}

const Address: React.FC<Props> = ({ address }) => {
	if (!address) {
		return <>No address</>;
	}
	return (
		<div>
			<div className="fs-exclude">{address.street1}</div>
			{address.street2 && <div className="fs-exclude">{address.street2}</div>}
			<div className="fs-exclude">
				{address.city}, {states.find((s) => s.value === address.state)!.label} {address.zip}
			</div>
		</div>
	);
};

export default Address;
