type User = {
	email: string;
	first_name: string;
	last_name: string;
};

type BlankUser = {
	email: null;
	first_name: null;
	last_name: null;
};

type TrialConfiguration = {
	enabled: boolean;
	duration: number; //seconds
	duration_description: string;
	content_description: string; // Description of trial length
};

type SubscriptionConfiguration = {
	trial: TrialConfiguration;
	trial_start: string | null;
	trial_end: string | null;
	finalized: boolean;
	has_institutional_pay: boolean;
	status: string;
	cost: number;
	print_cost: number;
	duration: number;
	duration_description: string;
	payment_methods: {
		passkey: boolean;
		creditcard: boolean;
	};
};

type BaseCourse = {
	id: number;
	number: string;
	name: string;
	instructor: {
		id: number;
		name: string;
	};
	subscription: SubscriptionConfiguration;
	print: {
		ordered: boolean;
		payment_methods: {
			passkey: boolean;
			creditcard: boolean;
		};
	};
	retaking: boolean;
};

export interface RetakenCourse extends BaseCourse {
	subscription: SubscriptionConfiguration & {
		amount_paid: number;
		amount_owed: number;
	};
	retaking: true;
}

export function isCourseBeingRetaken(course): course is RetakenCourse {
	return course.retaking === true;
}

export interface FirstTimeCourse extends BaseCourse {
	subscription: SubscriptionConfiguration & {
		amount_paid: null;
		amount_owed: null;
	};
	retaking: false;
}

export interface BlankCourse {
	subscription: Record<string, never>;
	retaking?: never;
}

export type Course = FirstTimeCourse | RetakenCourse | BlankCourse;

export type Textbook = {
	desktop_img_url: string;
	print_img_url: string;
};

export type BlankTextbook = Record<string, never>;

export type InstitutionalCourse = {
	number: string;
	name: string;
};

export type School = {
	id: number;
	name: string;
	lms_school: boolean;
	lms_instructions: string;
	never_show_course_list: boolean;
};

export interface AppConfigWithCoursePresent extends BaseAppConfig {
	course: RetakenCourse | FirstTimeCourse;
	textbook: Textbook;
	school: School;
	institutional_course: InstitutionalCourse;
}

export interface AppConfigWithoutCourseOrSchoolPresent extends BaseAppConfig {
	course: BlankCourse;
	textbook: BlankTextbook;
}

export interface AppConfigWithoutCourseButWithSchoolPresent extends BaseAppConfig {
	course: BlankCourse;
	textbook: BlankTextbook;
	school: School;
}

export function isCoursePresentInConfig(config): config is AppConfigWithCoursePresent {
	return config.course.id != null;
}

export function isSchoolPresentInConfig(
	config
): config is AppConfigWithCoursePresent | AppConfigWithoutCourseButWithSchoolPresent {
	return config.school != null;
}

export function areCourseAndSchoolBlankInConfig(
	config
): config is AppConfigWithoutCourseOrSchoolPresent {
	return config.course.id == null && config.school == null;
}

export type AppConfig =
	| AppConfigWithCoursePresent
	| AppConfigWithoutCourseButWithSchoolPresent
	| AppConfigWithoutCourseOrSchoolPresent;

interface BaseAppConfig {
	tos_html: string;
	tos_pdf_url: string | null;
	finalized: boolean;
	path_type:
		| 'LMS'
		| 'front_door'
		| 'front_door_returning'
		| 'prefilled'
		| 'complete_purchase'
		| 'print_purchase';
	path_config: {
		// Overrides for content in the path
		webtext_info_points?: string[];
		success_points?: string[];
		success_message?: string;
		welcome_step_ownership_phrasing?: string;
		abbreviated_path?: boolean;
	};
	user: User | BlankUser;
	user_agent: {
		unsupported: boolean;
		use_legacy_inputs: boolean;
	};
	persist: boolean;
}
