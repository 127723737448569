import validate from 'validate.js';

validate.extend(validate.validators.datetime, {
	parse: function (value, options) {
		return value;
	},
	format: function (value, options) {
		return value.toString();
	}
});

import { SignupStep } from '~/components';
import { CreditCardDateInput } from '~/components/input';

const validationConstraints = {
	cardExpMonth: {
		presence: true,
		numericality: {
			onlyInteger: true,
			greaterThan: 0,
			lessThanOrEqualTo: 12
		}
	},
	cardExpYear: {
		presence: true,
		numericality: {
			onlyInteger: true,
			greaterThan: new Date().getFullYear() - 1
		}
	},
	expDate: {
		datetime: {
			earliest: new Date(),
			message: '^This expiration date has already passed'
		}
	}
};

const validation = (userState, setFormValue) => {
	const { cardExpMonth, cardExpYear } = userState;
	var expDate;
	if (cardExpMonth > 0 && cardExpMonth <= 12 && cardExpYear > 2000) {
		// expDate is midnight at the end of the expiration
		// month, which is the same as midnight of the first
		// day of the following month. The year is advanced
		// if the card expires in December.
		if (cardExpMonth === 12) {
			expDate = new Date(cardExpYear + 1, 0);
		} else {
			expDate = new Date(cardExpYear, cardExpMonth);
		}
	}

	let errors = validate(
		{
			cardExpMonth,
			cardExpYear,
			expDate
		},
		validationConstraints,
		{
			format: 'flat'
		}
	);

	if (errors) {
		setFormValue(errors);
	}
	return !errors;
};

export default () => (
	<SignupStep validation={validation}>
		{({
			cardExpMonth,
			cardExpYear,
			setFormValue,
			formErrors,
			submitCount,
			submittedWhileDisabled
		}) => (
			<>
				<h1 style={{ fontSize: 25 }}>Enter your card’s expiration date</h1>
				<div className="exp-input">
					<CreditCardDateInput
						value={{
							expMonth: cardExpMonth,
							expYear: cardExpYear
						}}
						onChange={(value) =>
							setFormValue({
								cardExpMonth: value.expMonth,
								cardExpYear: value.expYear
							})
						}
					/>
				</div>
				{formErrors && submittedWhileDisabled && <Errors errors={formErrors} />}
			</>
		)}
	</SignupStep>
);

const Errors = ({ errors }) => {
	if (errors && Array.isArray(errors)) {
		const messages = errors.map((e, i) => (
			<div key={i} className="error-message">
				{e}
			</div>
		));
		return <>{messages}</>;
	}
	return null;
};
