import { buildSelectPaymentOptions, numberToWord } from '~/utils/helpers';

import { SelectInput } from '~/components/input';
import SignupStep from './SignupStep';

const updatePaymentMethod = (paymentMethod) => ({
	subscriptionPayload: { payment_details: { payment_method: paymentMethod } }
});
const getPaymentMethod = (formState) =>
	formState.subscriptionPayload?.payment_details.payment_method;

const WebtextSubscribeStep = () => (
	<SignupStep validation={(formState) => !!getPaymentMethod(formState)}>
		{({ setFormValue, signupConfig, ...formState }) => {
			const paymentOptions = buildSelectPaymentOptions(signupConfig);
			return (
				<>
					<h1>Activate your account</h1>
					<hr />
					<p>
						{paymentOptions.length === 1
							? 'There is one way you can do this'
							: `There are ${numberToWord[paymentOptions.length]} ways you can do this:`}
					</p>
					<ol>
						{paymentOptions.map((option, i) => (
							<li key={i}>{option.description}</li>
						))}
					</ol>
					<br />
					<SelectInput
						blurInputOnSelect={true}
						className="subscribe-select"
						placeholder="Select a subscription type"
						options={paymentOptions}
						value={getPaymentMethod(formState)}
						onChange={(subscriptionMethod) => setFormValue(updatePaymentMethod(subscriptionMethod))}
					/>
				</>
			);
		}}
	</SignupStep>
);

export default WebtextSubscribeStep;
