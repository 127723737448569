import styled from 'styled-components';

const TextInputStyles = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;

	label {
		font-weight: bold;
		color: #757575;
		text-transform: uppercase;
		letter-spacing: 1.9px;
		font-size: ${({ theme }) => theme.textInput.labelSize};
		margin-bottom: 2px;
	}

	input {
		border-radius: 0;
		margin: 0;
		max-width: 320px;
		height: 38px;
		padding: 0 0 0 6px;
		font-size: ${({ theme }) => theme.textInput.fontSize};
		border: none;
		caret-color: ${({ theme }) => theme.color.brand};
		border-bottom: 1px solid ${({ theme }) => theme.color.brand};
		padding-top: 4px;
		line-height: 28px;
		::placeholder {
			letter-spacing: 2px;
			color: #757575;
			font-weight: lighter;
		}
		:focus {
			outline: none;
		}
	}

	.error {
		font-size: 0.75em;
		font-style: italic;
		letter-spacing: 1px;
		padding-top: 4px;
		padding-bottom: 4px;
		color: ${({ theme }) => theme.color.error};
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.small}) {
		input {
			background: none;
			width: inherit;
		}
	}
`;

export default TextInputStyles;
