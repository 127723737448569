import React from 'react';

import { Link } from 'react-router-dom';
import ErrorBlurb from '~/components/error_blurb';
import PathStepStyles from '~/styles/path_step_styles';
import { StepPropsWithCoursePresentInAppConfig } from '~/types';
import { buildSelectPaymentOptions } from '~/utils/helpers';

export interface Props extends StepPropsWithCoursePresentInAppConfig {}

export interface State {}

export interface ErrorStepConfigParams {
	error_tips: [string];
}

class SubscriptionErrorStep extends React.Component<Props, State> {
	public render() {
		const { finalizationErrors } = this.props;
		const errorCount = finalizationErrors.length;
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>There Was A Problem Starting Your Subscription</h1>
					<hr />
					{errorCount ? (
						<>
							<p>The following error{errorCount > 1 ? 's were' : ' was'} found</p>
							<ul>
								{finalizationErrors.map((error, i) => (
									<li key={i}>{error}</li>
								))}
							</ul>
						</>
					) : (
						''
					)}
					<ErrorBlurb />
					{buildSelectPaymentOptions(this.props.appConfig).length > 1 && (
						<p>
							You may click{' '}
							<Link to="webtext_subscribe" className="no-link-styles">
								here
							</Link>{' '}
							to go back and choose a different subscription method
						</p>
					)}
				</div>
			</PathStepStyles>
		);
	}
}

export default SubscriptionErrorStep;
