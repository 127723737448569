import Rollbar from 'rollbar';

const rollbarConfig = {
	accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: import.meta.env.VITE_ROLLBAR_ENV,
		client: {
			javascript: {
				code_version: import.meta.env.VITE_COMMIT_REF,
				source_map_enabled: true
			}
		},
		server: {
			branch: import.meta.env.VITE_BRANCH
		}
	}
};

const rollbarInstance = import.meta.env.VITE_ROLLBAR_ENABLED ? new Rollbar(rollbarConfig) : null;

type LoggingFunction = (...args: Rollbar.LogArgument[]) => Rollbar.LogResult | undefined;

export const logError: LoggingFunction = (...args) => {
	console.error(...args);
	return rollbarInstance?.error(...args);
};

export const logWarn: LoggingFunction = (...args) => {
	console.warn(...args);
	return rollbarInstance?.warn(...args);
};

export const logInfo: LoggingFunction = (...args) => {
	console.info(...args);
	return rollbarInstance?.info(...args);
};

export const logDebug: LoggingFunction = (...args) => {
	console.debug(...args);
	return rollbarInstance?.debug(...args);
};
