import validate from 'validate.js';

import { HelpTip, SignupStep } from '~/components';
import { TextInput } from '~/components/input';
import { ccMask } from '~/utils/masks';

const CVVLabel = (
	<span>
		CVV Number
		<HelpTip tip="A 3- or 4-digit number usually on the back of the card." />
	</span>
);

const validation = {
	cardNumber: {
		presence: true,
		length: {
			minimum: 15
		}
	},
	CVV: {
		presence: true,
		length: {
			minimum: 3,
			message: '^CVV must be at least 3 digits'
		}
	}
};

export default () => (
	<SignupStep
		validation={({ CVV, cardNumber }) => {
			return !validate(
				{
					CVV,
					cardNumber
				},
				validation
			);
		}}>
		{({ cardNumber, CVV, setFormValue }) => (
			<>
				<h1>Enter your card number</h1>
				<p className="italics">
					Visa, Mastercard, or American Express. Please note that we do not issue refunds.
				</p>
				<TextInput
					name="card_number"
					placeholder="1234 5678 1234 5678"
					label="Card Number"
					className="fs-exclude"
					type="tel"
					autoFocus
					guide={false}
					mask={ccMask}
					value={cardNumber}
					onChange={(e) => setFormValue({ cardNumber: e.target.value.replace(/\s+/g, '') })}
				/>
				<TextInput
					type="tel"
					name="CVV"
					className="fs-exclude"
					placeholder="123x"
					guide={false}
					mask={[/\d/, /\d/, /\d/, /\d/]}
					label={CVVLabel}
					value={CVV}
					onChange={(e) => setFormValue({ CVV: e.target.value })}
				/>
			</>
		)}
	</SignupStep>
);
