import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { FaQuestion } from 'react-icons/fa';
import styled from 'styled-components';

import boxShadowMixin from '~/styles/mixins/box_shadow';

export interface Props {
	desktopImageURL?: string;
	printImageURL?: string;
	showPrint?: boolean;
	showDefault?: boolean;
	renderBookIn3d?: boolean;
}

export interface State {
	desktopLoaded: boolean;
	useDefaultDesktop: boolean;
}

const WebtextPreview: React.FC<Props> = (props) => {
	const { showPrint, printImageURL, desktopImageURL, showDefault } = props;
	const [desktopLoaded, setDesktopLoaded] = useState(false);
	const [errorLoadingImg, setErrorLoadingImg] = useState(false);

	const showDesktop = !showPrint;
	const showDefaultLaptopImg = errorLoadingImg || !desktopImageURL || showDefault;
	const showPrintImage = !showDefault && showPrint;

	useEffect(() => {
		setErrorLoadingImg(false);
	}, [desktopImageURL]);

	return (
		<WebtextPreviewStyles>
			<div id="desktop-preview">
				{showDesktop && (
					<img
						id="desktop-preview-shell"
						className={cx('fade-in', {
							hidden: showPrint
						})}
						src="https://s3.amazonaws.com/assets.webtexts.com/signup/laptop_shell.png"
					/>
				)}
				{showDesktop && !showDefaultLaptopImg && (
					<img
						id="desktop-preview-img"
						src={desktopImageURL}
						onLoad={(e) => setDesktopLoaded(true)}
						onError={(e) => setErrorLoadingImg(true)}
						className={cx({
							'fade-in': desktopLoaded,
							hidden: showPrint
						})}
					/>
				)}
				{showDesktop && showDefaultLaptopImg && (
					<img
						className="fade-in"
						id="desktop-preview-img"
						src="https://s3.amazonaws.com/assets.webtexts.com/signup/textbook_image_desktop.png"
					/>
				)}
			</div>
			{showPrint && <PrintPreview url={printImageURL!} show3d={props.renderBookIn3d} />}
		</WebtextPreviewStyles>
	);
};

interface PrintProps {
	url: string;
	show3d?: boolean;
}

const PrintPreview: React.FC<PrintProps> = ({ url, show3d }) => {
	const [errorLoadingPrintImg, setErrorLoadingPrintImg] = useState(false);

	useEffect(() => {
		setErrorLoadingPrintImg(false);
	}, [url]);

	if (errorLoadingPrintImg) {
		return (
			<div id="print-img-error">
				<FaQuestion size={64} />
				<h2>Unable to load preview image</h2>
			</div>
		);
	}

	if (show3d) {
		return (
			<div className="book-container fade-in">
				<div className="book">
					<img className="fade-in" onError={(e) => setErrorLoadingPrintImg(true)} src={url} />
				</div>
			</div>
		);
	}

	return (
		<img
			id="print-preview-img"
			className="fade-in"
			onError={(e) => setErrorLoadingPrintImg(true)}
			src={url}
		/>
	);
};

export default WebtextPreview;

const WebtextPreviewStyles = styled.div`
	background: linear-gradient(to right, #b7cbce 0%, #e2cab9 100%);
	border-radius: 4px;
	border: 4px white;
	height: 100%;
	max-width: 480px;
	min-height: 312px;
	max-height: 400px;
	width: auto;
	overflow: hidden;
	position: relative;

	#desktop-preview,
	#desktop-preview-shell,
	#print-preview-img,
	#desktop-preview-img {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
	}

	#desktop-preview {
		left: 5%;
		right: 5%;
		img {
			background-color: transparent;
			max-width: 612px;
			opacity: 0;
			transition: opacity 400ms linear;
			&.fade-in {
				opacity: 1;
			}
		}
	}

	#desktop-preview-shell {
		width: 100%;
		left: 0;
		right: 0;
	}

	#desktop-preview-img {
		width: 75%;
		left: 10%;
		right: 10%;
	}

	#print-preview-img {
		${boxShadowMixin(1)};
		width: 60%;
		margin: auto;
		left: 10%;
		top: 0%;
		bottom: 0%;
		right: 10%;
	}

	#mobile-preview-shell {
		position: absolute;
		right: 22%;
		bottom: 20%;
		opacity: 0;
		transition: opacity 400ms linear;
		&.fade-in {
			opacity: 1;
		}
	}

	#lms-warning {
		opacity: 1;
		width: 256px;
		height: 256px;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	#print-img-error {
		opacity: 70%;
		color: white;
		font-family: 'Arial';
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		position: relative;
		bottom: 0;
		top: 0;
	}

	.book-container {
		display: flex;
		align-items: center;
		justify-content: center;
		perspective: 600px;
		height: 100%;

		@keyframes initAnimation {
			0% {
				transform: rotateY(0deg);
			}
			100% {
				transform: rotateY(-30deg);
			}
		}

		.book {
			right: -0px;
			margin: auto;
			width: 200px;
			height: 255px;
			position: relative;
			transform-style: preserve-3d;
			transform: rotateY(-30deg);
			transition: 1s ease;
			animation: 1s ease 0s 1 initAnimation;
		}

		.book:hover {
			transform: rotateY(0deg);
		}

		.book > :first-child {
			position: absolute;
			top: 0;
			left: 0;
			background-color: red;
			width: 200px;
			height: 255px;
			transform: translateZ(25px);
			background-color: #01060f;
			border-radius: 0 2px 2px 0;
			box-shadow: 5px 5px 20px #666;
		}

		.book::before {
			position: absolute;
			content: ' ';
			background-color: blue;
			left: 0;
			top: 3px;
			width: 48px;
			height: 248px;
			transform: translateX(172px) rotateY(90deg);
			background: linear-gradient(
				90deg,
				#fff 0%,
				#f9f9f9 5%,
				#fff 10%,
				#f9f9f9 15%,
				#fff 20%,
				#f9f9f9 25%,
				#fff 30%,
				#f9f9f9 35%,
				#fff 40%,
				#f9f9f9 45%,
				#fff 50%,
				#f9f9f9 55%,
				#fff 60%,
				#f9f9f9 65%,
				#fff 70%,
				#f9f9f9 75%,
				#fff 80%,
				#f9f9f9 85%,
				#fff 90%,
				#f9f9f9 95%,
				#fff 100%
			);
		}

		.book::after {
			position: absolute;
			top: 0;
			left: 0;
			content: ' ';
			width: 200px;
			height: 255px;
			transform: translateZ(-25px);
			background-color: #01060f;
			border-radius: 0 2px 2px 0;
			box-shadow: -10px 0 50px 10px #666;
		}
	}
`;
