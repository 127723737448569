import { AxiosError } from 'axios';
import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
	CourseSearchInfo,
	EstimatedSalesTaxResponseFailure,
	EstimatedSalesTaxResponseSuccess,
	PaymentDetails,
	ShippingDetails,
	SubscriptionPayload,
	TokenizationResult,
	ValidationResponse
} from '~/types';

export const userUpdate = createStandardAction('USER_UPDATE')<{
	[s: string]: any;
}>();

export const setFormValue = createStandardAction('SET_FORM_VALUE')<{
	[s: string]: any;
}>();

export const userSetTermsAgreed = createStandardAction('USER_SET_TERMS_AGREED')<boolean>();

export const userUpdateSubscriptionInfo = createStandardAction(
	'USER_UPDATE_SUBSCRIPTION_INFO'
)<SubscriptionPayload>();

export const userSetTokenizedCC = createStandardAction('USER_SET_TOKENIZED_CC')<string>();

export const userUpdatePaymentDetails = createStandardAction(
	'USER_UPDATE_PAYMENT_DETAILS'
)<PaymentDetails>();

export const userUpdateAddress = createStandardAction('USER_UPDATE_ADDRESS')<ShippingDetails>();

export const userUpdateCourseSearchInfo = createStandardAction(
	'USER_UPDATE_COURSE_SEARCH_INFO'
)<CourseSearchInfo>();

export const userSubscriptionValidation = createAsyncAction(
	'USER_SUBSCRIPTION_VALIDATION_REQUEST',
	'USER_SUBSCRIPTION_VALIDATION_SUCCESS',
	'USER_SUBSCRIPTION_VALIDATION_FAILURE'
)<undefined, ValidationResponse, Error>();

export const userSubscriptionFinalization = createAsyncAction(
	'USER_SUBSCRIPTION_FINALIZATION_REQUEST',
	'USER_SUBSCRIPTION_FINALIZATION_SUCCESS',
	'USER_SUBSCRIPTION_FINALIZATION_FAILURE'
)<undefined, ValidationResponse, Error>();

export const userTokenizeCardInfo = createAsyncAction(
	'USER_TOKENIZE_CARD_INFO_REQUEST',
	'USER_TOKENIZE_CARD_INFO_SUCCESS',
	'USER_TOKENIZE_CARD_INFO_FAILURE'
)<any, TokenizationResult, Error>();

export const userFetchEstimatedTax = createAsyncAction(
	'USER_ESTIMATED_TAX_REQUEST',
	'USER_ESTIMATED_TAX_SUCCESS',
	'USER_ESTIMATED_TAX_FAILURE'
)<any, EstimatedSalesTaxResponseSuccess, AxiosError<EstimatedSalesTaxResponseFailure>>();
