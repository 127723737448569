import React from 'react';

import { lookupCourses } from '~/api';
import TypeaheadSelectInput from '~/components/input/TypeAheadSelectInput';
import AppButton from '~/connected/app_button';
import FrontDoorInfoHeader from '~/connected/front_door_info_formatter';
import PathStepStyles from '~/styles/path_step_styles';
import { SelectOption, StepProps } from '~/types';
import { useBasicInputs } from '~/utils/helpers';

export interface Props extends StepProps {}

type State = {
	course_options: Array<SelectOption>;
	fetch_error: boolean;
	fetching: boolean;
};

class SelectCourseStep extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			fetch_error: false,
			fetching: false,
			course_options: this.props.courseSearchInfo.course ? [this.props.courseSearchInfo.course] : []
		};
	}

	componentDidMount() {
		const { instructor, school } = this.props.courseSearchInfo;
		if (instructor && school) {
			this.fetchCourses();
		}
	}

	fetchCourses = () =>
		this.setState({ fetching: true }, () =>
			lookupCourses(
				this.props.courseSearchInfo.instructor!.value,
				this.props.courseSearchInfo.school!.value
			)
				.then((resp) => {
					this.setState({
						course_options: resp.data,
						fetch_error: false,
						fetching: false
					});
				})
				.catch((err) => {
					this.setState({
						fetch_error: true,
						fetching: false
					});
				})
		);

	private handleChange = (value) => {
		this.props.userUpdateCourseSearchInfo({
			course: this.state.course_options.find((c) => c.value == value)
		});
	};

	public render() {
		const { stepConfig, appConfig, courseSearchInfo } = this.props;
		const { course } = courseSearchInfo;
		const { course_options, fetch_error, fetching } = this.state;
		const forceNativeSelect = useBasicInputs(appConfig);
		return (
			<PathStepStyles>
				<div className="page-content">
					<FrontDoorInfoHeader />
					<h1>Okay, now find your course and section.</h1>
					<hr />
					<TypeaheadSelectInput
						value={course && course.value}
						onChange={this.handleChange}
						placeholder="Select Your Course"
						forceNativeFallback={forceNativeSelect}
						className="course-select"
						label="Course Number"
						loading={fetching}
						options={course_options}
					/>
					{!fetching && fetch_error && (
						<p className="error-message" style={{ marginTop: 16 }}>
							Error loading courses. Click{' '}
							<span className="link" onClick={this.fetchCourses}>
								here
							</span>{' '}
							to try again
						</p>
					)}
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}

export default SelectCourseStep;
