import React from 'react';

import { AppConfigWithCoursePresent } from '~/types';
import SignupStep from './SignupStep';

const round2 = (v) => Math.round(v * 100) / 100;

const WelcomeStep = () => (
	<SignupStep buttonText={buttonText}>
		{({ signupConfig }) => {
			const { course } = signupConfig;
			const showRetake = course.retaking && !course.subscription.has_institutional_pay;
			return showRetake ? (
				<WelcomeBack appConfig={signupConfig} />
			) : (
				<Welcome appConfig={signupConfig} />
			);
		}}
	</SignupStep>
);

export default WelcomeStep;

const buttonText = ({ signupConfig }) => {
	const { course } = signupConfig;
	const showRetake = course.retaking && !course.subscription.has_institutional_pay;
	const buttonText = showRetake
		? course.subscription.amount_owed > 0
			? 'Subscribe'
			: 'Ok'
		: 'Start';

	return <>{buttonText}</>;
};

const Welcome: React.FC<{ appConfig: AppConfigWithCoursePresent }> = ({ appConfig }) => {
	const { user, school, institutional_course } = appConfig;
	return (
		<div>
			<br />
			<h1>Nice to meet you!</h1>
			<hr />
			<p className="font-weight-bold">
				Hello, {user.first_name} {user.last_name} from {school.name}.
			</p>
			<p>
				We are Soomo Learning.{' '}
				{appConfig.path_config.welcome_step_ownership_phrasing ||
					'We make the webtext for your course'}
				: <br />
				<span className="font-weight-bold">
					{institutional_course.name} ({institutional_course.number})
				</span>
			</p>
			{!appConfig.finalized && <p>Let’s get you signed up.</p>}
		</div>
	);
};

const WelcomeBack: React.FC<{ appConfig: AppConfigWithCoursePresent }> = ({ appConfig }) => {
	const { user, course, institutional_course, school } = appConfig;
	const { retaking } = course;
	const owed = round2(course.subscription.amount_owed);
	const paid = round2(course.subscription.amount_paid);
	const cost = round2(course.subscription.cost);

	return (
		<div>
			<br />
			<h1>
				Welcome back, {user.first_name} {user.last_name}!
			</h1>

			{retaking && owed > 0 && (
				<h2
					style={{
						lineHeight: 1.05,
						fontSize: 20,
						marginBottom: '16px',
						marginTop: '16px'
					}}>
					You have a ${paid} credit toward this course.
				</h2>
			)}

			<p>
				It looks like you’ve previously taken {institutional_course.name} (
				{institutional_course.number}) at {school.name}.
			</p>
			{owed > 0 && (
				<div>
					<p>
						The last webtext you used for this course was ${paid}. The course is now using a webtext
						with additional content and is priced at ${cost}.
					</p>
					<p>We’ve given you a credit for your last purchase, so all you need to pay is ${owed}.</p>
				</div>
			)}
			{owed === 0 && (
				<p>
					We’ve transferred your subscription so you don't have to pay for the same webtext twice.
				</p>
			)}
		</div>
	);
};
