import { support_email, support_phone } from '~/fixtures/properties';

const ErrorBlurb = () => (
	<p>
		Please call support at{' '}
		<a href={`tel:${support_phone}`} target="_blank">
			{support_phone}
		</a>
		, email us at{' '}
		<a href={`mailto:${support_email}`} target="_blank">
			{support_email}
		</a>
		, or click the round button in the lower right.
	</p>
);

export default ErrorBlurb;
