const path = [
	{
		name: 'print_purchase', // Used for routing and tracked in store.app.currentStep
		next: null, // Default next step to visit onSubmit. Can be overridden in store/app/effects
		progress: 10, // Progress indicator at the top of the app
		canContinue: true, // Toggles store.app.canContinue. Steps without this are false by default
		hideNav: true // Toggles display of the forward and back nav buttons
	},

	{
		name: 'print_payment_type',
		title: 'Choose your payment',
		next: {
			passkey: 'print_passkey_input',
			creditcard: 'credit_card_input'
		},
		progress: 15
	},

	{
		name: 'print_passkey_input',
		title: 'Enter your passkey',
		next: 'print_shipping_address',
		validate: true,
		progress: 30
	},

	{
		name: 'credit_card_input',
		title: 'Enter your card number and CVV',
		next: 'cardholder_input',
		progress: 20
	},
	{
		name: 'cardholder_input',
		title: 'The name on the card',
		next: 'card_expiration_input',
		progress: 30
	},
	{
		name: 'card_expiration_input',
		title: 'Your card’s expiration date',
		next: 'print_shipping_address',
		progress: 40
	},
	{
		name: 'print_shipping_address',
		next: 'billing_address',
		progress: 50
	},

	{
		name: 'billing_address',
		next: 'print_review_addresses',
		progress: 60
	},

	{
		name: 'print_review_addresses',
		next: {
			passkey: 'print_success',
			creditcard: 'card_confirm'
		},
		finalize: {
			passkey: true,
			creditcard: false
		},
		progress: 75
	},

	{
		name: 'card_confirm',
		title: 'Please double-check your card information before confirming your purchase.',
		next: 'print_success',
		finalize: true, // Finalize the user's subscription when leaving this step (store/app/effects)
		progress: 90
	},

	{
		name: 'print_success',
		next: null,
		hideNav: true,
		progress: 100
	}
];

export default path;
