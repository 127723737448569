import validate from 'validate.js';

import { addressDetailsValidation } from '~/utils/validations';

import { HelpTip, SignupStep } from '~/components';
import { AddressInput } from '~/components/input';

const validation = (userState) => !validate(userState.physicalAddress, addressDetailsValidation);

const tipContent =
	'Sales tax is calculated based on where you are right now. For example, use your campus address rather than your family’s home address.';

export default () => (
	<SignupStep validation={validation}>
		{({ setFormValue, physicalAddress, isBillingAddressSameAsPhysical }) => (
			<>
				<h1>
					<>
						Enter current address <HelpTip tip={tipContent} />
					</>
				</h1>

				<p className="italics">
					<>
						We’ll use this address to calculate sales tax; <br />
						nothing will be shipped here.
					</>
				</p>

				<AddressInput
					value={physicalAddress}
					onChange={(physicalAddress) => {
						return setFormValue({ physicalAddress });
					}}
				/>
				<input
					style={{ marginRight: '.5rem' }}
					type="checkbox"
					id="billing-address-same-as-current-address"
					checked={isBillingAddressSameAsPhysical}
					onChange={({ target }) =>
						setFormValue({ isBillingAddressSameAsPhysical: target.checked })
					}
				/>

				<label htmlFor="billing-address-same-as-current-address">
					This is also my billing address
				</label>
			</>
		)}
	</SignupStep>
);
