import Tippy from '@tippyjs/react';
import React from 'react';
import 'tippy.js/dist/tippy.css';

interface Props {
	tip: string;
}

const HelpTip: React.FC<Props> = (props) => (
	<Tippy content={props.tip}>
		<svg
			width="13px"
			height="13px"
			viewBox="0 0 13 13"
			version="1.1"
			style={{ position: 'relative', top: '-.5em' }}>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Information-rollover" fill="#8B9D2F" fillRule="nonzero">
					<path
						d="M6.5,13 C8.28943452,13 9.82012649,12.3664435 11.0920759,11.0993304 C12.3640253,9.83221726 13,8.29910714 13,6.5 C13,4.71056548 12.3640253,3.17987351 11.0920759,1.90792411 C9.82012649,0.635974702 8.28943452,0 6.5,0 C4.71056548,0 3.17745536,0.638392857 1.90066964,1.91517857 C0.633556548,3.18229167 0,4.71056548 0,6.5 C0,8.29910714 0.635974702,9.83221726 1.90792411,11.0993304 C3.17987351,12.3664435 4.71056548,13 6.5,13 Z M6,4 C5.72916667,4 5.49479167,3.90104167 5.296875,3.703125 C5.09895833,3.50520833 5,3.27083333 5,3 C5,2.72916667 5.09895833,2.49479167 5.296875,2.296875 C5.49479167,2.09895833 5.72916667,2 6,2 C6.27083333,2 6.50520833,2.09895833 6.703125,2.296875 C6.90104167,2.49479167 7,2.72916667 7,3 C7,3.27083333 6.90104167,3.50520833 6.703125,3.703125 C6.50520833,3.90104167 6.27083333,4 6,4 Z M8,11 L5,11 L5,9.5 L5.85714286,9.5 L5.85714286,6.5 L5,6.5 L5,5 L7.14285714,5 L7.14285714,9.5 L8,9.5 L8,11 Z"
						id="i"></path>
				</g>
			</g>
		</svg>
	</Tippy>
);

export default HelpTip;
