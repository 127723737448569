import { Component } from 'react';
import styled from 'styled-components';

import SelectInput from '~/components/input/SelectInput/SelectInput';

interface MonthYear {
	expMonth: number;
	expYear: number;
}

interface State {
	monthInputValue: string;
	yearInputValue: string;
}

interface Props {
	value: MonthYear;
	onChange: (value: MonthYear) => void;
	forceNativeSelect?: boolean;
}

const months = new Array(12).fill(1).map((v, i) => ({ label: v + i, value: v + i }));

const years = new Array(32)
	.fill(new Date().getFullYear())
	.map((v, i) => ({ label: (v + i).toString(), value: (v + i).toString() }));

class CreditCardDateInput extends Component<Props, State> {
	constructor(props) {
		super(props);
		const { expMonth, expYear } = this.props.value;
		this.state = {
			monthInputValue: expMonth ? expMonth.toString() : '',
			yearInputValue: expYear ? expYear.toString() : ''
		};
	}

	private monthChanged = (expMonth) => {
		let { expYear } = this.props.value;
		this.props.onChange({
			expMonth,
			expYear
		});
	};

	private yearChanged = (expYear) => {
		let { expMonth } = this.props.value;
		this.props.onChange({
			expMonth,
			expYear
		});
	};

	public render() {
		let { expMonth, expYear } = this.props.value;
		return (
			<CreditCardDateInputStyles className="row no-gutters align-items-center">
				<div className="field col-12 col-sm-5">
					<SelectInput
						name="month"
						placeholder="month"
						className="month-select"
						hideInFullstory
						isSearchable
						value={expMonth}
						options={months}
						blurInputOnSelect={true}
						onChange={this.monthChanged}
						inputValue={this.state.monthInputValue}
						onInputChange={(val) => {
							let newVal = '';
							const intVal = parseInt(val);
							if (intVal) {
								let strVal = intVal.toString();
								if (strVal.length > 2) {
									strVal = strVal.substring(0, 2);
								}
								newVal = strVal;
							}
							this.setState({
								...this.state,
								monthInputValue: newVal
							});
						}}
						showNativeFallback
					/>
				</div>
				<div className="field col-12 col-sm-5">
					<SelectInput
						name="year"
						placeholder="year"
						className="year-select"
						hideInFullstory
						isSearchable
						value={expYear}
						options={years}
						blurInputOnSelect={true}
						onChange={this.yearChanged}
						inputValue={this.state.yearInputValue}
						onInputChange={(val) => {
							let newVal = '';
							const intVal = parseInt(val);
							if (intVal) {
								let strVal = intVal.toString();
								if (strVal.length > 4) {
									strVal = strVal.substring(0, 4);
								}
								newVal = strVal;
							}
							this.setState({
								...this.state,
								yearInputValue: newVal
							});
						}}
						showNativeFallback
					/>
				</div>
				<div className="field col-12 col-sm-2">
					<div className="exp-result fs-exclude">
						{expMonth ? expMonth : <span className="xx">XX</span>}/
						{expYear ? expYear.toString().substring(2, 4) : <span className="xx">XX</span>}
					</div>
				</div>
			</CreditCardDateInputStyles>
		);
	}
}

const CreditCardDateInputStyles = styled.div`
	width: 376px;
	display: flex;
	justify-content: center;
	.xx {
		color: #757575;
	}

	.field {
		margin-bottom: 8px;
		> div {
			margin-bottom: 0;
		}
	}

	.exp-result {
		font-size: 2.5rem;
		font-weight: lighter;
		text-align: left;
		margin-top: 16px;
	}

	.soomo-select__control {
		height: 52px;
		border: 2px solid ${(props) => props.theme.selectInput.border};
		border-radius: 0;
		background-color: white;
		text-align: center;
		font-family: Helvetica, Arial, sans-serif;
		font-size: 0.85rem;
		letter-spacing: 0.15rem;
		text-transform: uppercase;
		:hover {
			border: 2px solid ${(props) => props.theme.selectInput.border};
		}
	}

	.soomo-select__placeholder {
		color: #b4b4b4;
	}

	.soomo-select__value-container {
		align-items: center;
		justify-content: center;
		word-wrap: unset;
		white-space: nowrap;
		text-align: center;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		font-size: 0.975rem;
		margin-left: 20px;
	}

	.soomo-select__input {
		input {
			letter-spacing: 0.15rem;
		}
	}

	.soomo-select__indicator-separator {
		display: none;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		.field {
			padding-right: 8px;
		}

		.soomo-select__control {
			max-width: 90%;
		}

		.exp-result {
			/* font-size: 1.75rem; */
			font-size: 28px;
			text-align: center;
			margin-top: 0;
		}
	}
`;

export default CreditCardDateInput;
