import React from 'react';
import { connect } from 'react-redux';

import { selectShowNav } from '~/store/selectors';

import ErrorBoundary from '~/components/error_boundary';
import NavButtons from '~/components/input/NavButtons';
import Spinner from '~/components/spinner';
import SupportBar from '~/components/support_bar';
import WebtextPreview from '~/components/WebtextPreview';
import Routes from '~/routes';

import { SoomoLogo } from '~/components';

import PathContentStyles from './styles';

interface Props {
	fetchingConfig: boolean;
	showNav: boolean;
}

const PathContent: React.FC<Props> = (props) => {
	const { showNav, fetchingConfig } = props;
	return (
		<PathContentStyles className="path-content row no-gutters flex-grow-1">
			<div className="showcase-column col-sm-6 d-none d-sm-block">
				<WebtextPreview />
			</div>

			<div className="step-column col-xs-12 col-sm-6 flex-grow-1 d-flex flex-column justify-content-between">
				<div className="row nav-row align-items-center mx-0">
					<div className="col-6 d-block d-sm-none">
						<SoomoLogo width={166} height={34} />
					</div>
					<div className="col d-flex justify-content-end px-sm-0">{showNav && <NavButtons />}</div>
				</div>

				<div className="step-content row no-gutters flex-grow-1 d-flex">
					<div className="col">
						<ErrorBoundary>
							{
								// HACK: for IE11
								fetchingConfig ? (
									<div
										style={{
											display: 'flex',
											width: '100%',
											height: '100%',
											justifyContent: 'flex-center'
										}}>
										<Spinner />
									</div>
								) : (
									<Routes />
								)
							}
						</ErrorBoundary>
					</div>
				</div>

				<SupportBar />
			</div>
		</PathContentStyles>
	);
};

const mapStateToProps = (state) => ({
	fetchingConfig: state.app.fetchingConfig,
	showNav: selectShowNav(state)
});

const PathContentContainer = connect(mapStateToProps)(PathContent);

export default PathContentContainer;
