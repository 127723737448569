import React from 'react';

import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

class LMSSchoolStep extends React.Component<Props> {
	renderHelpMessage() {
		const { school } = this.props.courseSearchInfo;
		const { lms_instructions } = school ?? {};
		if (lms_instructions) {
			return <div dangerouslySetInnerHTML={{ __html: lms_instructions }} />;
		} else {
			return (
				<div>
					Your webtext is meant to be accessed from your school’s learning management system. Go
					sign in there and find the link to enter your webtext. If you need help, contact us by
					clicking the round button in the lower right corner.
				</div>
			);
		}
	}

	public render() {
		const { school } = this.props.courseSearchInfo;
		if (!school) {
			window.location.hash = 'welcome_front';
			return null;
		}
		const { lms_warning_header } = school;
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>{lms_warning_header || 'Please visit your school’s LMS to access your webtext.'}</h1>
					<hr />
					{this.renderHelpMessage()}
				</div>
			</PathStepStyles>
		);
	}
}

export default LMSSchoolStep;
