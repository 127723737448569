// unfortunately this is needed for the "redux-effex" dependency,
// even though our targets all support async functions natively
import 'regenerator-runtime/runtime';

import { FullStory, init as initFullStory } from '@fullstory/browser';
import { createHashHistory } from 'history';
import ReactDOM from 'react-dom';

import { authenticate, fetchPrintPurchaseConfig, fetchSignupConfig } from '~/api';
import App from '~/components/app';
import ErrorBoundary from '~/components/error_boundary';
import configureStore from '~/store/createStore';

// Initialize Fullstory as soon as possible.
if (import.meta.env.VITE_FULLSTORY_ORG_ID) {
	initFullStory({
		orgId: import.meta.env.VITE_FULLSTORY_ORG_ID
	});
}

document.addEventListener('DOMContentLoaded', () => {
	let jwt = new URLSearchParams(document.location.search).get('jwt');
	if (jwt) {
		window.sessionStorage.clear();
		window.history.replaceState({}, document.title, '/');
	} else {
		jwt = window.sessionStorage.getItem('jwt');
	}
	if (!jwt) {
		return ReactDOM.render(
			<ErrorBoundary initialError={new Error('Unable to find JWT for authentication.')} />,
			document.getElementById('root'),
			function () {
				const e = document.createEvent('Event');
				e.initEvent('SOOMO_RC_APP_LOADED', false, false);
				setTimeout(() => window.dispatchEvent(e), 500);
			}
		);
	}

	authenticate(jwt).then(({ pathType, courseId, intercom, fullstory }) => {
		if (import.meta.env.VITE_INTERCOM_APP_ID) {
			window.Intercom('boot', {
				app_id: import.meta.env.VITE_INTERCOM_APP_ID,
				...intercom.settings
			});
		}

		if (import.meta.env.VITE_FULLSTORY_ORG_ID && fullstory.identity) {
			FullStory('setIdentity', fullstory.identity);
		}

		const fetchConfig =
			pathType === 'print_purchase'
				? fetchPrintPurchaseConfig(String(courseId!))
				: fetchSignupConfig();

		fetchConfig.then(({ data: config }) => {
			config.finalized = window.sessionStorage.getItem('signupFinalized');

			const { store, persistor } = configureStore(config);
			const history = createHashHistory();

			// Triggered by a finalization success
			window.addEventListener('SOOMO_RC_PURGE_STORE', () => {
				setTimeout(() => {
					if (persistor) {
						persistor.pause();
						persistor.purge();
					}
				}, 1000); // <- hack. todo: Figure out how to purge when the store has finished writing and avoid repersists.
			});

			ReactDOM.render(
				<ErrorBoundary>
					<App store={store} config={config} />
				</ErrorBoundary>,
				document.getElementById('root')
			);
		});
	});
});
