import React from 'react';

import { TextInput } from '~/components/input';
import AppButton from '~/connected/app_button';
import FrontDoorInfoHeader from '~/connected/front_door_info_formatter';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

class RegisterEmailStep extends React.Component<Props> {
	public render() {
		const { user_state, userUpdate } = this.props;

		return (
			<PathStepStyles>
				<div className="page-content">
					<FrontDoorInfoHeader />
					<h1>What’s your name?</h1>
					<hr />
					<TextInput
						autoFocus
						label="First Name"
						placeholder="Jane"
						name="first_name"
						value={user_state.first_name}
						onChange={(e) =>
							userUpdate({
								first_name: e.target.value
							})
						}
					/>
					<TextInput
						label="Last Name"
						name="last_name"
						placeholder="Doe"
						value={user_state.last_name}
						onChange={(e) =>
							userUpdate({
								last_name: e.target.value
							})
						}
					/>
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}

export default RegisterEmailStep;
