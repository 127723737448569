import { Link } from 'react-router-dom';
import { SignupStep } from '~/components';
import ErrorBlurb from '~/components/error_blurb';

export default () => (
	<SignupStep hideButton>
		{() => (
			<>
				<h1>Error loading config.</h1>
				<ErrorBlurb />
				<p>
					Click <Link to="/welcome">here</Link> to start from the beginning.
				</p>
			</>
		)}
	</SignupStep>
);
