import BillingAddressStep from '~/components/path_steps/billing_address_step';
import CardConfirmStep from '~/components/path_steps/card_confirm_step';
import CardExpirationInputStep from '~/components/path_steps/card_expiration_input_step';
import CardholderInputStep from '~/components/path_steps/cardholder_input_step';
import CompletePurchase from '~/components/path_steps/complete_purchase';
import CreditCardInputStep from '~/components/path_steps/credit_card_input_step';
import DebugSelect from '~/components/path_steps/debug_select';
import CourseNotFoundStep from '~/components/path_steps/front_door/course_not_found_step';
import InstructorNotFoundStep from '~/components/path_steps/front_door/instructor_not_found_step';
import LMSSchoolStep from '~/components/path_steps/front_door/lms_school_step';
import RegisterEmailStep from '~/components/path_steps/front_door/register_email_step';
import RegisterNameStep from '~/components/path_steps/front_door/register_name_step';
import RegisterPasswordStep from '~/components/path_steps/front_door/register_password_step';
import SelectCourseStep from '~/components/path_steps/front_door/select_course_step';
import SelectInstructorStep from '~/components/path_steps/front_door/select_instructor_step';
import SelectSchoolStep from '~/components/path_steps/front_door/select_school_step';
import WelcomeBack from '~/components/path_steps/front_door/welcome_back_step';
import WelcomeFrontDoor from '~/components/path_steps/front_door/welcome_front_door_step';
import WelcomePrefilled from '~/components/path_steps/front_door/welcome_prefilled_step';
import FullAddressStep from '~/components/path_steps/full_address_step';
import PasskeyInputStep from '~/components/path_steps/passkey_input_step';
import PrintInfoStep from '~/components/path_steps/print_info_step';
import PrintPasskeyInputStep from '~/components/path_steps/print_passkey_input_step';
import PrintPaymentTypeStep from '~/components/path_steps/print_payment_type_step';
import PrintPurchase from '~/components/path_steps/print_purchase';
import PrintReviewAddressesStep from '~/components/path_steps/print_review_addresses_step';
import PrintShippingAddressStep from '~/components/path_steps/print_shipping_address_step';
import PrintSuccessStep from '~/components/path_steps/print_success_step';
import ReviewAddressesStep from '~/components/path_steps/review_addresses_step';
import SessionErrorStep from '~/components/path_steps/session_error_step';
import ShippingAddressStep from '~/components/path_steps/shipping_address_step';
import SubscriptionErrorStep from '~/components/path_steps/subscription_error_step';
import SuccessStep from '~/components/path_steps/success_step';
import TechCheckStep from '~/components/path_steps/tech_check_step';
import TermsOfServiceStep from '~/components/path_steps/terms_of_service_step';
import TrialInfoStep from '~/components/path_steps/trial_info_step';
import WebtextInfoStep from '~/components/path_steps/webtext_info_step';
import WebtextSubscribeStep from '~/components/path_steps/webtext_subscribe_step';
import WelcomeStep from '~/components/path_steps/welcome_step';

const pathSteps = {
	welcome: WelcomeStep,
	welcome_back: WelcomeBack,
	welcome_front: WelcomeFrontDoor,
	welcome_prefilled: WelcomePrefilled,
	select_school: SelectSchoolStep,
	select_instructor: SelectInstructorStep,
	select_course: SelectCourseStep,
	register_email: RegisterEmailStep,
	register_name: RegisterNameStep,
	register_password: RegisterPasswordStep,
	complete_purchase: CompletePurchase,
	print_purchase: PrintPurchase,
	print_payment_type: PrintPaymentTypeStep,
	print_passkey_input: PrintPasskeyInputStep,
	print_shipping_address: PrintShippingAddressStep,
	print_review_addresses: PrintReviewAddressesStep,
	print_success: PrintSuccessStep,
	webtext_info: WebtextInfoStep,
	tech_check: TechCheckStep,
	webtext_subscribe: WebtextSubscribeStep,
	credit_card_input: CreditCardInputStep,
	cardholder_input: CardholderInputStep,
	card_expiration_input: CardExpirationInputStep,
	passkey_input: PasskeyInputStep,
	trial_info: TrialInfoStep,
	terms_of_service: TermsOfServiceStep,
	terms_of_service_cc: TermsOfServiceStep,
	success: SuccessStep,
	card_confirm: CardConfirmStep,
	print_info: PrintInfoStep,
	shipping_address: ShippingAddressStep,
	subscription_error: SubscriptionErrorStep,
	session_error: SessionErrorStep,
	instructor_not_found: InstructorNotFoundStep,
	course_not_found: CourseNotFoundStep,
	lms_school: LMSSchoolStep,
	full_address: FullAddressStep,
	billing_address: BillingAddressStep,
	review_addresses: ReviewAddressesStep,
	// Debug steps
	debug_select: DebugSelect
};

export default pathSteps;
