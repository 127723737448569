import SignupStep from './SignupStep';

const TechCheckStep = () => (
	<SignupStep>
		{({ signupConfig }) => (
			<>
				<br />
				<h1>Tech Tip!</h1>
				<hr />
				<p>
					It looks like you’re using an older browser. You can continue the sign-up process no
					problem. But when you’re ready to use your webtext, you’ll have a better study experience
					if you use an up-to-date browser.
				</p>
				<p>
					<a target="_blank" href="https://www.google.com/chrome/browser">
						Chrome
					</a>
					,{' '}
					<a target="_blank" href="https://support.apple.com/downloads/safari">
						Safari
					</a>
					, and{' '}
					<a target="_blank" href="https://www.mozilla.org/en-US/firefox/">
						Firefox
					</a>{' '}
					all work great!
				</p>
			</>
		)}
	</SignupStep>
);

export default TechCheckStep;
