import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { RootState } from '~/store';

const FinalizationErrors = (props) => {
	return props.attempts >= 2 &&
		!props.submittingSubscription &&
		(props.tokenizationErrors?.length || props.finalizationErrors?.length) ? (
		<>
			<div className="error-message transaction-error">Transaction did not go through</div>
			<div>
				Enter a <Link to="/trial_info">free trial</Link> for now?
			</div>
		</>
	) : null;
};

export default connect((state: RootState) => ({
	submittingSubscription: state.app.submittingSubscription,
	tokenizationErrors: state.app.tokenizationErrors,
	finalizationErrors: state.app.finalizationErrors,
	attempts: state.app.submitCount
}))(FinalizationErrors);
