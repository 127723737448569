import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import ConfigErrorStep from '~/components/path_steps/front_door/config_error';
import withPathStepProps from '~/containers/with_path_step_props';
import { AppConfig, StepConfig } from '~/types';
import { RootState } from './store';

const subscriptionError = withPathStepProps({ name: 'subscription_error' });
const sessionError = withPathStepProps({ name: 'session_error' });
const lmsSchool = withPathStepProps({ name: 'lms_school' });
const instructorNotFound = withPathStepProps({ name: 'instructor_not_found' });
const courseNotFound = withPathStepProps({ name: 'course_not_found' });

interface Props {
	config: AppConfig;
	path: Array<StepConfig>;
	finalized: boolean;
	purchaseCompleted: boolean;
	printPurchaseCompleted: boolean;
}

const Routes: React.FC<Props> = (props) => {
	const { config, path, finalized, purchaseCompleted, printPurchaseCompleted } = props;

	const wrappedSteps = useMemo(() => {
		const wrappedSteps: Record<string, any> = {};
		path.forEach((p) => {
			wrappedSteps[p.name] = withPathStepProps(p);
		});
		return wrappedSteps;
	}, [path]);

	return (
		<Switch>
			{path.map((p, i) => {
				if (wrappedSteps[p.name] !== undefined) {
					if (p.name === 'success') {
						// Don't show success page if they haven't finalized in some way
						if (finalized || purchaseCompleted) {
							return <Route key={i} exact path={`/${p.name}`} component={wrappedSteps[p.name]} />;
						}
					} else if (p.name === 'print_success') {
						if (printPurchaseCompleted) {
							return <Route key={i} exact path={`/${p.name}`} component={wrappedSteps[p.name]} />;
						}
					} else if (
						// todo: This is awful.
						// extract this into a "isStepDisabled" function
						(config.path_type === 'front_door' && !finalized) ||
						(config.path_type === 'front_door_returning' && !finalized) ||
						(config.path_type === 'prefilled' && !finalized) ||
						(config.path_type === 'LMS' && (!finalized || config.path_config.abbreviated_path)) ||
						(config.path_type === 'complete_purchase' && !purchaseCompleted) ||
						(config.path_type === 'print_purchase' && !printPurchaseCompleted)
					) {
						// Don't reveal path routes if either path has finalized
						return <Route key={i} exact path={`/${p.name}`} component={wrappedSteps[p.name]} />;
					}
				}
			})}
			<Route exact path="/instructor_not_found" component={instructorNotFound} />
			<Route exact path="/course_not_found" component={courseNotFound} />
			<Route exact path="/lms_school" component={lmsSchool} />
			<Route exact path="/subscription_error" component={subscriptionError} />
			<Route exact path="/config_error" component={ConfigErrorStep} />
			<Route exact path="/session_error" component={sessionError} />
			{renderRedirects(config, finalized, purchaseCompleted, printPurchaseCompleted)}
		</Switch>
	);
};

const renderRedirects = (
	config: AppConfig,
	finalized,
	purchaseCompleted,
	printPurchaseCompleted
) => {
	switch (config.path_type) {
		case 'LMS':
			if (finalized) {
				if (!config.path_config.abbreviated_path) {
					return <Redirect to="success" />;
				}
			} else {
				return <Redirect to="welcome" />;
			}
		case 'front_door':
			if (finalized) {
				return <Redirect to="success" />;
			} else {
				return <Redirect to="welcome_front" />;
			}
		case 'front_door_returning':
			if (finalized) {
				return <Redirect to="success" />;
			} else {
				return <Redirect to="welcome_back" />;
			}
		case 'prefilled':
			if (finalized) {
				return <Redirect to="success" />;
			} else {
				return <Redirect to="welcome_prefilled" />;
			}
		case 'complete_purchase':
			if (purchaseCompleted) {
				return <Redirect to="success" />;
			} else {
				return <Redirect to="complete_purchase" />;
			}
		case 'print_purchase':
			if (printPurchaseCompleted) {
				return <Redirect to="print_success" />;
			} else {
				return <Redirect to="print_purchase" />;
			}
	}
};

export default connect((state: RootState) => ({
	config: state.app.config,
	path: state.app.path,
	finalized: state.app.finalized,
	purchaseCompleted: state.app.purchaseCompleted,
	printPurchaseCompleted: state.app.printPurchaseCompleted
}))(Routes);
