import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import validate from 'validate.js';

import { Link } from 'react-router-dom';
import Address from '~/components/Address';
import SignupStep from '~/components/path_steps/SignupStep';
import { sm } from '~/styles/mixins/breakpoints';
import { addressDetailsValidation } from '~/utils/validations';

const validation = (userState) => !validate(userState.physicalAddress, addressDetailsValidation);

const ReviewAddressesStep = () => (
	<SignupStep validation={validation} buttonText={() => <>Confirm</>}>
		{({ signupConfig, physicalAddress, billingAddress, isBillingAddressSameAsPhysical }) => (
			<>
				<h1>Review addresses</h1>
				<hr />
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span style={{ fontWeight: 'bold' }}>
						{signupConfig.path_type === 'print_purchase' ? 'Shipping' : 'Current'} Address
					</span>
					<Link to="/full_address">
						<MdEdit />
						Edit
					</Link>
				</div>
				<Address address={physicalAddress} />

				<Ruling />

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span style={{ fontWeight: 'bold' }}>Billing Address</span>
					<Link to={isBillingAddressSameAsPhysical ? '/full_address' : '/billing_address'}>
						<MdEdit />
						Edit
					</Link>
				</div>
				<Address address={isBillingAddressSameAsPhysical ? physicalAddress : billingAddress} />
			</>
		)}
	</SignupStep>
);

const Ruling = styled.div`
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 335px;
	height: 1px;
	background-color: #bdd63a;
	@media (max-width: ${sm}px) {
		width: 100%;
	}
`;

export default ReviewAddressesStep;
