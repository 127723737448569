import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import PathStepStyles from '~/styles/path_step_styles';

import { default as Button } from '~/connected/app_button';
import { RootState } from '~/store';
import { appContinue, appSetCanContinue, appStepMounted } from '~/store/app/actions';
import { selectCurrentStep } from '~/store/selectors';
import { setFormValue } from '~/store/user/actions';
import { UserState } from '~/store/user/reducer';
import { AppConfig, AppConfigWithCoursePresent, StepConfig } from '~/types';

type BaseSignupStepProps = {
	setFormValue: typeof setFormValue;
	appContinue: typeof appContinue;
	stepConfig: StepConfig;
	formErrors: any;
	submitCount: number;
	submittedWhileDisabled: boolean;
	// Server side validation errors
	serverSideValidationResults: string[];
} & UserState;

interface SignupStepPropsWithCoursePresentInAppConfig extends BaseSignupStepProps {
	signupConfig: AppConfigWithCoursePresent;
}

interface SignupStepProps extends BaseSignupStepProps {
	signupConfig: AppConfig;
}

type SignupStepContainerProps = {
	formState: UserState;
	children: SignupStepRenderProp;
	buttonText?: SignupStepRenderProp;
	appStepMounted: typeof appStepMounted;
	setFormValue: typeof setFormValue;
	appContinue: typeof appContinue;
	appSetCanContinue: typeof appSetCanContinue;
	serverSideValidationResults: string[];
	signupConfig: AppConfig;
	stepConfig: StepConfig;
	canContinue: boolean;
	submittedWhileDisabled: boolean;
	submitCount: number;
	validation?: (userState: UserState, setFormErrors?: (any) => void) => boolean;
	hideButton?: boolean;
} & UserState &
	RouteComponentProps;

type SignupStepRenderProp = (props: SignupStepProps) => any;

export type { SignupStepProps, SignupStepPropsWithCoursePresentInAppConfig };

const SignupStep: React.FC<SignupStepContainerProps> = (props) => {
	const stepName = props.location.pathname.split('/').pop();

	const { hideButton } = props;
	const [formErrors, setFormErrors] = useState(null);

	// An effect with no dependencies [] will only one run on the first render
	useEffect(() => {
		props.appStepMounted(stepName);
		if (!props.validation) {
			props.appSetCanContinue(true);
		}
	}, []);

	// Runs on every formState change
	const { formState } = props;
	useEffect(() => {
		if (props.validation) {
			const valid = props.validation(props.formState, setFormErrors);
			props.appSetCanContinue(valid);
		}
	}, [formState]);

	const { children, appStepMounted, ...remainingProps } = props;

	const signupStepProps = {
		...remainingProps,
		setFormErrors,
		formErrors,
		submitCount: props.submitCount
	};

	let buttonText = props.buttonText?.(signupStepProps) || 'Ok';
	return (
		<PathStepStyles id={'page-' + stepName}>
			<div className="page-content">{props.children(signupStepProps)}</div>
			{!hideButton && <Button>{buttonText}</Button>}
		</PathStepStyles>
	);
};

const ConnectedSignupStep = connect(
	(state: RootState) => {
		const { app, user } = state;
		return {
			formState: user,
			...user,
			submitCount: state.app.submitCount,
			submittedWhileDisabled: state.app.continueClickedWhileDisabled,
			signupConfig: state.app.config,
			stepConfig: selectCurrentStep(state),
			canContinue: state.app.canContinue,
			serverSideValidationResults: app.validationErrors
		};
	},
	{ setFormValue, appContinue, appStepMounted, appSetCanContinue }
)(SignupStep);

export default withRouter(ConnectedSignupStep);
