import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/input/Button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepPropsWithCoursePresentInAppConfig } from '~/types';

const round2 = (v) => Math.round(v * 100) / 100;
const updatePaymentMethod = (paymentMethod) => ({
	subscriptionPayload: { payment_details: { payment_method: paymentMethod } }
});

class CompletePurchase extends React.Component<StepPropsWithCoursePresentInAppConfig, {}> {
	componentDidMount() {
		this.props.userUpdatePaymentDetails({
			payment_method: 'trial'
		});
	}

	public render() {
		const { appConfig } = this.props;
		const { subscription } = appConfig.course;
		const startString = DateTime.fromISO(subscription.trial_start).toFormat('MMMM dd');
		const endString = DateTime.fromISO(subscription.trial_end).toFormat('MMMM dd');
		const useCard = appConfig.course.subscription.payment_methods.creditcard;
		const { retaking } = appConfig.course;
		const usePasskey = appConfig.course.subscription.payment_methods.passkey;
		const owed = round2(subscription.amount_owed);
		const paid = round2(subscription.amount_paid);
		const cost = round2(subscription.cost);

		let subscriptionOptions: React.ReactNode = null;
		let subscriptionPrompt: React.ReactNode = null;
		let showContinueButton = false;
		// We don't handle the owed == 0 step because the user shouldn't have
		// entered into the complete purchase step if they owed nothing
		if (retaking && owed > 0) {
			showContinueButton = true;
			subscriptionPrompt = (
				<div>
					<p>
						The last webtext you used for this course was ${paid}. The course is now using a webtext
						with additional content and is priced at ${cost}.
					</p>
					<p>We’ve given you a credit for your last purchase, so all you need to pay is ${owed}.</p>
					<p>Click continue to pay with a credit card.</p>
				</div>
			);
		} else {
			subscriptionOptions = (
				<div className="subscription-options">
					{usePasskey && (
						<Link
							to="passkey_input"
							id="passkey"
							className="no-link-styles subscribe-select"
							onClick={() => this.props.setFormValue(updatePaymentMethod('passkey'))}>
							<Button className="subscription-option">Passkey</Button>
						</Link>
					)}
					{useCard && (
						<Link
							to="full_address"
							id="credit-card"
							className="no-link-styles"
							onClick={() => this.props.setFormValue(updatePaymentMethod('creditcard'))}>
							<Button className="subscription-option">Credit Card</Button>
						</Link>
					)}
				</div>
			);

			subscriptionPrompt = (
				<p>
					To access your webtext,{' '}
					{usePasskey && 'use a passkey purchased from your school’s bookstore'}
					{useCard && usePasskey && ' or '}
					{useCard &&
						`subscribe now for $${
							subscription.amount_owed || subscription.cost
						} using a credit card`}
					.
				</p>
			);
		}

		const subscriptionStatus =
			subscription.status === 'trial_expired' ? (
				<p>Your trial access began on {startString} and has now expired.</p>
			) : subscription.status === 'trial' ? (
				<p>
					Your trial access began on {startString} and will expire on {endString}.
				</p>
			) : null;

		return (
			<CompletePurchaseStyles>
				<div className="page-content">
					<h1>Activate your account</h1>
					<hr />
					{subscriptionStatus}
					{retaking && owed > 0 && (
						<h2
							style={{
								lineHeight: 1.05,
								fontSize: 20,
								marginBottom: '16px',
								marginTop: '16px'
							}}>
							You have a ${paid} credit toward this course.
						</h2>
					)}
					{subscriptionPrompt}
					{subscriptionOptions}
				</div>
				{showContinueButton && (
					<Link
						to="full_address"
						className="no-link-styles continue-link"
						onClick={() => this.props.setFormValue(updatePaymentMethod('creditcard'))}>
						<Button>Continue</Button>
					</Link>
				)}
			</CompletePurchaseStyles>
		);
	}
}

const CompletePurchaseStyles = styled(PathStepStyles)`
	.subscription-options {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	a {
		width: 100%;
	}

	.continue-link {
		display: flex;
		flex-direction: column;
	}

	.subscription-option {
		width: 100%;
		:first-of-type {
			margin-bottom: 16px;
		}

		button {
			width: 100%;
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		.subscription-options {
			display: flex;
			justify-content: space-between;
			a {
				width: initial;
			}
		}

		.subscription-option {
			width: initial;
			:first-of-type {
				margin-bottom: 0;
			}
			button {
				width: 182px;
			}
		}
	}
`;

export default CompletePurchase;
