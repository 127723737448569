import { lighten } from 'polished';

const breakpoints = {
	small: '576px',
	medium: '768px',
	large: '992px',
	xlarge: '1200px'
};

export interface Theme {
	color: {
		brand: string;
		error: string;
	};
	breakpoints: {
		small: string;
		medium: string;
		large: string;
		xlarge: string;
	};
	textInput: {
		fontSize: string;
		labelSize: string;
	};
	progressBar: {
		trackColor: string;
		backgroundColor: string;
	};
	navButton: {
		size: string;
	};
	button: {
		fontSize: string;
		backgroundColor: string;
		hoverBackgroundColor: string;
		disabledBackgroundColor: string;
	};
	selectInput: {
		border: string;
	};
	pathStep: {
		mobilePadding: string;
	};
}

const theme: Theme = {
	breakpoints,
	color: {
		brand: '#bdd63a',
		error: '#ba1d51'
	},
	progressBar: {
		trackColor: '#7E7E7E',
		backgroundColor: '#BED63E'
	},
	navButton: {
		size: '29px'
	},
	textInput: {
		fontSize: '28px',
		labelSize: '12px'
	},
	selectInput: {
		border: '#BDD63A'
	},
	button: {
		fontSize: '12px',
		backgroundColor: '#BDD63A',
		hoverBackgroundColor: lighten(0.15, '#a1ba1d'),
		disabledBackgroundColor: '#b0be91'
	},
	pathStep: {
		mobilePadding: '24px'
	}
};

export default theme;
