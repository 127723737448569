import { Component } from 'react';

import TOSViewerStyles from '~/styles/tos_viewer_styles';

interface Props {
	children: any;
}

class TOSViewer extends Component<Props> {
	public render() {
		return <TOSViewerStyles>{this.props.children}</TOSViewerStyles>;
	}
}

export default TOSViewer;
