import validate from 'validate.js';

import { toggleContinue } from '~/utils/helpers';
import { emailValidation, passwordValidation } from '~/utils/validations';
import { RootState } from '.';
import { appSetCanContinue } from './app/actions';
import { selectCurrentStep } from './selectors';

export function validateUserForStep(dispatch, state: RootState) {
	const can_continue = state.app.canContinue;
	const user = state.user;
	const currentStep = selectCurrentStep(state);
	let valid = currentStep ? currentStep.canContinue : false;
	switch (state.app.currentStep) {
		case 'select_school':
			valid = user.course_search_info.school !== null;
			break;
		case 'select_instructor':
			valid = user.course_search_info.instructor !== null;
			break;
		case 'select_course':
			valid = user.course_search_info.course !== null;
			break;
		case 'register_name':
			const { first_name, last_name } = user;
			valid = Boolean(first_name && last_name);
			break;
		case 'register_email':
			const { email, email_confirm } = user;
			valid = !validate({ email, email_confirm }, emailValidation) && email === email_confirm;
			break;
		case 'register_password':
			const { password, password_confirm } = user;
			valid =
				!validate({ password, password_confirm }, passwordValidation) &&
				password === password_confirm;
			break;
	}
	toggleContinue(can_continue, valid, (canContinue) => dispatch(appSetCanContinue(canContinue)));
}
