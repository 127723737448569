import { Component, HTMLProps } from 'react';

import caret from '~/components/icons/caret';
import NativeSelectBasicStyles from '~/styles/native_select_basic_styles';
import { SelectOption } from '~/types';

import NativeSelectStyles from './native_select_styles';

type Props = {
	options: Array<SelectOption>;
	value?: any;
	placeholder?: any;
	onChange?: (e) => void;
	disableCustomStyling?: boolean;
} & HTMLProps<HTMLSelectElement>;

class NativeSelect extends Component<Props> {
	static defaultProps = {
		value: '',
		placeholder: 'Select A Value',
		disableCustomStyling: false,
		onChange: () => true
	};

	handleChange = (e) => this.props.onChange?.(e.target.value);

	getSelectedValue = () => {
		const option = this.props.options.find((v) => {
			return v.value == this.props.value;
		});
		return option ? option.label : 'No value found';
	};

	render() {
		return this.props.disableCustomStyling ? this.renderBasic() : this.renderWithCustomStyling();
	}

	renderWithCustomStyling = () => {
		const { options, tabIndex, value, placeholder, onSelect } = this.props;
		return (
			<NativeSelectStyles className="native-select">
				<div className="select-container">
					<select
						tabIndex={tabIndex}
						placeholder={placeholder}
						value={value || ''}
						onChange={this.handleChange}>
						<option value="" disabled hidden>
							{placeholder}
						</option>
						{options.map((o, i) => (
							<option key={i} value={o.value}>
								{o.label}
							</option>
						))}
					</select>
					{!value || value === '' ? (
						<span className="placeholder">{placeholder}</span>
					) : (
						<span className="value">{this.getSelectedValue()}</span>
					)}
					{caret}
				</div>
			</NativeSelectStyles>
		);
	};

	renderBasic = () => {
		const { options, tabIndex, value, placeholder, onSelect } = this.props;

		return (
			<NativeSelectBasicStyles className="native-select">
				<div className="select-container">
					<select
						tabIndex={tabIndex}
						placeholder={placeholder}
						value={value || ''}
						onChange={this.handleChange}>
						<option value="" disabled hidden>
							{placeholder}
						</option>
						{options.map((o, i) => (
							<option key={i} value={o.value}>
								{o.label}
							</option>
						))}
					</select>
				</div>
			</NativeSelectBasicStyles>
		);
	};
}

export default NativeSelect;
