import { createHashHistory } from 'history';
import { getType } from 'typesafe-actions';
import { logError } from '~/rollbar';
import { addressDetailsValidation, cardValidation } from './../../utils/validations';
import { userFetchEstimatedTax, userSetTermsAgreed } from './../user/actions';

import {
	userSetTokenizedCC,
	userSubscriptionFinalization,
	userSubscriptionValidation,
	userTokenizeCardInfo,
	userUpdate,
	userUpdateAddress,
	userUpdateCourseSearchInfo
} from '~/store/user/actions';

import {
	selectCardInfo,
	selectCourseIsFree,
	selectCurrentStep,
	selectHasInstitutionalPay,
	selectPaymentMethod,
	selectSubscriptionPayload
} from '~/store/selectors';

import {
	fetchEstimatedTax,
	finalizeSubscription,
	tokenizeCardInfo,
	validateSubscription
} from '~/api';

import {
	appContinue,
	appError,
	appFetchConfig,
	appFetchConfigBoot,
	appSetFinalizationErrors,
	appSetFinalized,
	appSetLoadingState,
	appSetPrintPurchaseComplete,
	appSetPurchaseComplete,
	appSetSubmittingSubscription,
	appSetTokenizationErrors,
	appSetValidationErrors,
	appStart,
	appStepMounted
} from '~/store/app/actions';
import { buildFinalizationErrorMessages, buildValidationErrorMessages } from '~/utils/helpers';

import { EstimatedSalesTaxResponseSuccess } from '~/types';

import isEqual from 'lodash/isEqual';
import validate from 'validate.js';
import { isCoursePresentInConfig } from '~/types/signup_config';
import { RootState } from '..';
import { validateUserForStep } from '../formValidations';
import { goToNextStep } from './goToNextStep';

const history = createHashHistory();

function revealApp() {
	const e = document.createEvent('Event');
	e.initEvent('SOOMO_RC_APP_LOADED', false, false);
	setTimeout(() => window.dispatchEvent(e), 500);
}

function purgeStore() {
	const e = document.createEvent('Event');
	e.initEvent('SOOMO_RC_PURGE_STORE', false, false);
	window.dispatchEvent(e);
}

let firstPage = true;
async function onAppStart({ action, getState, dispatch, nextDispatchAsync }) {
	try {
		const state = getState();
		const { config } = state.app;

		if (!config) {
			dispatch(appError('No config found'));
			revealApp();
			return;
		}

		const { course_search_info } = state.user;
		if (
			(config.path_type === 'front_door' || config.path_type === 'front_door_returning') &&
			course_search_info.course &&
			course_search_info.instructor.value !== -1 &&
			course_search_info.course.value !== -1
		) {
			dispatch(appFetchConfigBoot.request(course_search_info.course.value));
		}

		dispatch(appSetLoadingState('loaded'));
	} catch (e) {
		// Handle generic boot errors
		logError(e);
		dispatch(appError(e.message));
		dispatch(appSetLoadingState('errored'));
	}
	revealApp(); // reveal app either way so the error screen can be shown to user
}

/**
 * Trigger validation/finalization or proceed to next step
 */
async function onAppContinueFlow({ dispatch, getState }) {
	let state = getState();
	let currentStep = selectCurrentStep(state);

	if (!state.app.canContinue) {
		console.warn(
			'appContinue was called, however, the progression was blocked as store.app.canContinue is set to false.'
		);
		return;
	}

	// The user has clicked out of the terms_of_service or terms_of_service_cc step
	if (
		currentStep?.name.indexOf('terms_of_service') != -1 &&
		!selectSubscriptionPayload(state).terms_of_service_accepted
	) {
		dispatch(userSetTermsAgreed(true));
	}

	if (currentStep?.name == 'physical_address') {
	}

	let finalize = false;
	if (typeof currentStep?.finalize === 'boolean') {
		finalize = currentStep?.finalize;
	} else if (currentStep?.finalize) {
		const { payment_method } = selectSubscriptionPayload(state).payment_details || {};
		if (payment_method != null && currentStep?.finalize[payment_method] != null) {
			finalize = currentStep?.finalize[payment_method];
		}
	}

	// Determine if step triggers config load, server-side validation or finalization
	if (currentStep?.rehydrate_config) {
		dispatch(appFetchConfig.request(state.user.course_search_info.course.value));
	} else if (currentStep?.validate) {
		submitSubscriptionValidation({ dispatch, getState });
	} else if (finalize && !state.app.finalized) {
		dispatch(appSetSubmittingSubscription(true));
		if (currentStep?.name === 'card_confirm') {
			submitCardTokenization({ dispatch, getState });
		} else {
			submitSubscriptionFinalization({ dispatch, getState });
		}
	} else {
		goToNextStep(state, history);
	}
}

/**
 * Submits current subscription payload for validation on the server
 */
async function submitSubscriptionValidation({ dispatch, getState }) {
	const state = getState();
	const currentStep = selectCurrentStep(state);
	const subscription = selectSubscriptionPayload(state);
	try {
		dispatch(userSubscriptionValidation.request());
		if (currentStep?.name === 'passkey_input' || currentStep?.name === 'print_passkey_input') {
			delete subscription.shipping_details;
		}

		const resp = await validateSubscription(subscription, state.app.config.path_type);
		if (!resp || !resp.data) {
			throw new Error('No response found for validation request');
		}
		dispatch(userSubscriptionValidation.success(resp.data));
	} catch (e) {
		logError(e);
		dispatch(appSetValidationErrors(['Error validating info']));
		dispatch(userSubscriptionValidation.failure(e));
	}
}

/**
 * Builds error messages based on validation results and reroutes user if no errors are present
 */
async function onValidationComplete({ action, dispatch, getState, nextDispatchAsync }) {
	const state = getState();
	let currentStep = selectCurrentStep(state);
	const { errors } = state.user.subscriptionValidation;
	let stepErrors;
	// Determine if current page should block progression based on validation results
	if (errors) {
		stepErrors = buildValidationErrorMessages(currentStep!.name, errors);
	}

	if (stepErrors && stepErrors.length > 0) {
		dispatch(appSetValidationErrors(stepErrors));
	} else {
		dispatch(appSetValidationErrors(stepErrors));
		goToNextStep(state, history);
	}
}

/**
 * Submits current subscription payload for finalization on the server
 */
async function submitSubscriptionFinalization({ dispatch, getState }) {
	const state = getState();
	const currentStep = selectCurrentStep(state);
	const subscription = selectSubscriptionPayload(state);

	try {
		dispatch(userSubscriptionFinalization.request());
		const resp = await finalizeSubscription(subscription, state.app.config.path_type);
		dispatch(userSubscriptionFinalization.success(resp.data));
		let errors = buildFinalizationErrorMessages(resp.data);
		dispatch(appSetFinalizationErrors(errors));
		if (!errors.length) {
			window.sessionStorage.setItem('signupFinalized', 'true');
			dispatch(appSetFinalized(true));
			if (state.app.config.path_type === 'complete_purchase') {
				dispatch(appSetPurchaseComplete(true));
			}
			if (state.app.config.path_type === 'print_purchase') {
				dispatch(appSetPrintPurchaseComplete(true));
			}
			//Card confirm manages its own error view
		} else if (currentStep?.name !== 'card_confirm') {
			console.error(resp);
			console.error(errors);
			history.push('/subscription_error');
		}
	} catch (e) {
		logError(e);
		dispatch(appSetSubmittingSubscription(false));
		dispatch(userSubscriptionFinalization.failure(e));
		dispatch(appSetFinalizationErrors([`${e}`]));
		if (currentStep?.name !== 'card_confirm') {
			history.push('/subscription_error');
		}
	}
}

/**
 * Submits current card info for tokenization via accept.js
 */
async function submitCardTokenization({ dispatch, getState }) {
	const state = getState();
	const cardInfo = selectCardInfo(state);
	dispatch(userTokenizeCardInfo.request());
	setTimeout(async () => {
		try {
			let result = await tokenizeCardInfo(cardInfo);
			dispatch(userTokenizeCardInfo.success(result));
		} catch (e) {
			logError(e);
			dispatch(appSetSubmittingSubscription(false));
			dispatch(userTokenizeCardInfo.failure(e));
		}
	}, 200);
}

/**
 * Completes the user purchase flow after receiving their tokenized credit card from accept.js
 */
async function onTokenizationComplete({ action, dispatch, getState, nextDispatchAsync }) {
	const state = getState();
	const acceptResult = state.user.tokenizationResult;
	if (!acceptResult) {
		dispatch(appSetTokenizationErrors(['No result found from tokenization']));
	}
	const data = acceptResult.opaqueData;
	if (!data) {
		if (acceptResult.messages) {
			const errors = acceptResult.messages.message.map((m) => m.text);
			dispatch(appSetTokenizationErrors(errors));
		} else {
			dispatch(appSetTokenizationErrors(['No result found from tokenization']));
		}
		dispatch(appSetSubmittingSubscription(false));
	} else {
		dispatch(appSetTokenizationErrors([]));
		dispatch(userSetTokenizedCC(data.dataValue));
	}
}

// todo: Merge routes, this effect, and selectNextStep logic into a centralized switch of behavior
async function onStepMounted({ dispatch, getState }) {
	const state: RootState = getState();
	validateUserForStep(dispatch, state);
	const step = state.app.currentStep;
	if (step === 'success') {
		purgeStore();
	}
	if (
		selectPaymentMethod(state) == 'creditcard' &&
		state.user.physicalAddress &&
		!validate(state.user.physicalAddress, addressDetailsValidation) &&
		((!state.user.estimatedTax && !state.user.estimatedTaxValidationErrors) ||
			!isEqual(state.user.estimateAddress, state.user.physicalAddress))
	) {
		dispatch(userFetchEstimatedTax.request());
	}

	const { course_search_info } = state.user;
	if (
		state.app.config.path_type === 'front_door' ||
		state.app.config.path_type === 'front_door_returning'
	) {
		if (step === 'select_instructor' && !course_search_info.school) {
			history.push(`/select_school`);
		}

		if (step === 'select_course' && !course_search_info.instructor) {
			history.push(`/select_instructor`);
		}

		if (step === 'register_name' && !course_search_info.course) {
			history.push(`/select_school`);
		}

		if (step === 'register_email' && !course_search_info.course) {
			history.push(`/select_school`);
		}

		if (step === 'register_password' && !course_search_info.course) {
			history.push(`/select_school`);
		}

		if (step === 'webtext_subscribe') {
			if (!state.user.course_search_info.course) {
				history.push(`/select_school`);
			}

			if (!isCoursePresentInConfig(state.app.config)) {
				history.push(`/select_school`);
			}

			if (selectHasInstitutionalPay(state) || selectCourseIsFree(state)) {
				history.push(`/terms_of_service`);
			}
		}
	}

	if (
		step === 'terms_of_service' &&
		state.app.config.path_config.abbreviated_path &&
		state.app.finalized &&
		firstPage
	) {
		history.push(`/welcome`);
	}

	if (step === 'subscription_error') {
		const errors = state.app.finalizationErrors;
		if (!errors || errors.length === 0) {
			history.push(`/webtext_subscribe`);
		}
	}

	if (step === 'card_confirm') {
		const cardErrors = validate(state.user, cardValidation);
		const addressErrors = validate(state.user.physicalAddress, addressDetailsValidation);
		if (cardErrors || addressErrors) {
			history.push(`/webtext_subscribe`);
		}
	}
}

export default [
	{ action: getType(appStart), effect: onAppStart },
	{
		action: getType(appContinue),
		effect: onAppContinueFlow
	},
	{
		action: getType(userFetchEstimatedTax.request),
		effect: ({ dispatch, getState }) => {
			const state = getState();
			fetchEstimatedTax(selectSubscriptionPayload(state), state.app.config.path_type)
				.then((res) => {
					dispatch(userFetchEstimatedTax.success(res.data as EstimatedSalesTaxResponseSuccess));
				})
				.catch((err) => {
					dispatch(userFetchEstimatedTax.failure(err));
				});
		}
	},
	{
		action: getType(userSubscriptionValidation.success),
		effect: onValidationComplete
	},
	{
		action: getType(userTokenizeCardInfo.success),
		effect: onTokenizationComplete
	},
	{
		action: getType(appFetchConfig.success),
		effect: ({ dispatch, getState }) => {
			const state = getState();
			goToNextStep(getState(), history);
		}
	},
	{
		action: getType(userSetTokenizedCC),
		effect: submitSubscriptionFinalization
	},
	{
		// Redirect to success if the user has finalized
		action: getType(appSetFinalized),
		effect: ({ getState }) => {
			const config = getState().app.config;
			if (config.path_config.abbreviated_path) {
				let course_id: any = config.course.id;
				window.location.href = course_id ? `/courses/${course_id}` : '/courses';
			} else {
				history.push(`/success`);
			}
		}
	},
	{
		action: getType(appFetchConfigBoot.failure),
		effect: () => history.push('/config_error')
	},
	{
		action: getType(appFetchConfig.failure),
		effect: () => history.push('/config_error')
	},
	{
		// Purge store on success step visit
		action: getType(appStepMounted),
		effect: onStepMounted
	},
	{
		action: getType(userUpdateCourseSearchInfo),
		effect: ({ dispatch, getState }) => validateUserForStep(dispatch, getState())
	},
	{
		action: getType(userUpdateAddress),
		effect: ({ dispatch, getState }) => validateUserForStep(dispatch, getState())
	},
	{
		action: getType(userUpdate),
		effect: ({ dispatch, getState }) => validateUserForStep(dispatch, getState())
	}
];
