import { configureStore } from '@reduxjs/toolkit';
import { effectsMiddleware } from 'redux-effex';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import storage from 'redux-persist/lib/storage/session';
import rootReducer, { effects } from '~/store';
import rootSaga from '~/store/sagas';
import { initialState, UserState } from '~/store/user/reducer';
import { AppConfig } from '~/types';

const errorBoundedRootReducer = function (priorState, action) {
	try {
		return rootReducer(priorState, action);
	} catch (e) {
		console.error(e);
		return priorState;
	}
};

const configurePersistence = function (config) {
	// What gets written to local storage
	const inboundUserState = (state: UserState, key) => {
		if (key === 'user') {
			let expiration = new Date();
			expiration.setHours(expiration.getHours() + 1);
			state.persistExpiresAt = expiration.getTime();
			return {
				...state,
				CVV: null,
				subscriptionFinalization: null,
				subscriptionValidation: null,
				tokenizationResult: null,
				expiration,
				asyncState: {
					finalizingSubscription: false,
					tokenizingCard: false,
					validatingData: false
				}
			};
		}
		return state;
	};

	// What gets read from local storage
	const outboundUserState = (state: UserState, key) => {
		if (key === 'user') {
			const current_email = config.user.email;
			const stored_email = state.email;
			// Clear on a different email
			if (config.path_type !== 'front_door' && current_email !== stored_email) {
				return initialState;
			}
			// Clear after an hour
			if (state.persistExpiresAt && state.persistExpiresAt < new Date().getTime()) {
				return initialState;
			}

			// Clear on a different path
			if (state.pathType !== config.path_type) {
				return initialState;
			}

			return {
				...state,
				estimatedTax: null,
				CVV: null,
				subscriptionFinalization: null,
				subscriptionValidation: null,
				tokenizationResult: null,
				asyncState: {
					finalizingSubscription: false,
					tokenizingCard: false,
					validatingData: false
				}
			};
		}
		return state;
	};

	const userTransform = createTransform(inboundUserState, outboundUserState, {
		whitelist: ['user']
	});

	const persistConfig = {
		key: 'root',
		storage,
		transforms: [userTransform],
		blacklist: ['app', 'errors']
	};

	return persistConfig;
};

/**
 * Builds the store for the app.
 *
 * @param appConfig: Data on this config such as user.email is used to indicate hydration logic
 *  - If the user's email is different than what is in the store, don't rehydrate
 *  - If the store is more than an hour old, don't rehydrate
 */
function configureSignupStore(config: AppConfig) {
	const sagaMiddleware = createSagaMiddleware();
	const middleware = [effectsMiddleware(effects), sagaMiddleware];

	let reducer = errorBoundedRootReducer;
	if (config.persist) {
		const persistConfig = configurePersistence(config);
		reducer = persistReducer(persistConfig, errorBoundedRootReducer);
	}

	let store = configureStore({
		reducer,
		middleware
	});

	sagaMiddleware.run(rootSaga);

	const persistor = config.persist ? persistStore(store) : null;

	return { store, persistor };
}

export default configureSignupStore;
