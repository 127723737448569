import merge from 'lodash/merge';
import { ActionType, getType } from 'typesafe-actions';

import { appActions } from '~/store/app';
import { AppAction } from '~/store/app/reducer';
import { userActions } from '~/store/user';
import {
	AddressDetails,
	CourseSearchInfo,
	FinalizationResponse,
	SubscriptionPayload,
	TokenizationResult,
	ValidationResponse
} from '~/types';
import { isCoursePresentInConfig, isSchoolPresentInConfig } from '~/types/signup_config';

export type UserAction = ActionType<typeof userActions>;

export interface UserState {
	persistExpiresAt?: number;
	asyncState: {
		validatingData: boolean;
		finalizingSubscription: boolean;
		tokenizingCard: boolean;
		estimatingTax?: boolean;
	};
	pathType?:
		| 'LMS'
		| 'front_door'
		| 'front_door_returning'
		| 'prefilled'
		| 'complete_purchase'
		| 'print_purchase';
	tokenizationResult: TokenizationResult;
	subscriptionPayload: SubscriptionPayload;
	subscriptionValidation: ValidationResponse;
	subscriptionFinalization: FinalizationResponse;
	email?: string;
	email_confirm?: string;
	password?: string;
	password_confirm?: string;
	first_name?: string;
	last_name?: string;
	course_search_info: CourseSearchInfo;

	// Start of new refactor towards flatter form data structure here
	// todo: remove the optional types here later
	subscriptionMethod?: string;
	passkeyPlaintext?: string;
	tokenizedCC?: string;
	billingAddress?: AddressDetails;
	physicalAddress?: AddressDetails;
	shippingAddress?: AddressDetails;
	estimateAddress?: AddressDetails;
	isBillingAddressSameAsPhysical?: boolean;
	isBillingAddressSameAsShipping?: boolean;
	cardHolder?: string;
	cardNumber?: string;
	CVV?: string;
	cardExpMonth?: number;
	cardExpYear?: number;
	estimatedTaxError?: any;
	estimatedTaxValidationErrors: {
		taxEstimateAddress: Array<{ type: string; message: string }>;
	} | null;
	estimatedTax: {
		amount: number;
		rate: number;
		taxSummary?: string;
	} | null;
}

export const initialState = {
	pathType: null,
	persistExpiresAt: null,
	asyncState: {
		validatingData: false,
		tokenizingCard: false,
		finalizingSubscription: false,
		estimatingTax: false
	},
	// Snake cased to mirror rails backend
	subscriptionPayload: {
		course_id: null,
		terms_of_service_accepted: false,
		shipping_details: {
			street1: null,
			street2: null,
			city: null,
			state: null,
			zip: null
		},
		user: {
			first_name: null,
			last_name: null,
			email_address: null,
			password: null
		},
		payment_details: {
			payment_method: null,
			passkey_plaintext: '',
			tokenized_cc: null
		}
	},
	course_search_info: {
		course: null,
		instructor: null,
		school: null
	},
	tokenizationResult: null,
	subscriptionValidation: null,
	subscriptionFinalization: null,
	email: null,
	email_confirm: null,
	password: null,
	password_confirm: null,
	first_name: null,
	last_name: null,
	cardHolder: null,
	cardNumber: null,
	CVV: null,
	cardExpMonth: null,
	cardExpYear: null,
	estimatedTax: null
} as unknown as UserState;

export default function user(
	state: UserState = initialState,
	action: UserAction | AppAction
): UserState {
	switch (action.type) {
		case getType(appActions.appStart):
			const { config } = action.payload;

			let school = state.course_search_info.school;
			let instructor = state.course_search_info.instructor;
			let course = state.course_search_info.course;
			let course_id;

			if (isSchoolPresentInConfig(config)) {
				school = {
					label: config.school.name,
					value: config.school.id,
					lms_school: config.school.lms_school,
					lms_instructions: config.school.lms_instructions,
					lms_warning_header: '',
					never_show_course_list: config.school.never_show_course_list
				};
			}

			if (isCoursePresentInConfig(config) && config.path_type === 'prefilled') {
				instructor = {
					label: config.course.instructor.name,
					value: config.course.instructor.id
				};
				course = {
					label: config.course.number,
					value: config.course.id,
					lms_course: false
				};
				course_id = config.course.id;
			}

			return {
				...state,
				subscriptionPayload: {
					...state.subscriptionPayload,
					course_id
				},
				email: config.user.email || state.email,
				pathType: config.path_type,
				course_search_info: {
					...state.course_search_info,
					school,
					course,
					instructor
				}
			};
		case getType(userActions.userUpdate):
			return {
				...merge(state, action.payload)
			};
		case getType(userActions.setFormValue):
			return {
				...merge(state, action.payload)
			};
		case getType(userActions.userUpdateSubscriptionInfo):
			return {
				...state,
				subscriptionPayload: {
					...state.subscriptionPayload,
					...action.payload
				}
			};

		case getType(userActions.userSetTermsAgreed):
			return {
				...state,
				subscriptionPayload: {
					...state.subscriptionPayload,
					terms_of_service_accepted: action.payload
				}
			};
		case getType(userActions.userUpdateCourseSearchInfo):
			return {
				...state,
				course_search_info: {
					...state.course_search_info,
					...action.payload
				}
			};
		case getType(userActions.userUpdateAddress):
			return {
				...state,
				subscriptionPayload: {
					...state.subscriptionPayload,
					shipping_details: {
						...state.subscriptionPayload.shipping_details,
						...action.payload
					}
				}
			};
		case getType(userActions.userUpdatePaymentDetails):
			return {
				...state,
				subscriptionPayload: {
					...state.subscriptionPayload,
					payment_details: {
						...state.subscriptionPayload.payment_details,
						...action.payload
					}
				}
			};
		case getType(userActions.userSetTokenizedCC):
			return {
				...state,
				subscriptionPayload: {
					...state.subscriptionPayload,
					payment_details: {
						...state.subscriptionPayload.payment_details,
						tokenized_cc: action.payload
					}
				}
			};
		case getType(userActions.userTokenizeCardInfo.request):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					tokenizingCard: true
				}
			};
		case getType(userActions.userTokenizeCardInfo.failure):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					tokenizingCard: false
				}
			};
		case getType(userActions.userTokenizeCardInfo.success):
			return {
				...state,
				tokenizationResult: action.payload,
				asyncState: {
					...state.asyncState,
					tokenizingCard: false
				}
			};
		case getType(userActions.userSubscriptionValidation.request):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					validatingData: true
				}
			};
		case getType(userActions.userSubscriptionValidation.success):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					validatingData: false
				},
				subscriptionValidation: action.payload
			};
		case getType(userActions.userSubscriptionValidation.failure):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					validatingData: false
				}
			};
		case getType(userActions.userSubscriptionFinalization.request):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					finalizingSubscription: true
				}
			};
		case getType(userActions.userSubscriptionFinalization.success):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					finalizingSubscription: false
				},
				subscriptionFinalization: action.payload
			};
		case getType(userActions.userSubscriptionFinalization.failure):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					finalizingSubscription: false
				}
			};
		case getType(userActions.userFetchEstimatedTax.request):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					estimatingTax: true
				},
				estimatedTaxError: null,
				estimatedTaxValidationErrors: null,
				estimateAddress: { ...state.physicalAddress }
			};
		case getType(userActions.userFetchEstimatedTax.success):
			return {
				...state,
				asyncState: {
					...state.asyncState,
					estimatingTax: false
				},
				estimatedTax: action.payload
			};
		case getType(userActions.userFetchEstimatedTax.failure):
			const error = action.payload.response?.data?.error;
			if (error?.type === 'validation') {
				return {
					...state,
					asyncState: {
						...state.asyncState,
						estimatingTax: false
					},
					estimatedTaxValidationErrors: error.errors,
					estimatedTaxError: 'Error loading tax',
					estimatedTax: null
				};
			} else {
				return {
					...state,
					asyncState: {
						...state.asyncState,
						estimatingTax: false
					},
					estimatedTaxError: action.payload,
					estimatedTax: null
				};
			}
		default:
			return state;
	}
}
