import styled from 'styled-components';

const SelectInputStyles = styled.div`
	margin-bottom: 16px;
	max-width: 360px;

	label {
		font-weight: bold;
		color: #757575;
	}

	.soomo-select__control {
		height: 52px;
		border: 2px solid ${(props) => props.theme.selectInput.border};
		border-radius: 0;
		background-color: white;
		text-align: center;
		font-family: Helvetica, Arial, sans-serif;
		letter-spacing: 0.15rem;
		text-transform: uppercase;
		transition: box-shadow 100ms cubic-bezier(0.15, 0.82, 0.38, 0.95);
		&:hover {
			border: 2px solid ${(props) => props.theme.selectInput.border};
			box-shadow: 0 1px 3px 0 #a4bd2496;
		}

		&:focus {
			border: 2px solid ${(props) => props.theme.selectInput.border};
		}
	}

	.soomo-select__control--is-focused {
		box-shadow: 0 1px 3px 0 #a4bd2496;
		border: 2px solid ${(props) => props.theme.selectInput.border};
	}

	.soomo-select__placeholder {
		color: #717171;
		white-space: initial;
		line-height: 1.25;
	}

	.soomo-select__indicator {
		color: #757575 !important;
	}

	.soomo-select__dropdown-indicator {
		svg {
			color: #757575;
		}
	}

	.soomo-select__value-container {
		align-items: center;
		justify-content: center;
		word-wrap: unset;
		white-space: nowrap;
		text-align: center;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		font-size: 0.975rem;
		margin-left: 20px;
	}

	.soomo-select__indicator-separator {
		display: none;
	}

	&.show-native-fallback {
		.soomo-select__control {
			display: none;
		}

		.native-select {
			display: block;
		}
	}

	&.force-native-feedback {
		.soomo-select__control {
			display: none;
		}

		.native-select {
			display: block;
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		max-width: 320px;
		&.narrow {
			width: 90px;
			.soomo-select__value-container {
				margin-left: 0px;
				margin-right: -16px;
			}
		}
		&.show-native-fallback {
			.soomo-select__control {
				display: flex;
			}
			.native-select {
				display: none;
			}
		}

		&.force-native-fallback {
			.soomo-select__control {
				display: none;
			}
			.native-select {
				display: block;
			}
		}
	}
`;

export default SelectInputStyles;
