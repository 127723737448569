import { Duration } from 'luxon';

import states from '~/fixtures/states';
import {
	AppConfig,
	AppConfigWithCoursePresent,
	FullShippingInfo,
	ShippingDetails,
	ValidationErrors
} from '~/types';

export function buildPaymentOptionMap(config: AppConfigWithCoursePresent) {
	return config.course.subscription.payment_methods
		? {
				creditcard: config.course.subscription.payment_methods.creditcard,
				passkey: config.course.subscription.payment_methods.passkey,
				trial: config.course.subscription.trial.enabled
		  }
		: {};
}

export function selectPasskeyOnly(config: AppConfigWithCoursePresent) {
	const paymentOptions = buildPaymentOptionMap(config);
	const { creditcard, passkey, trial } = paymentOptions;
	return passkey && !creditcard && !trial;
}

/**
 * Determines whether the app should use basic inputs for compatibility with older browsers
 * @param config
 */
export function useBasicInputs(config: AppConfig) {
	return config.user_agent.unsupported || config.user_agent.use_legacy_inputs;
}

interface PaymentOption {
	label: string;
	value: string;
	description: string;
}
export const buildSelectPaymentOptions = (config: AppConfigWithCoursePresent): PaymentOption[] => {
	if (!config.course.subscription.payment_methods) return [];

	const paymentMethods = buildPaymentOptionMap(config);
	const selectList: Array<{ label: string; value: string; description: string }> = [];
	const owed = config.course.subscription.amount_owed;
	const cost = owed || config.course.subscription.cost;
	const trialLength = config.course.subscription.trial.content_description;

	if (paymentMethods.passkey) {
		const subLengthSeconds = config.course.subscription.duration;
		const subLengthDescription = buildSubLengthDescription(subLengthSeconds);
		selectList.push({
			label: 'PASSKEY - From your bookstore',
			value: 'passkey',
			description: `Activate your ${subLengthDescription} subscription using a passkey purchased from your school’s bookstore.`
		});
	}

	if (paymentMethods.creditcard) {
		selectList.push({
			label: `CREDIT CARD - $${cost} plus applicable tax`,
			value: 'creditcard',
			description: `Subscribe now using a credit card for $${cost} plus tax.`
		});
	}

	if (paymentMethods.trial) {
		const trialDescription = config.course.subscription.trial.content_description;
		const defaultDescription = 'first three (3) chapters';
		selectList.push({
			label: `FREE LIMITED TRIAL - ${
				trialLength === defaultDescription ? '3 chapters' : trialLength
			}`,
			value: 'trial',
			description: `Choose a free limited trial of the ${trialDescription}.`
		});
	}

	return selectList;
};

export function buildSubLengthDescription(lengthSeconds) {
	const subLengthMS = lengthSeconds * 1000;
	const subDuration = Duration.fromMillis(subLengthMS);
	const months = Math.floor(subDuration.as('months'));
	const weeks = Math.floor(subDuration.as('weeks'));
	let subLengthDescription;
	if (months < 1) {
		subLengthDescription = `${numberToWord[weeks]}-week`;
	} else {
		subLengthDescription = `${numberToWord[months]}-month`;
	}
	return subLengthDescription;
}

export const numberToWord = {
	0: 'zero',
	1: 'one',
	2: 'two',
	3: 'three',
	4: 'four',
	5: 'five',
	6: 'six',
	7: 'seven',
	8: 'eight',
	9: 'nine',
	10: 'ten',
	11: 'eleven',
	12: 'twelve'
};

/**
 * Builds messages relevant to the current path step
 * @param {string} stepName the name of the current step
 * @param {ValidationErrors} errors from the validation response
 * @returns {string[]} a list of errors to be shown in the view
 */
export function buildValidationErrorMessages(stepName: string, errors: ValidationErrors): string[] {
	let errorMessages: string[] = [];
	if (errors) {
		switch (stepName) {
			case 'passkey_input': {
				const { payment_details } = errors;
				if (payment_details) {
					const { payment_method, passkey } = payment_details;
					if (payment_method) {
						errorMessages.push(...buildPaymentMethodMessages(payment_method));
					}

					if (passkey) {
						errorMessages.push(...buildPasskeyValidationMessages(passkey));
					}
				}
				break;
			}
			case 'print_passkey_input': {
				const { payment_details } = errors;
				if (payment_details?.passkey) {
					errorMessages.push(...buildPrintPasskeyValidationMessages(payment_details.passkey));
				}
				break;
			}
			case 'select_school':
				const { course_search_info } = errors;
				if (course_search_info) {
					if (course_search_info.school.indexOf('lms_school') != -1) {
						errorMessages.push('lms_school');
					}
				}
				break;
			case 'register_email':
				const { user } = errors;
				if (
					user &&
					user.email_address &&
					user.email_address.indexOf('has already been taken') !== -1
				) {
					errorMessages.push('That email has already been used');
				}
		}
	}
	return errorMessages;
}

/**
 * @param {ValidationErrors} errors from the finalization response
 * @returns {string[]} a list of errors to be shown in the view
 */
export function buildFinalizationErrorMessages(response): string[] {
	if (!response) return ['No response found for finalization'];
	const errors = response.errors;
	const errorMessages: string[] = [];
	const { payment_details } = errors;
	if (payment_details) {
		const { payment_method, passkey, tokenized_cc, credit_card } = payment_details;
		if (payment_method) {
			errorMessages.push(...buildPaymentMethodMessages(payment_method));
		}

		if (passkey) {
			errorMessages.push(...buildPasskeyValidationMessages(passkey));
		}

		if (credit_card) {
			errorMessages.push(...buildCreditCardMessages(credit_card));
		}

		if (tokenized_cc) {
			errorMessages.push(tokenized_cc);
		}
		// Throw a default in since there's definitely an error
		if (!errorMessages.length) errorMessages.push('finalization error');
	} else if (Object.keys(errors).length !== 0) {
		errorMessages.push('There was an error finalizing the subscription');
	}

	let { missing } = response;
	if (Object.keys(missing).length) {
		errorMessages.push(`missing required data: ${Object.keys(missing)}`);
	}

	return errorMessages;
}

function buildPaymentMethodMessages(errors: string[]): string[] {
	const messages: string[] = [];
	errors.forEach((error) => {
		switch (error) {
			case 'is not included in the list':
				messages.push('Invalid payment method provided.');
				break;
			default:
				messages.push(`Payment method ${error}`);
		}
	});
	return messages;
}

function buildCreditCardMessages(errors: string[]): string[] {
	const messages: string[] = [];
	errors.forEach((error) => {
		switch (error) {
			case 'must be valid':
				messages.push('Credit card must be valid');
				break;
			default:
				messages.push(`Card ${error}`);
		}
	});
	return messages;
}

const errorOrdering = [
	'is not valid',
	'not valid for webtext (print only)',
	'not valid for webtext (print)',
	'has been voided',
	'has already been used by you',
	'has already been used by someone else',
	'has already been used',
	'does not have enough value',
	'contains too much value'
];

const byOrdering = (ordering) => (a, b) => {
	let ia = ordering.indexOf(a);
	let ib = ordering.indexOf(b);

	// ensure unknown item is sorted last
	if (ia < 0) ia = ordering.length;
	if (ib < 0) ib = ordering.length;

	return ia - ib;
};

function buildPasskeyValidationMessages(errors: string[]): string[] {
	const messages: string[] = [];
	const error = errors.sort(byOrdering(errorOrdering))[0];
	if (error) {
		switch (error) {
			case 'is not valid':
				messages.push('This passkey is invalid. Make sure you typed it in correctly!');
				break;
			case 'does not have enough value':
				messages.push(
					'This passkey has insufficient value. Contact your school bookstore to exchange it for another one.'
				);
				break;
			case 'contains too much value':
				messages.push(
					'This passkey has a higher value than the price of the webtext for this course. Please contact your bookstore to ensure you have the correct passkey.'
				);
				break;
			case 'has already been used by you':
				messages.push(
					'You’ve already used this passkey for another course. You must use a different passkey for each webtext.'
				);
				break;
			case 'has already been used by someone else':
				messages.push(
					'This passkey has already been used by another student. Please contact your school bookstore to get a new passkey.'
				);
				break;
			case 'has already been used':
				messages.push(
					'This passkey has already been used. Please contact your school bookstore to get a new passkey.'
				);
				break;
			case 'has been voided':
				messages.push(
					'This passkey has been voided. Contact your school bookstore to exchange it for another one.'
				);
				break;
			case 'not valid for webtext (print)':
				messages.push('This passkey cannot be used for this webtext.');
				break;
			case 'not valid for webtext (print only)':
				messages.push(
					'This passkey should start with "WT" and have a total of 13 letters and numbers.'
				);
				messages.push(
					'The passkey you entered starts with "WPOD" and is for a print copy of the webtext. You can order a print book with this passkey later by clicking the Order a Print Book button in the Tools Menu of your webtext.'
				);
				break;
			default:
				messages.push(`${error}`);
		}
	}
	return messages;
}

const printErrorOrdering = [
	'is not valid',
	'not valid for webtext (subscription only)',
	'not valid for webtext (subscription and print)',
	'has been voided',
	'has already been used'
];

function buildPrintPasskeyValidationMessages(errors: string[]): string[] {
	const messages: string[] = [];
	const error = errors.sort(byOrdering(printErrorOrdering))[0];
	if (error) {
		switch (error) {
			case 'is not valid':
				messages.push('This passkey is invalid. Make sure you typed it in correctly!');
				break;
			case 'not valid for webtext (subscription only)':
				messages.push(
					'This passkey should start with "WPOD" and have a total of 13 letters and numbers.'
				);
				messages.push(
					'The passkey you entered starts with "WT" and can be used for a webtext but not a print copy.'
				);
				break;
			case 'not valid for webtext (subscription and print)':
				messages.push(
					'This passkey should start with "WPOD" and have a total of 13 letters and numbers.'
				);
				break;
			case 'has already been used':
				messages.push(
					'This passkey has already been used. Please contact your school bookstore to get a new passkey.'
				);
				break;
			case 'has been voided':
				messages.push(
					'This passkey has been voided. Contact your school bookstore to exchange it for another one.'
				);
				break;
			default:
				messages.push(`${error}`);
		}
	}
	return messages;
}

export function toggleContinue(canContinue, valid, callback) {
	if (canContinue && !valid) {
		callback(false);
	} else if (!canContinue && valid) {
		callback(true);
	}
}

export function buildFullShippingAddress(
	shippingDetails: ShippingDetails,
	appConfig: AppConfig
): FullShippingInfo {
	return {
		...shippingDetails,
		name: `${appConfig.user.first_name} ${appConfig.user.last_name}`
	};
}

export function fullStateForAbbreviation(stateAbbreviation: string) {
	return states.find((s) => s.value == stateAbbreviation)!.label;
}
