import styled from 'styled-components';

const NativeSelectBasicStyles = styled.div`
	.select-container {
		border: 2px solid ${(props) => props.theme.selectInput.border};
		height: 52px;
		max-width: 360px;
		width: 100%;
		display: inline-flex;
		align-items: center;
		position: relative;
		background-color: white;
		z-index: 0;
		transition-property: color, box-shadow;
		transition: 100ms cubic-bezier(0.15, 0.82, 0.38, 0.95);

		&:hover {
			border: 2px solid ${(props) => props.theme.selectInput.border};
			box-shadow: 0 1px 3px 0 #a4bd2496;
		}

		select {
			width: 100%;
			background: none;
			border: none;
			outline: none;
			border-radius: 0;
			padding: 16px;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-family: Helvetica, Arial, sans-serif;
			font-size: 16px;
			letter-spacing: 0.15rem;
			text-transform: uppercase;
		}

		@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
			padding-left: 8px;
			padding-right: 8px;
			select {
				font-size: 12px;
			}
		}
`;

export default NativeSelectBasicStyles;
