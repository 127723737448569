import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '~/store';

import PathContent from '~/components/PathContent/PathContent';
import ProgressBar from '~/components/ProgressBar';
import { AppLoadingState } from '~/types';

import { SoomoLogo } from '~/components';
import ErrorBlurb from '~/components/error_blurb';
import ErrorBoundary from '~/components/error_boundary';
import Loader from '~/components/loader';

import AppContentStyles from './styles';

export interface Props {
	appError: string;
	loadingState: AppLoadingState;
}

class AppContent extends React.Component<Props> {
	private renderLoader() {
		return <Loader />;
	}

	private renderUnloaded = () => (
		<div className="status-card">
			<div className="d-sm-none">
				<SoomoLogo />
			</div>
			<p>App unloaded.</p>
		</div>
	);

	private statusCard = (msg) => (
		<div className="status-card">
			<div className="d-sm-none">
				<SoomoLogo />
			</div>
			<h2>Something went wrong</h2>
			<p className="font-italic">"{msg}"</p>
			<ErrorBlurb />
		</div>
	);

	public renderState() {
		const { appError } = this.props;
		switch (this.props.loadingState) {
			case 'loading':
				return this.renderLoader();
			case 'errored':
				return this.statusCard(appError || 'The app encountered an error');
			case 'unloaded':
				return this.renderUnloaded();
			default:
				return <PathContent />;
		}
	}

	public render() {
		return (
			<AppContentStyles className="app-content d-flex flex-column">
				<ProgressBar />
				<div className="row no-gutters justify-content-center flex-grow-1">
					<div className="app-column px-sm-5 px-md-0 col-sm-12 col-md-11 col-lg-11 col-xl-10 d-flex d-flex-column">
						<div className="flex-grow-1 d-flex d-flex-column d-sm-block">
							<div className="title-bar d-none d-sm-block">
								<SoomoLogo />
							</div>
							<ErrorBoundary>{this.renderState()}</ErrorBoundary>
						</div>
					</div>
				</div>
			</AppContentStyles>
		);
	}
}

export default connect((state: RootState) => ({
	loadingState: state.app.loadingState,
	appError: state.app.appError
}))(AppContent);
