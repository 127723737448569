import { MdArrowDropDown, MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { RootState } from '~/store';
import { sm } from '~/styles/mixins/breakpoints';

import { SignupStep } from '..';
import FinalizationErrors from '../FinalizationErrors';
import { SignupStepPropsWithCoursePresentInAppConfig } from './SignupStep';

export default () => (
	<SignupStep buttonText={() => <>Purchase</>} validation={({ estimatedTax }) => !!estimatedTax}>
		{(props) => <Page {...props} />}
	</SignupStep>
);

const formatCost = (cost) => {
	return cost?.toString().indexOf('.') === -1 ? `$${cost}.00` : `$${cost}`;
};

const Page = ({
	cardNumber,
	CVV,
	cardHolder,
	cardExpMonth,
	cardExpYear,
	signupConfig,
	email,
	estimatedTax,
	physicalAddress
}: SignupStepPropsWithCoursePresentInAppConfig) => {
	const { retaking, subscription } = signupConfig.course;
	const { amount_owed, cost, print_cost, amount_paid } = subscription;

	const productName = signupConfig.path_type === 'print_purchase' ? 'Print book' : 'Webtext';

	const productCost = signupConfig.path_type === 'print_purchase' ? print_cost : cost;

	const formattedProductCost = formatCost(productCost);
	const amountOwedFormatted = formatCost(amount_owed);
	const amountPaidFormatted = formatCost(amount_paid);
	const taxRate = `${((estimatedTax?.rate || 0) * 100).toFixed(2)}%`;

	return (
		<>
			<h1>Review your order</h1>

			<CardNumber>
				<span className="fs-exclude">Card number: {cardNumber}</span>
				<Link to="/credit_card_input">
					<MdEdit />
					Edit
				</Link>
			</CardNumber>

			<div className="fs-exclude">CVV: {CVV}</div>
			<div>Name: {cardHolder}</div>
			<div className="fs-exclude">
				Expiration date: {cardExpMonth!.toString().length == 1 ? `0${cardExpMonth}` : cardExpMonth}/
				{cardExpYear!.toString().substring(2, 4)}
			</div>

			<Ruling />

			<div style={{ fontWeight: 'bold', marginBottom: '1em' }}>Order Summary</div>

			<BillingSummary className={estimatedTax && 'tax-percentage-message-displayed'}>
				<LineItem>
					<span>{productName} cost:</span>
					<span>{formattedProductCost}</span>
				</LineItem>

				{amount_paid && (
					<LineItem>
						<span>Credit:</span>
						<span className="italic">–{amountPaidFormatted}</span>
					</LineItem>
				)}

				{amount_paid && amount_owed && (
					<LineItem>
						<span>Amount owed:</span>
						<span>{amountOwedFormatted}</span>
					</LineItem>
				)}

				<LineItem className="sales-tax-value">
					<span>{physicalAddress!.state} sales tax:</span>
					<span>
						<AsyncTaxValue />
					</span>
					{estimatedTax && (
						<div className="tax-percentage-message">
							<MdArrowDropDown size={32} color="#BDD63A" />
							<div>For your location, the sales tax rate is {taxRate}</div>
						</div>
					)}
				</LineItem>

				<LineItem>
					<span>Total:</span>
					<span>
						<AsyncTaxValue
							baseCost={retaking ? signupConfig.course.subscription.amount_owed : productCost}
							error="Cannot calculate total"
						/>
					</span>
				</LineItem>
			</BillingSummary>
			<MobileTaxMessage className="mobile-tax-percentage-message">
				For your location, the sales tax rate is {taxRate}
			</MobileTaxMessage>
			<p>
				Receipt will be sent to <b>{email}</b>
			</p>
			<div>
				<FinalizationErrors />
				<TaxErrors />
			</div>
		</>
	);
};

const AsyncTaxValue = ({
	baseCost = 0,
	error = 'Error loading tax'
}: {
	baseCost?: number;
	error?: string;
}) => {
	const { estimatedTax, estimatedTaxError, loadingTax } = useSelector(taxInfoSelector);

	if (!loadingTax && estimatedTax) {
		const { amount } = estimatedTax;
		let total = amount;
		if (baseCost != 0) {
			total += baseCost;
		}
		return <>{total == 0 ? '$0.00' : `$${total.toFixed(2)}`}</>;
	}
	if (!loadingTax && estimatedTaxError) {
		return (
			<span className="error-message" style={{ fontWeight: 'normal', fontSize: '1.2rem' }}>
				{error}
			</span>
		);
	}

	if (loadingTax) {
		return <>loading...</>;
	}

	return null;
};

const TaxErrors = () => {
	const { estimatedTaxError, estimatedTaxValidationErrors, loadingTax, printPurchasePath } =
		useSelector(taxInfoSelector);
	if (
		!loadingTax &&
		estimatedTaxValidationErrors &&
		estimatedTaxValidationErrors.taxEstimateAddress
	) {
		const errors = estimatedTaxValidationErrors.taxEstimateAddress;
		// shortcut for now because we'll typically only have a single error
		if (errors.length === 1 && errors[0].type === 'state_zip_mismatch') {
			return (
				<p className="error-message transaction-error">
					State and zip code do not match. Please{' '}
					<Link to="/full_address">go back and review addresses</Link> again.
				</p>
			);
		}
	} else if (!loadingTax && estimatedTaxError) {
		return (
			<p className="error-message transaction-error">
				Cannot calculate sales tax at this time. Please try again later.
				{!printPurchasePath && (
					<>
						<br />
						If you need immediate access, enter a <Link to="/trial_info"> free trial</Link> and pay
						later.
					</>
				)}
			</p>
		);
	}
	return null;
};

const taxInfoSelector = (state: RootState) => ({
	estimatedTax: state.user.estimatedTax,
	estimatedTaxError: state.user.estimatedTaxError,
	estimatedTaxValidationErrors: state.user.estimatedTaxValidationErrors,
	loadingTax: state.user.asyncState.estimatingTax,
	printPurchasePath: state.app.config.path_type === 'print_purchase'
});

const MobileTaxMessage = styled.div`
	display: none;
	margin-top: 1.25em;
	margin-bottom: 1.25em;
	font-size: 15px;
	font-style: italic;
	@media (max-width: ${sm}px) {
		display: block;
	}
	color: #757575;
`;

const Ruling = styled.div`
	margin-top: 2rem;
	margin-bottom: 2rem;
	width: 335px;
	height: 1px;
	background-color: #bdd63a;
	@media (max-width: ${sm}px) {
		width: 100%;
	}
`;

const CardNumber = styled.div`
	display: flex;
	justify-content: space-between;
	max-width: 328px;
	@media (max-width: ${sm}px) {
		max-width: 100%;
	}
`;

const BillingSummary = styled.div`
	margin-bottom: 2em;
	width: 100%;
	> div {
		display: flex;
		justify-content: space-between;
	}

	.tax-percentage-message {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 95%;
		margin: 0;
		padding: 0;
		top: -1.2em;

		div {
			border-left: 3px solid #bdd63a;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px;
			height: 55px;
			padding-left: 1em;
			font-size: 15px;
			font-style: italic;
			color: #757575;
		}

		svg {
			position: relative;
			transform: rotate(90deg);
			right: 0;
			left: 1em;
			flex-shrink: 0;
		}
	}

	@media (max-width: ${sm}px) {
		flex-direction: column;
		width: 100%;
		margin-bottom: 0;
		.tax-percentage-message {
			display: none;
		}
	}
`;

const LineItem = styled.div`
	max-width: 60%;
	.tax-percentage-message-displayed & {
		max-width: 40%;
	}

	display: flex;
	justify-content: space-between;
	line-height: 1.5em;
	.italic {
		font-style: italic;
	}
	:last-child {
		font-weight: bold;
		font-size: 1.5em;
	}

	&.sales-tax-value {
		/* so that the percentage message can be offset  */
		position: relative;
	}

	@media (max-width: ${sm}px) {
		max-width: 100%;
	}
`;
