function SoomoLogoHorizontal(props) {
	return (
		<svg data-name="Layer 1" width="3.72in" height={72.96} viewBox="0 0 268.16 54.4" {...props}>
			<g fill="#231f20">
				<path d="M121.23 28.8c-3.17-.54-5.91-1-5.91-3.16 0-1.84 1.92-2.89 5.28-2.89a13.61 13.61 0 017.29 2l.09.05 2.39-4.89-.07-.05a20 20 0 00-9.66-2.3c-6.91 0-11.37 3.29-11.37 8.39 0 6.34 5.62 7.25 10.14 8 3 .49 5.69.92 5.69 2.9s-1.77 2.84-5.27 2.84A16.78 16.78 0 01111 37l-.1-.07-2.39 4.9h.07c2.45 1.8 6.83 3 10.91 3 7.22 0 11.71-3.18 11.71-8.29-.04-6.05-5.55-6.99-9.97-7.74zM146.94 17.54c-7.95 0-13.72 5.74-13.72 13.66a13.26 13.26 0 0013.72 13.71 13.88 13.88 0 009.9-3.85 13.74 13.74 0 003.88-9.86 13.63 13.63 0 00-3.88-9.83 13.91 13.91 0 00-9.9-3.83zm0 21.86c-4.4 0-7.48-3.37-7.48-8.2s3.08-8.2 7.48-8.2 7.53 3.37 7.53 8.2-3.09 8.2-7.53 8.2zM177.13 17.54c-8 0-13.73 5.74-13.73 13.66a13.26 13.26 0 0013.73 13.71 13.87 13.87 0 009.87-3.85 13.7 13.7 0 003.88-9.86 13.59 13.59 0 00-3.88-9.83 13.88 13.88 0 00-9.87-3.83zm0 21.86c-4.41 0-7.48-3.37-7.48-8.2s3.07-8.2 7.48-8.2 7.53 3.37 7.53 8.2-3.1 8.2-7.53 8.2zM226.69 17.54a11.92 11.92 0 00-9.58 4.34c-1.73-2.8-4.8-4.34-8.65-4.34a10.63 10.63 0 00-8.3 3.5v-3.21h-5.91v26.73h6.2V31c0-5 2.39-7.85 6.57-7.85 3.7 0 5.66 2.39 5.66 6.91v14.5h6.2V31c0-4.91 2.45-7.85 6.57-7.85 3.7 0 5.66 2.39 5.66 6.91v14.5h6.19v-15.3c0-7.45-3.86-11.72-10.61-11.72zM264.28 21.37a13.9 13.9 0 00-9.89-3.83c-8 0-13.73 5.74-13.73 13.66a13.26 13.26 0 0013.73 13.71 13.87 13.87 0 009.89-3.85 13.74 13.74 0 003.88-9.86 13.63 13.63 0 00-3.88-9.83zm-9.89 18c-4.41 0-7.49-3.37-7.49-8.2S250 23 254.39 23s7.53 3.37 7.53 8.2-3.1 8.2-7.53 8.2z" />
			</g>
			<path
				d="M12.3 39.07v-12H.58a.57.57 0 00-.58.57V49a.58.58 0 00.58.57h37.1a.58.58 0 00.58-.57v-9.35H12.88a.58.58 0 01-.58-.58zM63.55 46.4V16.48a.58.58 0 01.58-.58H75V.58a.58.58 0 00-.58-.58H47.67a.58.58 0 00-.58.58V9h8.57a.58.58 0 01.57.58v29.49a.58.58 0 01-.57.58h-8.57v14.17a.58.58 0 00.58.58h26.75a.58.58 0 00.58-.58V47H64.13a.57.57 0 01-.58-.6z"
				fill="#bed73f"
			/>
			<path
				d="M12.88 9a.58.58 0 00-.58.58v17.5h25.38a.57.57 0 01.58.57v12h8.83V9z"
				fill="#bed73f"
			/>
			<path
				d="M37.68 27.06H12.3v12a.58.58 0 00.58.58h25.38v-12a.57.57 0 00-.58-.58zM56.23 39.07V9.56a.58.58 0 00-.57-.56h-8.57v30.65h8.57a.58.58 0 00.57-.58z"
				fill="#75b643"
			/>
			<path
				d="M86.64 15.9H75V47h11.64a.57.57 0 00.58-.57V16.48a.58.58 0 00-.58-.58z"
				fill="#bed73f"
			/>
			<path d="M64.13 15.9a.58.58 0 00-.58.58V46.4a.57.57 0 00.58.57H75V15.9z" fill="#75b643" />
		</svg>
	);
}

export default SoomoLogoHorizontal;
