import validate from 'validate.js';

import { AddressInput } from '~/components/input';
import SignupStep from '~/components/path_steps/SignupStep';
import { addressDetailsValidation } from '~/utils/validations';

const validation = (userState) => !validate(userState.billingAddress, addressDetailsValidation);

export default () => (
	<SignupStep validation={validation}>
		{({ setFormValue, billingAddress }) => (
			<>
				<h1>Enter billing address</h1>
				<p className="italics">What is the address associated with this credit card?</p>
				<AddressInput
					value={billingAddress}
					onChange={(billingAddress) => setFormValue({ billingAddress })}
				/>
			</>
		)}
	</SignupStep>
);
