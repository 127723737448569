import React from 'react';

import { TextInput } from '~/components/input';
import AppButton from '~/connected/app_button';
import FrontDoorInfoHeader from '~/connected/front_door_info_formatter';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

class RegisterPasswordStep extends React.Component<Props> {
	private handlePasswordChange = (e) => {
		this.props.userUpdate({ password: e.target.value.replace(/ /g, '') });
	};

	private handlePasswordConfirmChange = (e) => {
		this.props.userUpdate({
			password_confirm: e.target.value.replace(/ /g, '')
		});
	};

	public render() {
		const { user_state } = this.props;
		return (
			<PathStepStyles>
				<div className="page-content">
					<FrontDoorInfoHeader />
					<h1>Enter the password you will use to log in.</h1>
					<hr />
					<p className="italics">Your password must be at least 8 characters long.</p>
					<TextInput
						autoFocus
						label="Password Login"
						type="password"
						name="password"
						placeholder="••••••"
						value={user_state.password}
						onChange={this.handlePasswordChange}
					/>
					<TextInput
						label="Re-enter password"
						type="password"
						name="confirm_password"
						placeholder="••••••"
						value={user_state.password_confirm}
						onChange={this.handlePasswordConfirmChange}
					/>
					{!this.props.canContinue && 'Passwords must match.'}
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}

export default RegisterPasswordStep;
