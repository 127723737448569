import { AppConfig, AppLoadingState, StepConfig } from '~/types';

import { createAsyncAction, createStandardAction } from 'typesafe-actions';

export const appStart = createStandardAction('APP_START')<{
	config: AppConfig;
	path: Array<StepConfig>;
}>();

export const appSetLoadingState = createStandardAction('APP_SET_LOADING_STATE')<AppLoadingState>();

export const appError = createStandardAction('APP_ERROR')<string>();

export const appSetCanContinue = createStandardAction('APP_SET_CAN_CONTINUE')<boolean>();

export const appStepMounted = createStandardAction('APP_STEP_MOUNTED')<string>();

export const appSetSubmittingSubscription = createStandardAction(
	'APP_SET_SUBMITTING_SUBSCRIPTION'
)<boolean>();

export const appSetValidationErrors = createStandardAction('APP_SET_VALIDATION_ERRORS')<string[]>();

export const appSetFinalizationErrors = createStandardAction('APP_SET_FINALIZATION_ERRORS')<
	string[]
>();

export const appSetFinalized = createStandardAction('APP_SET_FINALIZED')<boolean>();

export const appSetPurchaseComplete = createStandardAction('APP_SET_PURCHASE_COMPLETE')<boolean>();

export const appSetPrintPurchaseComplete = createStandardAction(
	'APP_SET_PRINT_PURCHASE_COMPLETE'
)<boolean>();

export const appSetBootError = createStandardAction('APP_SET_BOOT_ERROR')<string>();

export const appSetTokenizationErrors = createStandardAction('APP_SET_TOKENIZATION_ERRORS')<
	string[]
>();

export const appFetchConfig = createAsyncAction(
	'APP_FETCH_CONFIG_REQUEST',
	'APP_FETCH_CONFIG_SUCCESS',
	'APP_FETCH_CONFIG_FAILURE'
)<string, AppConfig, Error>();

export const appFetchConfigBoot = createAsyncAction(
	'APP_FETCH_CONFIG_BOOT_REQUEST',
	'APP_FETCH_CONFIG_BOOT_SUCCESS',
	'APP_FETCH_CONFIG_BOOT_FAILURE'
)<string, AppConfig, Error>();

export const appContinue = createStandardAction('APP_CONTINUE')();
export const appDisabledContinueClick = createStandardAction('APP_DISABLED_CONTINUE_CLICK')();
