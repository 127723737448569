import styled from 'styled-components';

const NativeSelectStyles = styled.div`
	.select-container {
		border: 2px solid ${(props) => props.theme.selectInput.border};
		height: 52px;
		max-width: 320px;
		width: 100%;
		display: inline-flex;
		align-items: center;
		position: relative;
		background-color: white;
		z-index: 0;
		transition-property: color, box-shadow;
		transition: 100ms cubic-bezier(0.15, 0.82, 0.38, 0.95);
		&:hover {
			border: 2px solid ${(props) => props.theme.selectInput.border};
			box-shadow: 0 1px 3px 0 #a4bd2496;
			svg {
				color: hsl(0, 0%, 60%);
			}
		}

		select {
			width: 100%;
			background: none;
			border: none;
			outline: none;
			-webkit-appearance: none;
			border-radius: 0;
			padding: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: transparent;
			position: relative;
			text-align: center;
			font-family: Helvetica, Arial, sans-serif;
			font-size: 16px;
			letter-spacing: 0.15rem;
			text-transform: uppercase;
			z-index: 1;
		}

		.placeholder {
			color: #717171;
		}

		.placeholder,
		.value {
			line-height: 1.25;
			position: absolute;
			width: 90%;
			text-align: center;
			font-family: Helvetica, Arial, sans-serif;
			font-size: 0.975rem;
			letter-spacing: 0.15rem;
			text-transform: uppercase;
			font-weight: 400;
			padding-right: 8px;
			padding-left: 8px;
		}

		.hidden {
			display: none;
		}

		svg {
			position: absolute;
			right: 8px;
			color: hsl(0, 0%, 80%);
			transition: fill 100ms cubic-bezier(0.15, 0.82, 0.38, 0.95);
			fill: currentColor;
			z-index: 0;
		}
	}
`;

export default NativeSelectStyles;
