import styled from 'styled-components';
import boxShadowMixin from '~/styles/mixins/box_shadow';

const PathContentStyles = styled.div`
	background: none;
	box-shadow: none;
	max-height: initial;
	.step-column {
		.nav-row {
			background-color: white;
			padding-bottom: 8px;
			padding-top: 8px;
			min-height: 54px;
			${boxShadowMixin(1)};
		}
		.step-content {
			overflow-y: auto;
			.col {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.error-boundary {
		padding-top: 14px;
		font-size: 1.5rem;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		background-color: white;
		border-radius: 6px;
		padding: 12px;
		min-height: 490px;
		max-height: 672px;
		${boxShadowMixin(1)};
		.showcase-column {
			padding-left: 0;
			padding-right: 0;
			min-height: 428px;
		}
		.step-column {
			.nav-row {
				padding: 0;
				min-height: initial;
				box-shadow: none;
			}
			.step-content {
				padding: 4px;
				display: block;
				flex-grow: 1;
			}
		}
	}

	@media (max-width: ${({ theme }) => theme.breakpoints.large}) and (min-width: ${({ theme }) =>
			theme.breakpoints.small}) {
		height: auto;
		max-height: fit-content;
	}
`;

export default PathContentStyles;
