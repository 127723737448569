import styled from 'styled-components';

const SupportBarStyles = styled.div`
	border-top: thin solid #a6a6a6;
	display: flex;
	flex-shrink: 0;
	width: 100%;
	height: 98px;
	align-items: center;
	background-color: #f1f1f1;
	.contact-info {
		font-family: Helvetica-Bold, sans-serif;
		font-size: 12px;
		color: #a5a3a3;
		width: 50%;
		padding: 4px 4px 4px ${({ theme }) => theme.pathStep.mobilePadding};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.small}) {
		display: none;
	}
`;

export default SupportBarStyles;
