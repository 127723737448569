import React from 'react';

import TypeaheadSelectInput from '~/components/input/TypeAheadSelectInput';
import AppButton from '~/connected/app_button';
import FrontDoorInfoHeader from '~/connected/front_door_info_formatter';
import PathStepStyles from '~/styles/path_step_styles';

import { lookupSchools } from '~/api';
import { SchoolSearchResult, StepProps } from '~/types';
import { useBasicInputs } from '~/utils/helpers';

interface Props extends StepProps {}

type State = {
	school_options: Array<SchoolSearchResult>;
	fetch_error: boolean;
	fetching: boolean;
};

class SelectSchoolStep extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			fetch_error: false,
			fetching: false,
			school_options: this.props.courseSearchInfo.school ? [this.props.courseSearchInfo.school] : []
		};
	}
	componentDidMount() {
		this.fetchSchools();
	}

	fetchSchools = () =>
		this.setState({ fetching: true }, () =>
			lookupSchools()
				.then((resp) => {
					this.setState({
						school_options: resp.data,
						fetch_error: false,
						fetching: false
					});
				})
				.catch((err) => {
					this.setState({
						fetch_error: true,
						fetching: false
					});
				})
		);

	private handleChange = (value) => {
		this.props.userUpdateCourseSearchInfo({
			school: this.state.school_options.find((i) => i.value == value),
			instructor: undefined,
			course: undefined
		});
	};

	public render() {
		const { appConfig, courseSearchInfo } = this.props;
		const { school } = courseSearchInfo;
		const { school_options, fetch_error, fetching } = this.state;
		const forceNativeSelect = useBasicInputs(appConfig);
		return (
			<PathStepStyles>
				<div className="page-content">
					<FrontDoorInfoHeader />
					<h1>What school do you go to?</h1>
					<hr />
					<TypeaheadSelectInput
						className="school-select"
						value={school && school.value}
						forceNativeFallback={forceNativeSelect}
						onChange={this.handleChange}
						placeholder="Select Your School"
						label="School Name"
						noOptionsMessage={() => (fetching ? 'Loading' : 'No Options')}
						loading={fetching}
						options={school_options}
					/>
					{!fetching && fetch_error && (
						<p className="error-message" style={{ marginTop: 16 }}>
							Error loading schools. Click{' '}
							<span className="link" onClick={this.fetchSchools}>
								here
							</span>{' '}
							to try again.
						</p>
					)}
				</div>
				<AppButton>Ok</AppButton>
			</PathStepStyles>
		);
	}
}

export default SelectSchoolStep;
