import { combineReducers } from 'redux';

import app, { AppState } from '~/store/app/reducer';
import user, { UserState } from '~/store/user/reducer';

import appEffects from '~/store/app/effects';

export const effects = [...appEffects];

export interface RootState {
	app: AppState;
	user: UserState;
}

const rootReducer = combineReducers({
	app,
	user
});

export default rootReducer;
