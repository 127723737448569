import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '~/store';
import { CourseSearchInfo } from '~/types';

type Props = {
	course_search_info: CourseSearchInfo;
};

/**
 * Renders info about the user's selected school, instructor and course
 */
class FrontDoorInfoHeader extends Component<Props> {
	render() {
		const { school, instructor, course } = this.props.course_search_info;
		return (
			<FrontDoorInfoHeaderStyles>
				{school && <div>{school.label}</div>}
				{school && instructor && instructor.value !== -1 && <div>{instructor.label}</div>}
				{school && instructor && course && course.value !== -1 && <div>{course.label}</div>}
			</FrontDoorInfoHeaderStyles>
		);
	}
}

const FrontDoorInfoHeaderStyles = styled.div`
	line-height: 1rem;
	font-size: 1.15rem;
	color: #615f61;
	margin-bottom: 16px;
	word-break: break-word;
	div {
		margin-bottom: 4px;
	}
`;

const mapStateToProps = (state: RootState) => ({
	course_search_info: state.user.course_search_info
});

export default connect<Props>(mapStateToProps)(FrontDoorInfoHeader);
