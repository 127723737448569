import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '~/components/input/Button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepPropsWithCoursePresentInAppConfig } from '~/types';

const round2 = (v) => Math.round(v * 100) / 100;
const updatePaymentMethod = (paymentMethod) => ({
	subscriptionPayload: { payment_details: { payment_method: paymentMethod } }
});

class PrintPurchase extends React.Component<StepPropsWithCoursePresentInAppConfig, {}> {
	public render() {
		const { appConfig } = this.props;
		const { subscription, print } = appConfig.course;
		const printCost = round2(subscription.print_cost);
		const showPrintCost = print.payment_methods.passkey === false;

		return (
			<PrintPurchaseStyles>
				<div className="page-content">
					<h1>Order a print book</h1>
					<p>
						A print book is not required for this course, but you may order one if you’d like to!
					</p>
					{showPrintCost && <p>It costs ${printCost} plus tax, which includes shipping.</p>}
					<p>
						<em className="lighter-text">
							This print-on-demand copy will take 6–10 business days to print and ship. (Which is
							amazingly fast for a custom book!)
						</em>
					</p>
				</div>
				<Link
					to={print.payment_methods.passkey === true ? 'print_payment_type' : 'credit_card_input'}
					className="no-link-styles continue-link"
					onClick={
						print.payment_methods.passkey === false
							? () => this.props.setFormValue(updatePaymentMethod('creditcard'))
							: null
					}>
					<Button>Ok</Button>
				</Link>
			</PrintPurchaseStyles>
		);
	}
}

const PrintPurchaseStyles = styled(PathStepStyles)`
	a {
		width: 100%;
	}

	em.lighter-text {
		color: #666;
	}

	.continue-link {
		display: flex;
		flex-direction: column;
	}
`;

export default PrintPurchase;
