import React from 'react';
import { Link } from 'react-router-dom';

import Button from '~/components/input/Button';
import PathStepStyles from '~/styles/path_step_styles';
import { StepPropsWithCoursePresentInAppConfig } from '~/types';

import PasskeyInput from '~/components/input/PasskeyInput';
import { selectPasskeyOnly, toggleContinue } from '~/utils/helpers';

export interface Props extends StepPropsWithCoursePresentInAppConfig {}

export interface State {
	submitted: boolean;
}

export interface PasskeyStepConfigParams {}

class PasskeyInputStep extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			submitted: false
		};
		this.validate();
	}

	componentDidMount() {
		this.props.userUpdatePaymentDetails({
			payment_method: 'passkey'
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.validate();
			if (!prevProps.validatingData && this.props.validatingData) {
				this.setState({
					submitted: true
				});
			}
		}
	}

	validate() {
		let { passkey, canContinue, setCanContinue } = this.props;
		let valid = passkey.length === 13;
		toggleContinue(canContinue, valid, setCanContinue);
	}

	handleChange = (e) => {
		const passkey = e.target.value;
		this.props.userUpdatePaymentDetails({
			passkey_plaintext: passkey
		});
		if (this.state.submitted && passkey.length < 13) {
			this.setState({ submitted: false });
		}
	};

	public render() {
		const { stepConfig, canContinue, submitPage, validatingData, passkey } = this.props;

		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>{stepConfig.title}</h1>
					<hr />
					<p>Once redeemed, passkeys cannot be returned to the school bookstore.</p>
					<PasskeyInput value={passkey} onChange={this.handleChange} disabled={validatingData} />
					{this.renderHelpText()}
					{this.renderErrors()}
				</div>
				<>
					<br />
					<Button
						onClick={() => submitPage()}
						disabled={!canContinue}
						loading={validatingData || validatingData}>
						Redeem
					</Button>
				</>
			</PathStepStyles>
		);
	}

	private renderHelpText() {
		const { appConfig, passkey } = this.props;
		const { payment_methods, trial } = appConfig.course.subscription;

		const ccAvailable = payment_methods.creditcard;
		const trialAvailable = trial.enabled;
		let paymentOptionMessage = '';

		if (ccAvailable) {
			if (trialAvailable) {
				paymentOptionMessage = 'pay by credit card or enter a trial instead';
			} else {
				paymentOptionMessage = 'pay by credit card';
			}
		} else {
			if (trialAvailable) {
				paymentOptionMessage = 'enter a trial instead';
			} else {
				paymentOptionMessage = 'choose a different payment method';
			}
		}

		if (passkey.length < 13) {
			return (
				<>
					<p>
						This passkey should start with "WT" and have a total of 13 letters and numbers. If you
						have questions about your order, get in touch with your school bookstore.
					</p>
					{
						// Don't show this text if they can only pay with passkey, or if they are completing a purchase from trial
						!selectPasskeyOnly(this.props.appConfig) &&
							this.props.appConfig.path_type !== 'complete_purchase' && (
								<p>
									Or, you can go back and{' '}
									<Link to="/webtext_subscribe">{paymentOptionMessage}</Link>
								</p>
							)
					}
				</>
			);
		}
	}

	private renderErrors() {
		const { validatingData, validationErrors } = this.props;
		if (this.state.submitted && !validatingData && validationErrors.length) {
			let errors = validationErrors.map((err, i) => {
				return (
					<div key={i} className="error-message">
						{err}
					</div>
				);
			});

			if (errors) {
				return (
					<>
						{errors}
						<div className="error-message">
							If you need help, click the round button in the lower right. Or you can go{' '}
							<Link to="/webtext_subscribe">back and select another subscription option.</Link>{' '}
						</div>
					</>
				);
			}
		}
	}
}

export default PasskeyInputStep;
