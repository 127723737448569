import React from 'react';

import ErrorBlurb from '~/components/error_blurb';
import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';

export interface Props extends StepProps {}

class InstructorNotFoundStep extends React.Component<Props> {
	public render() {
		return (
			<PathStepStyles>
				<div className="page-content">
					<h1>Instructor not found</h1>
					<hr />
					<ErrorBlurb />
				</div>
			</PathStepStyles>
		);
	}
}

export default InstructorNotFoundStep;
