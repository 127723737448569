import validate from 'validate.js';

import { shippingAddressDetailsValidation } from '~/utils/validations';

import { SignupStep } from '~/components';
import { AddressInput } from '~/components/input';

const validation = (userState) =>
	!validate(userState.shippingAddress, shippingAddressDetailsValidation);

export default () => (
	<SignupStep validation={validation}>
		{({ setFormValue, shippingAddress }) => (
			<>
				<h1>Shipping address</h1>

				<p className="italics">Where do you want us to send your book?</p>

				<AddressInput
					value={shippingAddress}
					onChange={(shippingAddress) => setFormValue({ shippingAddress })}
				/>
			</>
		)}
	</SignupStep>
);
