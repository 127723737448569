import React from 'react';
import styled from 'styled-components';

import { FiDownload as DownloadIcon } from 'react-icons/fi';

import TOSViewer from '~/components/tos_viewer';

import PathStepStyles from '~/styles/path_step_styles';
import { StepProps } from '~/types';
import Button from '../input/Button';

export interface Props extends StepProps {}

export interface State {}

export interface TermsOfServiceConfigParams {}

class TermsOfServiceStep extends React.Component<Props, State> {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.setCanContinue(true);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.termsAccepted === false && this.props.termsAccepted) {
			this.props.submitPage();
		}
	}

	onContinue = () => {
		if (!this.props.termsAccepted) {
			this.props.userSetTermsAgreed(true);
		} else {
			this.props.submitPage();
		}
	};

	public render() {
		const { stepConfig, finalizing, appConfig } = this.props;
		const { tos_html, tos_pdf_url } = appConfig;
		return (
			<TOSStepStyles>
				<div className="page-content">
					<h1>{stepConfig.title}</h1>
					<TOSViewer>
						<span dangerouslySetInnerHTML={{ __html: tos_html }} />
					</TOSViewer>
					{tos_pdf_url && (
						<div id="tos-link">
							<a href={appConfig.tos_pdf_url!} target="_blank">
								Download Terms of Service <DownloadIcon />
							</a>
						</div>
					)}
				</div>
				<Button loading={finalizing} onClick={this.onContinue}>
					I Agree
				</Button>
			</TOSStepStyles>
		);
	}
}

const TOSStepStyles = styled(PathStepStyles)`
	@media (max-width: ${({ theme }) => theme.breakpoints.small}) {
		.page-content {
			max-height: 70vh;
			overflow-y: scroll;
		}
		.continue-button {
			position: absolute;
			right: 0;
			left: 0;
			bottom: 0;
		}
	}

	#tos-link {
		color: #0068d3;
		text-decoration: underline !important;
		display: flex;
		justify-content: flex-end;
		svg {
			margin-left: 0.5rem;
		}
	}
`;

export default TermsOfServiceStep;
