export const xs = 0;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;

export default {
	xs,
	sm,
	md,
	lg,
	xl
};
