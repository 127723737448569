import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '~/store';
import { selectCurrentStep } from '~/store/selectors';

interface Props {
	progress: number;
}

const ProgressBar: React.FC<Props> = (props) => (
	<ProgressBarStyles {...props} className="path-progress-bar">
		<div className="slider" />
	</ProgressBarStyles>
);

const ProgressBarStyles = styled.div`
	height: 6px;
	width: 100%;
	background-color: ${({ theme }) => theme.progressBar.trackColor};
	.slider {
		height: 100%;
		width: ${(props: Props) => props.progress}%;
		background-color: ${({ theme }) => theme.progressBar.backgroundColor};
		transition: width 400ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
	}
`;

export default connect((state: RootState) => ({
	progress: selectCurrentStep(state)?.progress || 0
}))(ProgressBar);
